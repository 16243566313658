import { ReactElement, useEffect, useState } from "react";

import api from "../common/api";
import { useAmplitudeTrack } from "../common/useAmplitudeTrack";

import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";

import { CalendarType, Notification, Review } from "../providers/types";
import { useSchedules } from "../providers/schedules";
import { useNotifications } from "../providers/notifications";
import { useMainTabIndex } from "../providers/mainTabIndex";
import { pushPopup } from "../providers/popups";
import { usePartner } from "../providers/partner";
import { PopupKind } from "../popup/Popup";
import ReservationDetail from "../scenes/Main/Calendar/ReservationDetail";
import InquiryDetail from "../scenes/Main/Calendar/InquiryDetail/index";

import { formatDistance } from "date-fns";
import { ko } from "date-fns/locale";

const ReviewPopup = ({
  popPopup,
  reviewId,
}: {
  popPopup: () => void;
  reviewId: number;
}) => {
  const { partner } = usePartner();
  const [data, setData] = useState<Review[]>();

  useEffect(() => {
    (async () => {
      if (reviewId) {
        const datas = await api.get(
          `/rest/review?groupId=${partner.id}&reviewId=${reviewId}&type=U2G_B_RESV&platform=PARTNER_WEB`
        );
        setData(datas);
      }
    })();
  }, [reviewId]);

  return (
    <VFlex p-32-rl p-32-b width={460} ovf-h>
      <HFlex>
        <VFlex f-1 />
        <Image
          p-16-b
          p-24-t
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>
      {data?.map((value, i) => {
        return (
          <VFlex key={i}>
            <Text m-4-b t-24-600-s8 l-m>
              {value.pet.name} 보호자님이 보낸
            </Text>
            <Text t-24-600-s8>미용 서비스 후기가 도착했어요</Text>
            <VFlex
              rel
              m-32-t
              height={194}
              style={{
                background: `${value.decoration.color.topBgColor}`,
                borderRadius: "24px 24px 0 0",
              }}
            >
              <VFlex
                style={{
                  position: "absolute",
                  top: 1.5,
                  right: 0,
                  left: 0,
                  margin: "0 auto",
                  width: 224,
                  height: 219,
                  background: `url(${value.decoration.attachment}) no-repeat center / 224px auto`,
                }}
              ></VFlex>
            </VFlex>
            <VFlex
              style={{
                padding: "24px 24px 32px",
                background: `${value.decoration.color.bottomBgColor}`,
                borderRadius: "0 0 24px 24px",
              }}
            >
              <VFlex>
                {value.content && (
                  <VFlex
                    t-16-s8
                    m-24-b
                    l-m
                    style={{
                      maxHeight: 70,
                      overflowY: "scroll",
                    }}
                  >
                    {value.content}
                  </VFlex>
                )}
                <Text t-18-600-s10 m-16-b l-m>
                  {value.pet.name} 보호자님이 보낸 감사인사입니다 🙂
                </Text>
                <VFlex g-4>
                  {value.tags.map((tag) => {
                    return (
                      <HFlex key={tag.id} g-12>
                        <Image
                          width={24}
                          src={`/icons/review/check_${value.decoration.color.id}.png`}
                        />
                        <Text>{tag.content}</Text>
                      </HFlex>
                    );
                  })}
                </VFlex>
              </VFlex>
            </VFlex>
          </VFlex>
        );
      })}
    </VFlex>
  );
};

const NotificationItem = ({
  popPopup,
  notification,
  fetchNotifications,
}: {
  popPopup: () => void;
  notification: Notification;
  fetchNotifications: () => void;
}) => {
  const { setCalendarType, setCalendarDate, fetchSchedules } = useSchedules();
  const { setSelectedMenuIndex } = useMainTabIndex();
  return (
    <VFlex
      a-st
      j-c
      p-32-rl
      g-8
      height={126}
      sized
      bdr-16={notification.read}
      bc-t2={!notification.read}
      clickable
      onClick={async () => {
        if (notification.label === "고객 메세지") {
          pushPopup({
            key: `review${notification.id}`,
            kind: PopupKind.Popup,
            element: ReviewPopup,
            props: { reviewId: notification.reviewId },
          });
        } else if (notification.pushData.type === "INQ_RESV") {
          if (notification.reservationId) {
            popPopup();
            pushPopup({
              key: "addSchedule",
              kind: PopupKind.Popup,
              element: ReservationDetail,
              props: {
                reservationId: notification.reservationId,
              },
            });
          } else {
            popPopup();
            pushPopup({
              kind: PopupKind.Popup,
              element: InquiryDetail,
              props: {
                inquiryId: notification.inquiredReservationId,
              },
            });
          }
        } else {
          setSelectedMenuIndex("reservation");
          setCalendarType(CalendarType.DAILY);
          setCalendarDate(new Date(notification.sendDate * 1000));
          popPopup();
        }

        await api.put(`/rest/notification/${notification.id}/read`);
        fetchNotifications();
      }}
    >
      <HFlex a-c g-8>
        <Image
          size={24}
          src={
            notification.iconType === "INFO"
              ? `/icons/icon_noti_info.png`
              : notification.iconType === "POSITIVE"
              ? `/icons/icon_noti_positive.png`
              : notification.iconType === "NEGATIVE"
              ? `/icons/icon_noti_negative.png`
              : notification.iconType === "HEART"
              ? `/icons/icon_noti_heart.png`
              : ""
          }
        />
        <Text f-1 t-14-500-s3>
          {notification.label}
        </Text>
        <Text t-14-500-s3>
          {formatDistance(new Date(notification.sendDate * 1000), new Date(), {
            addSuffix: true,
            locale: ko,
          })}
        </Text>
      </HFlex>
      <Text t-16-500-s9 l-m>
        {notification.content}
      </Text>
    </VFlex>
  );
};

function NotificationPopup({
  popPopup,
}: {
  popPopup: () => void;
}): ReactElement {
  const { notifications, fetchNotifications } = useNotifications();
  const { trackLandNotification } = useAmplitudeTrack();
  useEffect(() => {
    trackLandNotification();
  }, []);

  return (
    <VFlex f-1 a-st p-24-t rel ovf-h>
      <HFlex p-24-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>
      <Text m-24-b p-24-rl t-20-700-s9>
        알림
      </Text>
      <VFlex ovf-a>
        {notifications.map((notification) => {
          return (
            <NotificationItem
              popPopup={popPopup}
              key={notification.id}
              notification={notification}
              fetchNotifications={fetchNotifications}
            />
          );
        })}
      </VFlex>
    </VFlex>
  );
}

export default NotificationPopup;
