import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "../../../layouts/Button";
import Flex from "../../../layouts/Flex";
import HFlex from "../../../layouts/HFlex";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import api from "../../../common/api";
import { Pet } from "../../../providers/types";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import _ from "lodash";

interface QquestionnaireResultsInterface {
  queueId: number;
  contentId: number;
  templateId: number;
  questionId: number;
  note: string;
  title: string;
  created: number;
}

interface QuestionHistoryDetailInterface {
  id: number;
  title: string;
  sortOrder: number;
  questionnaireResults: QquestionnaireResultsInterface[];
  required: boolean;
}

function Questionnaire({
  popPopup,
  questionnaireId,
  petName,
  questionnaireSubmitedDate,
}: {
  popPopup: () => void;
  questionnaireId: number;
  petName: string;
  questionnaireSubmitedDate: number;
}) {
  const [questionnaire, setQuestionnaire] = useState<
    QuestionHistoryDetailInterface[]
  >([]);

  useEffect(() => {
    (async () => {
      const result = await api.get(
        `/ext/questionnaire/${questionnaireId}/result`
      );
      setQuestionnaire(result);
    })();
  }, [questionnaireId]);

  const steps = useMemo(
    () =>
      _.sortBy(questionnaire, (q: any) => q.sortOrder).map((q: any) => ({
        ...q,
        questionnaireQuestionsById: Object.fromEntries(
          q.questionnaireQuestions.map((qq: any) => [qq.id, qq])
        ),
      })),
    [questionnaire]
  );

  return (
    <VFlex f-1 a-st p-24-t m-8-rl ovf-a style={{ position: "relative" }}>
      <HFlex p-24-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>

      <VFlex p-32-rl p-16-tb>
        <VFlex g-4 m-24-b>
          <Text
            t-24-600-bl4
            style={{ whiteSpace: "pre-line" }}
          >{`${petName}의 안전문진표`}</Text>
          <Text t-16-500-s4>
            {format(
              new Date(questionnaireSubmitedDate * 1000),
              "yyyy. MM. dd (EEEEE)",
              { locale: ko }
            )}
          </Text>
        </VFlex>

        <VFlex g-24 style={{ paddingBottom: "100px" }}>
          {steps
            .map((item: any) => ({
              title: item.title,
              answer: item.questionnaireResults[0].title,
              note: item.questionnaireResults[0].note,
              textArea: item.questionnaireResults[0]?.note || false,
            }))
            .map(({ title, answer, note, textArea }: any, i: number) => (
              <HFlex key={i} g-16>
                <Text
                  t-14-500-s6
                  width={260}
                  style={{
                    display: "inline-block",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {title}
                </Text>
                <VFlex g-16>
                  {answer && (
                    <Text t-14-700-gr5={textArea} t-14-400-s7={!textArea}>
                      {answer}
                    </Text>
                  )}
                  {note && <Text t-14-700-s8>{note}</Text>}
                </VFlex>
              </HFlex>
            ))}
        </VFlex>
      </VFlex>
      <HFlex
        width={600}
        height={86}
        bd-t
        a-c
        g-8
        m-24-t
        p-32-rl
        bc-w
        style={{ position: "fixed", bottom: 0 }}
      >
        <VFlex f-1 />
        <Button type="confirm" caption="확인" onClick={() => popPopup()} />
      </HFlex>
    </VFlex>
  );
}

export default Questionnaire;
