import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-hot-toast";
import { isNil } from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

let client: any;

export const initializeAxios = (token: string | null) => {
  client = axios.create({
    baseURL: API_URL,
    headers: token
      ? {
          platform: "PARTNER_WEB",
          Authorization: `Bearer ${token}`,
        }
      : {
          platform: "PARTNER_WEB",
        },
  });

  client.interceptors.request.use(onRequest);
  client.interceptors.response.use(onResponse);
};

async function handleResponse(res: AxiosResponse) {
  if (res.data.httpStatus === "UNAUTHORIZED") {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/signup/company"
    ) {
      window.location.href = "/";
    }
    return;
  }

  if (
    res.data.apiCode !== "A01" &&
    res.config.url !== "/rest/user/information" &&
    res.config.url !== "/rest/user/groups" &&
    res.config.url !== "/rest/admin/bug-report?platform=PARTNER_WEB"
  ) {
    const user = await api.get("/rest/user/information");
    const partners = await api.get("/rest/user/groups");

    await api.post("/rest/admin/bug-report?platform=PARTNER_WEB", {
      userId: user?.id || null,
      groupId: partners[0]?.id || null,
      requestMethod: res.config.method,
      apiPath: res.config.url,
      errorType: res.data.httpStatus,
      errorMessage: res.data.message,
    });
    toast.error("에러가 발생하였습니다. 다시 시도해 주세요.");
  }
  return res.data.data;
}

async function axiosGet<T = any>(url: string, config?: AxiosRequestConfig) {
  try {
    const res = await client.get(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    alert(`Error: ${e.message}`);
    throw e;
  }
}

async function axiosPost<T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.post(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    alert(`Error: ${e.message}`);
    throw e;
  }
}

async function axiosPut<T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.put(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    alert(`Error: ${e.message}`);
    throw e;
  }
}

async function axiosDelete<T = any>(url: string, config?: AxiosRequestConfig) {
  try {
    const res = await client.delete(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    alert(`Error: ${e.message}`);
    throw e;
  }
}

const api = {
  get: axiosGet,
  post: axiosPost,
  delete: axiosDelete,
  put: axiosPut,
};

export default api;

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
  metadata: { startTime: number };
}

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { headers } = config;
  config.headers = { ...headers, platform: "PARTNER_WEB", version: "2.0.12" };
  config.metadata = {
    startTime: new Date().getTime(),
  };
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { config } = response;
  const duration = isNil(
    (config as InternalAxiosRequestConfig).metadata?.startTime
  )
    ? null
    : (new Date().getTime() -
        ((config as InternalAxiosRequestConfig).metadata
          ?.startTime as number)) /
      1000;

  amplitude.track("api_end", {
    method: config.method,
    path: config.url,
    time: duration,
    data: isNil(config?.data)
      ? null
      : config.data instanceof FormData
      ? [...config.data.entries()].map(
          (e) =>
            `${encodeURIComponent(e[0])} : ${encodeURIComponent(
              e[1] instanceof File ? "[File]" : e[1]
            )}`
        )
      : JSON.stringify(config.data),
  });

  return response;
};
