// base
import React, { useEffect, useMemo, useRef, useState } from "react";

// common
import api from "../../../common/api";
import { dayOfWeek, staffColors } from "../../../common/constants";
import {
  minutesToTimeString,
  timeStringToMinutes,
} from "../../../common/format";
import Spinner from "../../../common/Spinner/Spinner";

// components
import ScheduleMini from "./ScheduleMini";
import PersonalScheduleMini from "./PersonalScheduleMini";
import { PopupKind } from "../../../popup/Popup";
import { PopupAnchorActions } from "../../../App";

// providers
import {
  BusinessHoliday,
  BusinessHour,
  DetailsType,
  ReservationStatus,
  Schedule,
  Staff,
  TemporaryHoliday,
} from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { useSchedules } from "../../../providers/schedules";
import { useBusinessHours } from "../../../providers/businessHours";
import { usePartner } from "../../../providers/partner";

// layouts
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import HFlex from "../../../layouts/HFlex";
import Absolute from "../../../layouts/Absolute";
import Image from "../../../layouts/Image";

// lib
import _ from "lodash";
import { useLongPress, LongPressEvent } from "use-long-press";
import { addMinutes, differenceInMinutes, startOfDay } from "date-fns";
import hangul from "hangul-js";

export const getNthWeeks = (date: Date): [number, number] => {
  date = new Date(date);
  const firstDate = new Date(date);
  firstDate.setDate(1);
  const lastDate = new Date(date);
  lastDate.setDate(1);
  lastDate.setMonth(date.getMonth() + 1);
  lastDate.setDate(0);
  // if last day is Sun/Mon/Tue/Wed and today is last week
  // then this week is first week of next month
  if (
    [0, 1, 2, 3].includes(lastDate.getDay()) &&
    date.getDate() >= lastDate.getDate() - lastDate.getDay()
  ) {
    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    return [
      date.getMonth(),
      Math.ceil((date.getDate() + firstDate.getDay()) / 7),
    ];
  }
  // if first day is Friday or Saturday, then first week starts next week
  else if (firstDate.getDay() === 5 || firstDate.getDay() === 6) {
    // if today is before the first week
    if (date.getDate() <= 7 - firstDate.getDay()) {
      // calculate last week of last month
      date.setDate(0);
      return getNthWeeks(date);
    } else {
      return [
        date.getMonth(),
        Math.ceil((date.getDate() - (7 - firstDate.getDay())) / 7),
      ];
    }
  }
  // otherwise normally calculate
  else {
    return [
      date.getMonth(),
      Math.ceil((date.getDate() + firstDate.getDay()) / 7),
    ];
  }
};

export const checkIsBusinessHoliday = (
  businessHoliday: BusinessHoliday,
  date: Date
) => {
  return (
    businessHoliday.dayOfWeek === dayOfWeek[date.getDay()] &&
    (businessHoliday.repeatType === 1 ||
      getNthWeeks(date)[1] === businessHoliday.repeatType - 1)
  );
};

export const checkIsBusinessHolidays = (
  businessHolidays: BusinessHoliday[],
  date: Date
) => {
  return _.some(businessHolidays, (businessHoliday) => {
    return checkIsBusinessHoliday(businessHoliday, date);
  });
};

export const checkIsTemporaryHoliday = (
  temporaryHoliday: TemporaryHoliday,
  date: Date
) => {
  return (
    startOfDay(temporaryHoliday.startDate).getTime() <=
      startOfDay(date).getTime() &&
    startOfDay(temporaryHoliday.endDate).getTime() >= startOfDay(date).getTime()
  );
};

export const checkIsTemporaryHolidays = (
  temporaryHolidays: TemporaryHoliday[],
  date: Date
) => {
  return _.some(temporaryHolidays, (temporaryHoliday, j) => {
    return checkIsTemporaryHoliday(temporaryHoliday, date);
  });
};

export const checkIsBusinessHour = ({
  date,
  businessHours,
  businessHolidays,
  temporaryHolidays,
  time,
}: {
  date: Date;
  businessHours: BusinessHour;
  businessHolidays: BusinessHoliday[];
  temporaryHolidays: TemporaryHoliday[];
  time: number;
}) => {
  return (
    checkIsBusinessHolidays(businessHolidays, date) ||
    checkIsTemporaryHolidays(temporaryHolidays, date) ||
    time < (timeStringToMinutes(businessHours?.start) ?? 0) ||
    time >= (timeStringToMinutes(businessHours?.end) ?? 0)
  );
};

export const HolidayBlock = ({
  text,
  height,
}: {
  text?: string;
  height?: number;
}) => {
  return (
    <VFlex
      j-c
      bdr-4
      p-4-l
      m-2
      height={height}
      style={{
        width: "calc(100% - 4px)",
        background: "#CAD1CE",
      }}
    >
      <HFlex g-4 a-c>
        <Image size={14} src={`/icons/icon_holiday.png`} />
        <Text t-12-600-w>{text}</Text>
      </HFlex>
    </VFlex>
  );
};

export const ScheduleBlock = ({
  schedule,
  overlapIndex,
  maxOverlapIndex,
  initialStaffs,
  personalList,
  statusList,
  width,
}: {
  schedule: Schedule;
  overlapIndex?: number;
  maxOverlapIndex?: number;
  initialStaffs?: Staff[];
  startDateTime?: Date;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
  width?: number;
}) => {
  const isStatusShow = statusList
    ?.filter((i: ReservationStatus) => i.showed)
    ?.map((status) => status.value)
    .map(Number);

  const isStatusActive = statusList
    ?.filter((i: ReservationStatus) => i.actived)
    ?.map((status) => status.value)
    .map(Number);

  const isPersonalActive = personalList && personalList[0]?.actived;

  const startTime = useMemo(
    () =>
      new Date(
        Math.min(
          schedule.startDateTime.getTime(),
          schedule.endDateTime.getTime()
        )
      ),
    [schedule]
  );
  const endTime = useMemo(
    () =>
      new Date(
        Math.max(
          schedule.startDateTime.getTime(),
          schedule.endDateTime.getTime()
        )
      ),
    [schedule]
  );

  const isPersonalSchedule = useMemo(() => {
    return schedule.type === "PARTNER_SCHEDULE";
  }, [schedule]);

  const isInquiriesSchedule = useMemo(() => {
    return (
      schedule.incomingPlatform === "PUA" || schedule.incomingPlatform === "PUW"
    );
  }, [schedule]);

  const durationOfTime = Math.floor(
    differenceInMinutes(endTime, startTime) / 15
  );
  const boxRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <VFlex
        ref={boxRef}
        g-4
        abs
        p-6-tb
        p-4-rl
        m-2-t
        m-4-l
        bdr-4
        clickable
        style={{
          display:
            (isPersonalSchedule && isPersonalActive) ||
            (isStatusShow?.includes(schedule.status) &&
              isStatusActive?.includes(schedule.status))
              ? "block"
              : "none",
          top: `${
            19 *
            Math.floor(
              (startTime.getHours() * 60 + startTime.getMinutes()) / 15
            )
          }px`,
          left: `calc(${
            maxOverlapIndex && Number(overlapIndex) * (100 / maxOverlapIndex)
          }% - 2px)`,
          height: `${
            differenceInMinutes(endTime, startTime) <= 30
              ? 30
              : 19 * Math.floor(differenceInMinutes(endTime, startTime) / 15) -
                8
          }px`,
          width: `calc(${width}% - 4px)`,
          background: isPersonalSchedule
            ? schedule.staffs.length === 0
              ? `repeating-linear-gradient(-45deg, ${schedule.backgroundColor}, ${schedule.backgroundColor} 2px, transparent 0, transparent 5px)`
              : `repeating-linear-gradient(-45deg, ${
                  staffColors[schedule.staffs[0]?.color]?.bgColor
                }, ${
                  staffColors[schedule.staffs[0]?.color]?.bgColor
                } 2px, transparent 0, transparent 5px)`
            : [6, 7].includes(schedule.status) // 예약취소, 노쇼
            ? "none"
            : schedule.staffs.length === 0
            ? "#E4E8E6"
            : staffColors[schedule.staffs[0]?.color]?.bgColor,
          textDecoration: [5, 6, 7].includes(schedule.status)
            ? "line-through"
            : "none",
          opacity: [4, 6, 7].includes(schedule.status) ? 0.5 : 1,
          border: [6, 7].includes(schedule.status)
            ? schedule.staffs.length === 0
              ? `2px solid #979E9B`
              : `2px solid ${staffColors[schedule.staffs[0]?.color]?.bgColor}`
            : "none",
          lineHeight: 0,
        }}
        onClick={() => {
          const positionX = boxRef.current?.getBoundingClientRect().x;
          const positionY = boxRef.current?.getBoundingClientRect().y;
          const positionB = boxRef.current?.getBoundingClientRect().bottom;
          const resultXvalue =
            positionX && positionX < 420
              ? positionX + boxRef.current?.getBoundingClientRect().width + 15
              : positionX && positionX - 415;

          isPersonalSchedule
            ? pushPopup({
                key: "personalScheduleMini",
                kind: PopupKind.Mini,
                element: PersonalScheduleMini,
                props: {
                  personalSchedule: schedule,
                },
                // 미니팝업 위치
                x: resultXvalue,
                y:
                  positionY && positionY < 0
                    ? positionB && positionB / 2 - 100
                    : positionY && positionY - 100,
              })
            : pushPopup({
                key: "scheduleMini",
                kind: PopupKind.Mini,
                element: ScheduleMini,
                props: {
                  reservationId: schedule.reservationId,
                  schedule: schedule,
                  startTime: startTime,
                },
                // 미니팝업 위치
                x: resultXvalue,
                y: positionY && positionY / 2,
              });
        }}
      >
        {isPersonalSchedule ? (
          // 기타 일정
          <VFlex>
            <HFlex l-m>
              <VFlex
                m-4-r
                style={{
                  display: "inline-block",
                  width: 10,
                  height: 10,
                  background: schedule.mainColor,
                  borderRadius: "100%",
                }}
              ></VFlex>
              <Text t-13-700-s8>{schedule.title}</Text>
            </HFlex>
            {schedule.memo && (
              <Text t-12-s8 l-m>
                {schedule.memo}
              </Text>
            )}
          </VFlex>
        ) : durationOfTime === 2 ? (
          // 30분일 때
          <HFlex l-m sized>
            <Text
              t-13-700-s8
            >{`${schedule.petName} (${schedule.species})`}</Text>
            {isInquiriesSchedule && (
              <Image m-4-l size={16} src={`/icons/icon_send.png`} />
            )}
          </HFlex>
        ) : durationOfTime > 4 ? (
          // 1시간 이상일때
          <VFlex>
            <HFlex l-m sized>
              <Text
                t-13-700-s8
              >{`${schedule.petName} (${schedule.species})`}</Text>
              {isInquiriesSchedule && (
                <Image m-4-l size={16} src={`/icons/icon_send.png`} />
              )}
            </HFlex>
            <Text t-12-700-s8 l-m sized>
              {schedule.productList?.join(", ") ?? []}
            </Text>
            {schedule.requestNote && (
              <Text t-12-s8 l-m sized>
                {schedule.requestNote}
              </Text>
            )}
          </VFlex>
        ) : (
          // 1시간일 때
          <VFlex>
            <HFlex l-m sized>
              <Text
                t-13-700-s8
              >{`${schedule.petName} (${schedule.species})`}</Text>
              {isInquiriesSchedule && (
                <Image m-4-l size={16} src={`/icons/icon_send.png`} />
              )}
            </HFlex>
            <Text t-12-700-s8 l-m sized>
              {schedule.productList?.join(", ") ?? []}
            </Text>
          </VFlex>
        )}
      </VFlex>
    </>
  );
};

export const CreatingScheduleBlock = ({ schedule }: { schedule: Schedule }) => {
  const startTime = useMemo(
    () =>
      new Date(
        Math.min(
          schedule.startDateTime.getTime(),
          schedule.endDateTime.getTime()
        )
      ),
    [schedule]
  );
  const endTime = useMemo(
    () =>
      new Date(
        Math.max(
          schedule.startDateTime.getTime(),
          schedule.endDateTime.getTime()
        )
      ),
    [schedule]
  );

  return (
    <VFlex
      abs
      m-4
      c-c
      bdr-4
      style={{
        top: `${
          19 *
          Math.floor((startTime.getHours() * 60 + startTime.getMinutes()) / 15)
        }px`,
        height: `${
          19 * Math.floor(differenceInMinutes(endTime, startTime) / 15) - 8
        }px`,
        width: "calc(100% - 8px)",
        background: "rgba(241, 243, 242, 0.30)",
        border: "1px dashed var(--grey-shades-grey-s-4, #848A87)",
      }}
      clickable
    >
      <VFlex width={"80%"} c-c>
        <Text t-12-s4 l-m style={{ textAlign: "center" }}>
          {minutesToTimeString(
            startTime.getHours() * 60 + startTime.getMinutes()
          )}
          &nbsp;~&nbsp;
          {minutesToTimeString(endTime.getHours() * 60 + endTime.getMinutes())}
        </Text>
      </VFlex>
    </VFlex>
  );
};

const StaffCalendar = ({
  selectedStaffs,
  searchText,
  addScheduleRef,
  personalList,
  statusList,
}: {
  selectedStaffs: Staff[] | undefined;
  searchText?: string;
  addScheduleRef: React.RefObject<PopupAnchorActions>;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) => {
  const { partner } = usePartner();
  const { schedules, calendarDate } = useSchedules();
  const { businessHours, businessHolidays, temporaryHolidays } =
    useBusinessHours();
  const [creatingSchedule, setCreatingSchedule] = useState<Schedule>();
  const divRef = useRef<HTMLDivElement>(null);
  const openHour = useMemo(
    () =>
      Math.max(
        ..._.compact(
          businessHours.map((businessHour) => {
            try {
              return parseInt(businessHour.start?.split(":")[0]);
            } catch {
              return undefined;
            }
          })
        ),
        0
      ),
    [businessHours]
  );
  const businessHoursByDay = useMemo(() => {
    return _.keyBy(businessHours, (businessHour) => {
      return dayOfWeek.findIndex((day) => day === businessHour.dayOfWeek);
    });
  }, [businessHours]);
  useEffect(() => {
    divRef.current?.scrollTo({ top: 38 * openHour * 2, behavior: "smooth" });
  }, [divRef, openHour]);
  const filteredSchedules = useMemo(() => {
    return schedules?.filter((schedule) => {
      return (
        startOfDay(schedule.startDateTime).getTime() ===
          startOfDay(calendarDate).getTime() &&
        _.some(
          [
            schedule.petName,
            schedule.nickname ?? "",
            schedule.cellNumber ?? "",
          ],
          (text) => hangul.search(text, searchText ?? "") >= 0
        ) &&
        (schedule.staffs.length === 0 ||
          _.intersection(
            selectedStaffs?.map((staff) => staff.id),
            schedule.staffs.map((staff) => staff.id)
          ).length > 0)
      );
    });
  }, [schedules, searchText, calendarDate, selectedStaffs]);
  const [startPosition, setStartPosition] = useState<{
    x: number;
    y: number;
  }>();
  const scheduleBoxRef = useRef<HTMLDivElement>(null);
  const bind = useLongPress(
    () => {
      const startDateTime = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30
      );
      const endDateTime = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30 + 60
      );
      setCreatingSchedule({
        reservationId: 0,
        status: 0,
        species: "",
        productList: [],
        chargerIdList: [],
        startDateTime,
        endDateTime,
        petName: "",
        staffs: _.compact([selectedStaffs![startPosition!.x - 1]]),
        note: "",
        backgroundColor: "pink",
        type: "drag",
      });
    },
    {
      filterEvents: (e: any) => {
        // filter out right click
        return e.buttons === 1;
      },
      onStart: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;
        setStartPosition({
          x: Math.floor(
            (e.clientX - x) / (width / ((selectedStaffs?.length ?? 0) + 1))
          ),
          y: Math.floor((e.clientY - y) / 38),
        });
      },
      onMove: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;
        if (startPosition) {
          const newEndPosition = {
            x: Math.floor(
              (e.clientX - x) / (width / ((selectedStaffs?.length ?? 0) + 1))
            ),
            y: Math.floor((e.clientY - y) / 38) + 1,
          };
          if (creatingSchedule) {
            let newEndDateTime = new Date(
              calendarDate.getFullYear(),
              calendarDate.getMonth(),
              creatingSchedule.startDateTime.getDate(),
              Math.floor(newEndPosition.y / 2),
              (newEndPosition.y % 2) * 30
            );
            if (creatingSchedule.endDateTime !== newEndDateTime) {
              if (
                differenceInMinutes(
                  newEndDateTime,
                  creatingSchedule.startDateTime.getTime()
                ) < 60
              ) {
                newEndDateTime = addMinutes(creatingSchedule.startDateTime, 60);
              }
              setCreatingSchedule({
                ...creatingSchedule,
                endDateTime: newEndDateTime,
              });
            }
          }
        }
      },
      onFinish: async (e: LongPressEvent) => {
        if ("buttons" in e && e.buttons > 0) {
          setStartPosition(undefined);
          setCreatingSchedule(undefined);
          return;
        }
        setStartPosition(undefined);
        if (creatingSchedule) {
          addScheduleRef.current?.pushPopup(
            creatingSchedule.staffs,
            creatingSchedule.startDateTime,
            creatingSchedule.endDateTime,
            creatingSchedule.type
          );
          setCreatingSchedule(undefined);
        }
      },
      onCancel: () => {
        setStartPosition(undefined);
        setCreatingSchedule(undefined);
      },
      threshold: 200,
    }
  );

  const schedulesByStaff = useMemo(() => {
    if (!filteredSchedules) return {};

    const schedulesByStaff = _(filteredSchedules)
      .flatMap(({ staffs, ...fields }) =>
        staffs.length === 0
          ? [{ ...fields, staffs, background: "#E4E8E6" }]
          : staffs.map((staff) => ({
              ...fields,
              staffs: [staff],
              background: staffColors[staff?.color]?.bgColor ?? "#E4E8E6",
              mainColor: staffColors[staff?.color]?.mainColor ?? "#4B4F4D",
            }))
      )
      .groupBy((schedule) => schedule.staffs[0]?.id ?? "미지정")
      .mapValues((schedules) =>
        _.orderBy(schedules, (schedule) => schedule.startDateTime.getTime())
      )
      .value();

    return _.mapValues(schedulesByStaff, (schedules) => {
      if (!schedules.length) return [];

      const overlappingGroups = [];
      let currentGroup = [schedules[0]];

      for (let i = 1; i < schedules.length; i++) {
        const currentSchedule = schedules[i];

        let overlaps = false;
        for (const schedule of currentGroup) {
          if (
            schedule.endDateTime > currentSchedule.startDateTime &&
            schedule.startDateTime < currentSchedule.endDateTime
          ) {
            overlaps = true;
            break;
          }
        }

        if (overlaps) {
          currentGroup.push(currentSchedule);
        } else {
          currentGroup.sort((a, b) => {
            if (a.startDateTime.getTime() === b.startDateTime.getTime()) {
              return b.endDateTime.getTime() - a.endDateTime.getTime();
            }
            return a.startDateTime.getTime() - b.startDateTime.getTime();
          });

          overlappingGroups.push(currentGroup);
          currentGroup = [currentSchedule];
        }
      }

      currentGroup.sort((a, b) => {
        if (a.startDateTime.getTime() === b.startDateTime.getTime()) {
          return b.endDateTime.getTime() - a.endDateTime.getTime();
        }
        return a.startDateTime.getTime() - b.startDateTime.getTime();
      });
      overlappingGroups.push(currentGroup);

      return overlappingGroups;
    });
  }, [filteredSchedules]);

  const maxOverlapByStaff = useMemo(() => {
    return _.mapValues(schedulesByStaff, (dailySchedules) =>
      dailySchedules.map((schedules) => {
        if (!schedules.length) {
          return {
            maxOverlapIndex: 0,
            schedules: {},
          };
        }
        let schedulesToIterate = schedules;
        let currentEndTime;
        let overlapIndex = 0;
        let nextSchedulesToIterate = [];
        const schedulesByOverlapIndex: Record<string, Schedule[]> = {};
        while (true) {
          for (const schedule of schedulesToIterate) {
            if (currentEndTime && currentEndTime > schedule.startDateTime) {
              nextSchedulesToIterate.push(schedule);
              continue;
            }
            currentEndTime = schedule.endDateTime;
            schedulesByOverlapIndex[overlapIndex.toString()] ||= [];
            schedulesByOverlapIndex[overlapIndex.toString()].push(schedule);
          }
          if (nextSchedulesToIterate.length === 0) {
            break;
          }
          overlapIndex++;
          schedulesToIterate = [...nextSchedulesToIterate];
          nextSchedulesToIterate = [];
          currentEndTime = undefined;
        }
        return {
          maxOverlapIndex: overlapIndex + 1,
          schedules: schedulesByOverlapIndex,
        };
      })
    );
  }, [schedulesByStaff]);

  const [isStaff, setIsStaff] = useState<DetailsType>();
  const fetchDetils = async () => {
    const res = await api.get(
      `/rest/group/${partner.id}/details?type=CALENDAR_S`
    );
    setIsStaff(res[0]);
  };
  useEffect(() => {
    fetchDetils();
  }, []);

  return (
    <VFlex f-1>
      <HFlex rel>
        <VFlex width={80} bd-b-t3 c-c />
        <VFlex f-1 ovf-h>
          {isStaff?.value === "FALSE" ? (
            <HFlex>
              {selectedStaffs?.map((staff, i) => (
                <VFlex key={i} f-1 c-c height={38} bd-b-t3 ovf-h>
                  <HFlex c-c g-6>
                    <HFlex
                      width={12}
                      height={12}
                      bdr-12
                      sized
                      style={{
                        backgroundColor: staffColors[staff.color]?.mainColor
                          ? staffColors[staff.color]?.mainColor
                          : "#E4E8E6",
                      }}
                    />
                    <Text t-14-s8 l-1>
                      {staff.name}
                    </Text>
                  </HFlex>
                </VFlex>
              ))}
            </HFlex>
          ) : (
            <HFlex>
              {[
                { name: "미지정", color: "#E4E8E6" },
                ...(selectedStaffs ?? []),
              ].map((staff, i) => (
                <VFlex key={i} f-1 c-c height={38} bd-b-t3 ovf-h>
                  <HFlex c-c g-6>
                    <HFlex
                      width={12}
                      height={12}
                      bdr-12
                      sized
                      style={{
                        backgroundColor: staffColors[staff.color]?.mainColor
                          ? staffColors[staff.color]?.mainColor
                          : "#E4E8E6",
                      }}
                    />
                    <Text t-14-s8 l-1>
                      {staff.name}
                    </Text>
                  </HFlex>
                </VFlex>
              ))}
            </HFlex>
          )}
          {(checkIsBusinessHolidays(businessHolidays, calendarDate) ||
            checkIsTemporaryHolidays(temporaryHolidays, calendarDate)) && (
            <HFlex bd-b-t3>
              <VFlex f-1 rel>
                {checkIsBusinessHolidays(businessHolidays, calendarDate) && (
                  <HolidayBlock text="정기 휴무" height={18} />
                )}
                {checkIsTemporaryHolidays(temporaryHolidays, calendarDate) && (
                  <HolidayBlock text="임시 휴무" height={18} />
                )}
              </VFlex>
            </HFlex>
          )}
        </VFlex>
      </HFlex>
      <VFlex rel ovf-h>
        <HFlex ref={divRef} f-1 ovf-a rel>
          <VFlex width={80}>
            {[...Array(24).keys()]
              .map((value) => value * 60)
              .map((value, i) => (
                <VFlex key={i}>
                  <VFlex
                    c-c
                    height={38}
                    bd-br-t3
                    style={{
                      borderBottom: "1px dashed #EBEDEC",
                    }}
                  >
                    <Text t-14-500-s4>{`${value >= 720 ? "오후" : "오전"} ${
                      Math.floor(value / 60) % 12 || 12
                    }:${("0" + (value % 60)).slice(-2)}`}</Text>
                  </VFlex>
                  <VFlex c-c height={38} bd-br-t3 />
                </VFlex>
              ))}
          </VFlex>

          {/* 현재 시간 바 */}
          {calendarDate.getDate() === new Date().getDate() && (
            <VFlex
              style={{
                position: "absolute",
                top: `${
                  19 *
                  Math.floor(
                    (new Date().getHours() * 60 + new Date().getMinutes()) / 15
                  )
                }px`,
                left: 80,
                width: `calc(100% - 80px)`,
                height: 1,
                background: "#0092E4",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: -5,
                  left: -5,
                  width: 11,
                  height: 11,
                  borderRadius: "100%",
                  background: "#0092ea",
                  zIndex: 1,
                }}
              ></div>
            </VFlex>
          )}

          {schedulesByStaff && (
            <VFlex f-1>
              {isStaff?.value === "FALSE" ? (
                <HFlex f-1 ref={scheduleBoxRef} {...bind()}>
                  {selectedStaffs?.map((staff, i) => (
                    <VFlex key={i} f-1 rel>
                      <Absolute bd-l-t3={i > 0} style={{ paddingRight: 12 }}>
                        {maxOverlapByStaff[staff.id]?.map(
                          ({ maxOverlapIndex, schedules }, idx) => (
                            <VFlex key={idx} f-1 rel>
                              {Object.entries(schedules).flatMap(
                                (
                                  [overlapIndex, scheduleList],
                                  scheduleListIdx,
                                  entries
                                ) =>
                                  scheduleList.map((schedule, j) => {
                                    const startTime = new Date(
                                      Math.min(
                                        schedule.startDateTime.getTime(),
                                        schedule.endDateTime.getTime()
                                      )
                                    );
                                    const endTime = new Date(
                                      Math.max(
                                        schedule.startDateTime.getTime(),
                                        schedule.endDateTime.getTime()
                                      )
                                    );

                                    let width = 100 / maxOverlapIndex;

                                    for (
                                      let nextIdx = scheduleListIdx + 1;
                                      nextIdx < entries.length;
                                      nextIdx++
                                    ) {
                                      const nextScheduleList =
                                        entries[nextIdx][1];

                                      let overlaps = false;
                                      for (const nextSchedule of nextScheduleList) {
                                        const nextStartTime = new Date(
                                          Math.min(
                                            nextSchedule.startDateTime.getTime(),
                                            nextSchedule.endDateTime.getTime()
                                          )
                                        );
                                        const nextEndTime = new Date(
                                          Math.max(
                                            nextSchedule.startDateTime.getTime(),
                                            nextSchedule.endDateTime.getTime()
                                          )
                                        );

                                        if (
                                          !(
                                            endTime <= nextStartTime ||
                                            startTime >= nextEndTime
                                          )
                                        ) {
                                          overlaps = true;
                                          break;
                                        }
                                      }

                                      if (!overlaps) {
                                        width += 100 / maxOverlapIndex;
                                      } else {
                                        break;
                                      }
                                    }

                                    return (
                                      <ScheduleBlock
                                        key={j}
                                        schedule={schedule}
                                        overlapIndex={parseInt(overlapIndex)}
                                        maxOverlapIndex={maxOverlapIndex}
                                        personalList={personalList}
                                        statusList={statusList}
                                        width={width}
                                      />
                                    );
                                  })
                              )}
                            </VFlex>
                          )
                        )}
                      </Absolute>
                      {creatingSchedule &&
                        (creatingSchedule.staffs[0]?.id ?? "미지정") ===
                          staff.id && (
                          <Absolute
                            bd-l-t3={i > 0}
                            style={{ paddingRight: 12 }}
                          >
                            <CreatingScheduleBlock
                              schedule={creatingSchedule}
                            />
                          </Absolute>
                        )}
                      {Array(48)
                        .fill(1)
                        .map((_, j) => (
                          <VFlex
                            key={j}
                            height={38}
                            bd-b-t3
                            bc-t1={checkIsBusinessHour({
                              date: calendarDate,
                              businessHours:
                                businessHoursByDay[
                                  calendarDate.getDay().toString()
                                ],
                              businessHolidays,
                              temporaryHolidays,
                              time: j * 30,
                            })}
                            style={{
                              borderBottom: `1px ${
                                j % 2 === 0 ? "dashed" : "solid"
                              } #EBEDEC`,
                            }}
                          />
                        ))}
                    </VFlex>
                  ))}
                </HFlex>
              ) : (
                <HFlex f-1 ref={scheduleBoxRef} {...bind()}>
                  {[{ id: "미지정" }, ...(selectedStaffs ?? [])].map(
                    (staff, i) => (
                      <VFlex key={i} f-1 rel>
                        <Absolute bd-l-t3={i > 0} style={{ paddingRight: 12 }}>
                          {maxOverlapByStaff[staff.id]?.map(
                            ({ maxOverlapIndex, schedules }, idx) => (
                              <VFlex key={idx} f-1 rel>
                                {Object.entries(schedules).flatMap(
                                  (
                                    [overlapIndex, scheduleList],
                                    scheduleListIdx,
                                    entries
                                  ) =>
                                    scheduleList.map((schedule, j) => {
                                      const startTime = new Date(
                                        Math.min(
                                          schedule.startDateTime.getTime(),
                                          schedule.endDateTime.getTime()
                                        )
                                      );
                                      const endTime = new Date(
                                        Math.max(
                                          schedule.startDateTime.getTime(),
                                          schedule.endDateTime.getTime()
                                        )
                                      );

                                      let width = 100 / maxOverlapIndex;

                                      for (
                                        let nextIdx = scheduleListIdx + 1;
                                        nextIdx < entries.length;
                                        nextIdx++
                                      ) {
                                        const nextScheduleList =
                                          entries[nextIdx][1];

                                        let overlaps = false;
                                        for (const nextSchedule of nextScheduleList) {
                                          const nextStartTime = new Date(
                                            Math.min(
                                              nextSchedule.startDateTime.getTime(),
                                              nextSchedule.endDateTime.getTime()
                                            )
                                          );
                                          const nextEndTime = new Date(
                                            Math.max(
                                              nextSchedule.startDateTime.getTime(),
                                              nextSchedule.endDateTime.getTime()
                                            )
                                          );

                                          if (
                                            !(
                                              endTime <= nextStartTime ||
                                              startTime >= nextEndTime
                                            )
                                          ) {
                                            overlaps = true;
                                            break;
                                          }
                                        }

                                        if (!overlaps) {
                                          width += 100 / maxOverlapIndex;
                                        } else {
                                          break;
                                        }
                                      }

                                      return (
                                        <ScheduleBlock
                                          key={j}
                                          schedule={schedule}
                                          overlapIndex={parseInt(overlapIndex)}
                                          maxOverlapIndex={maxOverlapIndex}
                                          personalList={personalList}
                                          statusList={statusList}
                                          width={width}
                                        />
                                      );
                                    })
                                )}
                              </VFlex>
                            )
                          )}
                        </Absolute>
                        {creatingSchedule &&
                          (creatingSchedule.staffs[0]?.id ?? "미지정") ===
                            staff.id && (
                            <Absolute
                              bd-l-t3={i > 0}
                              style={{ paddingRight: 12 }}
                            >
                              <CreatingScheduleBlock
                                schedule={creatingSchedule}
                              />
                            </Absolute>
                          )}
                        {Array(48)
                          .fill(1)
                          .map((_, j) => (
                            <VFlex
                              key={j}
                              height={38}
                              bd-b-t3
                              bc-t1={checkIsBusinessHour({
                                date: calendarDate,
                                businessHours:
                                  businessHoursByDay[
                                    calendarDate.getDay().toString()
                                  ],
                                businessHolidays,
                                temporaryHolidays,
                                time: j * 30,
                              })}
                              style={{
                                borderBottom: `1px ${
                                  j % 2 === 0 ? "dashed" : "solid"
                                } #EBEDEC`,
                              }}
                            />
                          ))}
                      </VFlex>
                    )
                  )}
                </HFlex>
              )}
            </VFlex>
          )}
          {!schedulesByStaff && (
            <VFlex abs c-c style={{ inset: 0 }} unclickable>
              <Spinner />
            </VFlex>
          )}
        </HFlex>
      </VFlex>
    </VFlex>
  );
};

export default StaffCalendar;
