import { format, startOfDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "../../../components/DatePicker";
import { ko } from "date-fns/locale";
import AddSchedule from "../Calendar/AddSchedule";
import Pagination from "../../../components/Pagination";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import { pushPopup } from "../../../providers/popups";
import { PopupKind } from "../../../popup/Popup";
import Image from "../../../layouts/Image";
import Divider from "../../../layouts/Divider";
import { useNotifications } from "../../../providers/notifications";
import { usePartner } from "../../../providers/partner";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import FilterChip from "../../../layouts/FilterChip";

const ITEMS_PER_PAGE = 10;
const NOTIFICATION_STATUS = [
  { text: "전체", status: "ALL" },
  { text: "실패", status: "FAIL" },
  { text: "성공", status: "SUCCESS" },
  { text: "예정", status: "SCHEDULE" },
];

function GroupNotificationList() {
  const { partner } = usePartner();
  const { trackClickNotificationTalkStatus } = useAmplitudeTrack();

  const { groupNotifications, fetchGroupNotifications, totalGroupNotiCount } =
    useNotifications();
  const [selectedFilter, setSelectedFilter] = useState(
    NOTIFICATION_STATUS[0].status
  );
  const [page, setPage] = useState(0);
  const [date, setDate] = useState<Date | undefined>();

  useEffect(() => {
    setPage(0);
  }, [selectedFilter, date]);

  useEffect(() => {
    const parsedDate = date && Math.floor(date.getTime() / 1000);

    if (selectedFilter === "ALL")
      (async () => await fetchGroupNotifications({ page, date: parsedDate }))();
    else
      (async () =>
        await fetchGroupNotifications({
          page,
          status: selectedFilter,
          date: parsedDate,
        }))();
  }, [page, selectedFilter, date]);

  return (
    <VFlex f-1 a-st p-16-t p-32-rl>
      <HFlex g-8 a-c>
        <HFlex a-st bdr-8 bd-t3 sized ovf-h width={171} height={38}>
          {NOTIFICATION_STATUS.map(({ status, text }, i) => (
            <HFlex key={i} f-1 p-8-rl>
              <HFlex
                c-c
                f-1
                clickable
                onClick={() => {
                  setSelectedFilter(status);
                  trackClickNotificationTalkStatus(text, partner.title);
                }}
              >
                <Text
                  t-14-700-s9={selectedFilter === status}
                  t-14-700-s4={selectedFilter !== status}
                >
                  {text}
                </Text>
              </HFlex>
              <VFlex a-st m-12-tb>
                <Divider width={0.1} />
              </VFlex>
            </HFlex>
          ))}
        </HFlex>
        <DatePicker
          date={date}
          setDate={setDate}
          trigger={
            date ? (
              <FilterChip type="selected">
                <HFlex a-c g-8>
                  {format(date, "yyyy년 MM월 dd일")}
                  <Image
                    src="/icons/icon_close_g1.svg"
                    size={18}
                    onClick={() => setDate(undefined)}
                  />
                </HFlex>
              </FilterChip>
            ) : (
              <FilterChip
                prefixIcon="/icons/icon_today.png"
                prefixIconSize={18}
              >
                <Text t-14-500-s8>날짜 선택</Text>
              </FilterChip>
            )
          }
        />
      </HFlex>
      {groupNotifications.length === 0 ? (
        <HFlex c-c height={330} bdr-16 bd-t3 m-16-tb>
          <VFlex a-c g-8>
            <Image size={24} src={`/icons/icon_info_gray.png`} />
            <Text t-16-500-s4>내역이 존재하지 않습니다.</Text>
          </VFlex>
        </HFlex>
      ) : (
        <VFlex f-1 ovf-a>
          <VFlex f-1 g-24 m-32-t>
            <VFlex bd-tbl-t3 m-32-b style={{ minWidth: 800 }}>
              <HFlex a-c height={54} bc-t1 bd-b-t3 a-st>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>발송 날짜</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>발송 시간</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl width={150} sized>
                  <Text t-14-500-s4>고객 정보</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl width={150} sized>
                  <Text t-14-500-s4>연락처</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>발송 채널</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl width={180} sized>
                  <Text t-14-500-s4>발송 내용</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>발송 상태</Text>
                </HFlex>
              </HFlex>
              {groupNotifications.map((notification, i) => (
                <HFlex
                  key={i}
                  a-c
                  height={54}
                  bd-b-t3={i !== groupNotifications.length - 1}
                  a-st
                  classes={["table-row"]}
                  clickable
                  onClick={() => {
                    pushPopup({
                      kind: PopupKind.Popup,
                      element: AddSchedule,
                      props: { reservationId: notification.reservationId },
                    });
                  }}
                >
                  <HFlex a-c bd-r-t3 p-16-rl f-1 g-8 ovf-h>
                    <Text t-14-500-s9>
                      {format(
                        new Date(notification.sendDate * 1000),
                        "yyyy.MM.dd (EEEEE)",
                        {
                          locale: ko,
                        }
                      )}
                    </Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl f-1 g-8 ovf-h>
                    <Text t-14-500-s9>
                      {format(
                        new Date(notification.sendDate * 1000),
                        "a hh:mm",
                        {
                          locale: ko,
                        }
                      )}
                    </Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl g-8 width={150} sized ovf-h>
                    <Text
                      t-14-500-s9
                    >{`${notification.petName} (${notification.species.name})`}</Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl g-8 width={150} sized ovf-h>
                    <Text t-14-500-s9>{notification.cellNumber}</Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl f-1 g-8 ovf-h>
                    <Text t-14-500-s9>{notification.label}</Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl g-8 width={180} sized ovf-h>
                    <Text t-14-500-s9>{notification.title}</Text>
                  </HFlex>
                  <HFlex
                    a-c
                    bd-r-t3
                    p-16-rl
                    f-1
                    g-8
                    ovf-h
                    style={{
                      color:
                        notification.alimtalkSucceed || notification.smsSucceed
                          ? "#3EBA86"
                          : notification.scheduled
                          ? "#000000"
                          : "#B62C00",
                    }}
                  >
                    <Text t-14-700>
                      {notification.alimtalkSucceed || notification.smsSucceed
                        ? "발송 성공"
                        : notification.scheduled
                        ? "발송 예정"
                        : "발송 실패"}
                    </Text>
                  </HFlex>
                </HFlex>
              ))}
            </VFlex>
          </VFlex>
        </VFlex>
      )}
      <Pagination
        page={page}
        pageCount={totalGroupNotiCount / ITEMS_PER_PAGE}
        onPageChange={(n) => setPage(n)}
      />
    </VFlex>
  );
}

export default GroupNotificationList;
