import { InputHTMLAttributes, useRef, useState } from 'react';
import HFlex from '../layouts/HFlex';
import Image from '../layouts/Image';
import VFlex from '../layouts/VFlex';

function SearchInput({
  className,
  placeholder,
  value,
  height,
  style,
  onChangeValue,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  onChangeValue: (value: string) => void;
  [key: string]: any;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [cancelMouseDown, setCancelMouseDown] = useState<boolean>(false);
  return (
    <HFlex
      height={height}
      a-c
      g-8
      rel
      clickable
      onClick={() => {
        inputRef.current?.focus();
      }}
      style={style}
    >
      <Image size={24} src={`/icons/icon_search.svg`} />
      <HFlex a-c f-1>
        <VFlex width={100} f-1>
          <input
            placeholder={placeholder}
            ref={inputRef}
            value={value ?? ''}
            onChange={(e) => onChangeValue(e.target.value)}
            {...props}
          />
        </VFlex>
        {!!value && cancelMouseDown && (
          <Image
            size={24}
            src={`/icons/input_cancel.png`}
            onMouseDown={() => setCancelMouseDown(true)}
            onClick={(e) => {
              onChangeValue('');
              e.stopPropagation();
              setCancelMouseDown(false);
            }}
          />
        )}
      </HFlex>
    </HFlex>
  );
}

export default SearchInput;
