import { Dispatch, SetStateAction, useState } from "react";

import Absolute from "../../layouts/Absolute";
import FilterChip from "../../layouts/FilterChip";
import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import DnDList from "../DnDList";
import Dropdown from "../../layouts/Dropdown";
import DropdownLayout from "../../layouts/DropdownV2";

import { SORT_ORDER } from "../../common/constants";
import { Sorts } from "../../providers/types";

const SortFilter = ({
  options,
  sortFilters,
  setSortFilters,
}: {
  options: Sorts[];
  sortFilters: Sorts[];
  setSortFilters: Dispatch<SetStateAction<Sorts[]>>;
}) => {
  const [opened, setOpened] = useState(false);

  const handleDeleteItem = (category: string) => {
    setSortFilters((filters) => filters.filter((f) => f.category !== category));
  };

  const handleChangeSortOrder = (category: string, order: string) => {
    const newSortfilter = [...sortFilters];
    newSortfilter[
      newSortfilter.findIndex((i) => i.category === category)
    ].sortOrder = order;

    setSortFilters(newSortfilter);
  };

  const handleChangeSortCategory = (category: string, newCategory: string) => {
    const newFilter = { ...sortFilters.find((f) => f.category === category) };
    newFilter.category = newCategory;
    newFilter.title =
      options.find((o) => o.category === newCategory)?.title! ?? "";

    const newSortfilter = [...sortFilters];
    newSortfilter[newSortfilter.findIndex((i) => i.category === category)] =
      newFilter as Sorts;

    setSortFilters(newSortfilter);
  };

  const handleAddSortFilter = (category: string) => {
    setSortFilters((filters) => [
      ...filters,
      options.find((opt) => opt.category === category)!,
    ]);
  };

  return (
    <>
      <FilterChip
        size="S"
        type={sortFilters.length > 0 ? "selected" : undefined}
        onClick={() => setOpened((o) => !o)}
        prefixIcon={
          sortFilters.length > 1
            ? "/icons/active_arrow_both.svg"
            : sortFilters[0]?.sortOrder === "ASC"
            ? "/icons/active_arrow_upward.svg"
            : "/icons/active_arrow_downward.svg"
        }
        prefixIconSize={18}
        suffixIcon="/icons/active_arrow_drop_down.svg"
        suffixIconSize={18}
      >
        {sortFilters.length > 0
          ? `${sortFilters[0].title}${
              sortFilters.length > 1 ? ` 외 ${sortFilters.length - 1}` : ""
            }`
          : "정렬"}
      </FilterChip>

      {opened && (
        <Absolute
          top={"auto"}
          left={"auto"}
          right={"auto"}
          bottom={"auto"}
          style={{ zIndex: 2, marginTop: "40px" }}
        >
          <VFlex
            bdr-12
            bc-tw
            p-8-tb
            p-8-rl
            style={{
              boxShadow:
                "0px 4px 8px 1px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
            }}
          >
            <DnDList
              items={sortFilters.map((f) => ({ ...f, id: f.category }))}
              onDragEnd={(items) => {
                setSortFilters(items);
              }}
              renderItem={({ item }) => (
                <Item
                  options={[
                    ...options.filter(
                      (o) =>
                        !sortFilters.find((f) => f.category === o.category) ||
                        o.category === item.category
                    ),
                  ]}
                  item={item}
                  deleteItem={handleDeleteItem}
                  changeSortOrder={handleChangeSortOrder}
                  changeSortCategory={handleChangeSortCategory}
                />
              )}
            />

            <VFlex m-8-t>
              {options.length > sortFilters.length && (
                <VFlex>
                  <Dropdown
                    position="left"
                    trigger={
                      <HFlex p-6-tb p-8-rl bdr-8 g-4 clickable hover>
                        <Image src="/icons/add.svg" size={20} />
                        <Text t-14-500-s6>정렬 추가</Text>
                      </HFlex>
                    }
                    renderOptions={({ close }) => (
                      <DropdownLayout width={164}>
                        {[
                          ...options.filter((o) =>
                            sortFilters.find((f) => f.category !== o.category)
                          ),
                        ].map((i) => (
                          <DropdownLayout.Item key={i.category}>
                            <Text
                              f-1
                              t-14-500-s8
                              clickable
                              onClick={() => {
                                handleAddSortFilter(i.category);
                                close();
                              }}
                            >
                              {i.title}
                            </Text>
                          </DropdownLayout.Item>
                        ))}
                      </DropdownLayout>
                    )}
                  />
                </VFlex>
              )}
              <HFlex
                p-6-tb
                g-4
                p-8-rl
                bdr-8
                clickable
                onClick={() => setSortFilters([])}
                hover
              >
                <Image src="/icons/delete.svg" size={20} />
                <Text t-14-500-s6>정렬 제거</Text>
              </HFlex>
            </VFlex>
          </VFlex>
        </Absolute>
      )}
      {opened && (
        <Absolute style={{ zIndex: 1 }} onClick={() => setOpened(false)} />
      )}
    </>
  );
};

export default SortFilter;

const Item = ({
  item,
  deleteItem,
  changeSortOrder,
  changeSortCategory,
  options,
}: {
  item: Sorts;
  deleteItem: (category: string) => void;
  changeSortOrder: (category: string, order: string) => void;
  changeSortCategory: (category: string, newCategory: string) => void;
  options: Sorts[];
}) => {
  return (
    <HFlex a-c g-12 p-4-tb j-b p-8-rl>
      <Image src="/icons/drag_handle.svg" size={20} />
      <HFlex f-1 g-8>
        <Dropdown
          position="left"
          trigger={
            <FilterChip
              size="S"
              onClick={() => {}}
              suffixIcon="/icons/navigation/arrow_drop_down.svg"
              suffixIconSize={18}
            >
              {item.title}
            </FilterChip>
          }
          renderOptions={({ close }) => (
            <DropdownLayout width={180}>
              {options.map((o) => (
                <DropdownLayout.Item>
                  <Text
                    f-1
                    t-14-500-s8
                    onClick={() => {
                      changeSortCategory(item.category, o.category);
                      close();
                    }}
                  >
                    {o.title}
                  </Text>
                </DropdownLayout.Item>
              ))}
            </DropdownLayout>
          )}
        />

        <Dropdown
          position="left"
          trigger={
            <FilterChip
              size="S"
              onClick={() => {}}
              suffixIcon="/icons/navigation/arrow_drop_down.svg"
              suffixIconSize={18}
            >
              {SORT_ORDER[item.sortOrder]}
            </FilterChip>
          }
          renderOptions={({ close }) => (
            <DropdownLayout width={180}>
              <DropdownLayout.Item>
                <Text
                  f-1
                  t-14-500-s8
                  onClick={() => {
                    changeSortOrder(item.category, "ASC");
                    close();
                  }}
                >
                  오름차순
                </Text>
              </DropdownLayout.Item>
              <DropdownLayout.Item>
                <Text
                  f-1
                  t-14-500-s8
                  onClick={() => {
                    changeSortOrder(item.category, "DESC");
                    close();
                  }}
                >
                  내림차순
                </Text>
              </DropdownLayout.Item>
            </DropdownLayout>
          )}
        />
      </HFlex>
      <Image
        src="/icons/popup_close.png"
        size={20}
        clickable
        onClick={(e) => {
          e.preventDefault();
          deleteItem(item.category);
        }}
      />
    </HFlex>
  );
};
