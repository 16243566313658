import React, { useCallback, useEffect, useRef, useState } from "react";

import { scheduleStatusString } from "../../../common/constants";
import { numberWithCommas } from "../../../common/utils";
import { Histories } from "../../../providers/types";
import { useRevenueHistory } from "../../../providers/revenueHistory";

import { calcEndDate, calcStartDate } from "./SalesStatistics";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";

import { debounce } from "lodash";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

function ListItem({
  name,
  date,
  productName,
  productPrice,
  status,
  registeredRevenue,
}: Histories) {
  return (
    <HFlex g-16 p-16-tb p-24-rl a-c bd-b-t1 j-b>
      <VFlex>
        <Text t-14-s4>
          {name} ·&nbsp;
          {format(new Date(date * 1000), "M월 d일 (EEE) a h:mm", {
            locale: ko,
          })}
        </Text>
        <Text m-4-t m-8-b t-16-600-s7 l-m>
          {productName}
          {productPrice && ` · ${numberWithCommas(productPrice)}원`}
        </Text>
        <Text t-16-gr5>{scheduleStatusString[status]}</Text>
      </VFlex>

      {/* TODO : 결제 작업 후 진행 */}
      {/* {!registeredRevenue && (
        <VFlex height={46} j-c p-16-rl bc-s9 bdr-16 t-14-w>
          결제 진행
        </VFlex>
      )} */}
    </HFlex>
  );
}

function SalesList({
  date,
  calendarType,
}: {
  date: Date | undefined;
  calendarType: string;
}) {
  const startDate = calcStartDate(calendarType, date as Date);
  const endDate = calcEndDate(calendarType, startDate);

  const { revenueHistoryCount, fetchRevenueHistory } = useRevenueHistory();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [pageNum, setPageNum] = useState(0);
  const [histories, setHistories] = useState<Histories[]>([]);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting && !loading && hasNext) {
        setPageNum((no) => no + 1);
      }
    })
  );

  const fetchRevenueHistoryList = useCallback(async () => {
    try {
      setLoading(true);
      const list = await fetchRevenueHistory({
        startDate,
        endDate,
        page: pageNum,
      });
      setHistories((prev) => [...prev, ...list]);
      setHasNext(list.length !== 0);
    } finally {
      setLoading(false);
    }
  }, [fetchRevenueHistory, startDate, endDate, pageNum]);

  useEffect(() => {
    fetchRevenueHistoryList();
  }, [calendarType, date, pageNum, fetchRevenueHistoryList]);

  useEffect(() => {
    setHistories([]);
    setPageNum(0);
  }, [date, revenueHistoryCount]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current!;
      if (scrollHeight - scrollTop === clientHeight) {
        setPageNum((prev) => prev + 1);
      }
    }, 300);

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);

      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }

    return undefined;
  }, [scrollRef]);

  return (
    <VFlex bd-l-t3 width={376} ref={scrollRef}>
      {revenueHistoryCount === 0 ? (
        <VFlex width={376}>
          <VFlex
            a-c
            j-c
            bd-t3
            bdr-16
            g-8
            m-24
            style={{
              padding: "100px 0",
            }}
          >
            <Image size={24} src={`/icons/icon_noti.svg`} />
            <Text t-16-s2>제공한 서비스가 없습니다.</Text>
          </VFlex>
        </VFlex>
      ) : (
        <VFlex ref={scrollRef} ovf-s>
          <VFlex bc-w g-8 p-16-tb p-24-rl bd-b-t1 width={376}>
            <Text t-18-600-s8>
              {revenueHistoryCount}건의 서비스를 제공했어요
            </Text>
          </VFlex>
          <VFlex
            style={{ height: "100%", overflowY: "scroll", paddingBottom: 113 }}
          >
            {histories.map((i) => (
              <ListItem key={i.id} {...i} />
            ))}
            {!loading && hasNext && <VFlex ref={setLastElement} />}
          </VFlex>
        </VFlex>
      )}
    </VFlex>
  );
}

export default SalesList;
