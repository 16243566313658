import _, { isNil } from "lodash";
import hangul from "hangul-js";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  letterGroup,
  scheduleStatusColor,
  scheduleStatusString,
} from "../../../common/constants";
import SearchInput from "../../../components/SearchInput";
import {
  InquiryNotificationHistory,
  NoticeInterface,
  PastSchedule,
  Pet,
  Reservation,
  Guardian,
} from "../../../providers/types";
import AddCustomer from "../Customers/AddCustomer";
import api from "../../../common/api";
import { format } from "date-fns";
import { id, ko } from "date-fns/locale";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import AddSchedule from "./AddSchedule";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { pushPopup } from "../../../providers/popups";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import Divider from "../../../layouts/Divider";
import Button from "../../../layouts/Button";
import { usePartner } from "../../../providers/partner";
import { usePets } from "../../../providers/pets";
import useDebounce from "../../../common/useDebounce";
import NoticeDetail from "../Settings/NoticeDetail";
import Flex from "../../../layouts/Flex";
import SendNotice from "./SendNotice";
import { reg } from "../../../common/format";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function AddScheduleCustomer({
  popPopup,
  editingReservation,
  selectedCustomer,
  setSelectedCustomer,
  selectedGuardian,
  setSelectedGuardian,
  loadSchedule,
}: {
  popPopup: () => void;
  editingReservation?: Reservation;
  selectedCustomer?: Pet;
  setSelectedCustomer: Dispatch<SetStateAction<Pet | undefined>>;
  selectedGuardian?: Guardian;
  setSelectedGuardian: Dispatch<SetStateAction<Guardian | undefined>>;
  loadSchedule: () => void;
}) {
  const { trackClickAddServiceNote } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { fetchPets } = usePets();
  const [searchText, setSearchText] = useState<string>();
  const [pastSchedules, setPastSchedules] = useState<PastSchedule[]>([]);
  const [filteredPets, setFilteredPets] = useState<Pet[]>([]);
  const debouncedSearchText = useDebounce({ value: searchText, delay: 500 });
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
  const [notice, setNotice] = useState<NoticeInterface | undefined>();
  const [notificationHistory, setNotificationHistory] = useState<
    InquiryNotificationHistory[]
  >([]);

  const notificationHistoryStatus = (
    alimtalk: boolean,
    sms: boolean,
    scheduled: boolean
  ) => {
    if (alimtalk) {
      return <Text t-14-600-gr5>발송 성공</Text>;
    } else if (sms) {
      return <Text t-14-600-gr5>sms 발송 성공</Text>;
    } else if (scheduled) {
      return <Text t-14-600-s2>발송 예정</Text>;
    } else {
      return <Text t-14-600-err1>발송 실패</Text>;
    }
  };

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedCustomer.guardianList.length > 1 &&
      !selectedGuardian
    ) {
      pushPopup({
        kind: PopupKind.Popup,
        align: PopupAlign.TopCenter,
        element: ConfirmPopup,
        props: {
          title: `${selectedCustomer.name} (${selectedCustomer.species})에게 여러명의 보호자가 있습니다.\n어떤 보호자로 예약할까요?`,
          guardians: selectedCustomer.guardianList,
          onConfirm: async (guardian: any) => {
            setSelectedGuardian(guardian);
          },
        },
      });
    }
  }, [
    selectedCustomer,
    selectedGuardian,
    setSelectedCustomer,
    setSelectedGuardian,
  ]);

  useEffect(() => {
    (async () => {
      if (selectedCustomer) {
        const schedules = await api.get(
          `/rest/reservation/pet/${selectedCustomer.id}/history?groupId=${partner?.id}`
        );
        setPastSchedules(schedules);
      }
    })();
  }, [selectedCustomer, partner]);

  useEffect(() => {
    if (editingReservation && !isNil(editingReservation.reservationNumber)) {
      (async () => {
        const notice = await api.get(
          `/ext/reservation/notice?key=${editingReservation.reservationNumber}`
        );
        setNotice(notice);
        const alarms = await api.get(
          `/rest/group/${partner?.id}/notification/history?reservationId=${editingReservation.id}&type=RESV`
        );
        setNotificationHistory(alarms);
      })();
    }
  }, [editingReservation]);

  useEffect(() => {
    fetchPets({ page: 0, sort: "PET_NAME" });
  }, [fetchPets]);

  const customerList = useMemo(
    () => [
      {
        group: "기타",
        list: filteredPets.filter(
          (pet) =>
            !_.some(letterGroup, (letter) =>
              letter === "A-Z"
                ? hangul.disassembleToString(pet.name)[0].match(/[a-z]/i)
                : hangul.disassembleToString(pet.name)[0] === letter
            )
        ),
      },
      ...letterGroup.map((letter) => ({
        group: letter,
        list: filteredPets
          .filter((pet) =>
            letter === "A-Z"
              ? hangul.disassembleToString(pet.name)[0].match(/[a-z]/i)
              : hangul.disassembleToString(pet.name)[0] === letter
          )
          .filter((pet) =>
            _.some([
              pet.name,
              ...pet.guardianList.map((guardian) => guardian.cellnumber),
            ])
          ),
      })),
    ],
    [filteredPets]
  );

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    })
  );

  const fetchFilteredPets = useCallback(
    async (page: number, hasNext: boolean, selectedPetId?: number) => {
      if (!hasNext) {
        return;
      }

      try {
        setLoading(true);
        const newPets = await fetchPets({
          page,
          search: debouncedSearchText,
          sort: "PET_NAME",
        });
        if (newPets == null) {
          return;
        }
        if (page === 0) {
          await setFilteredPets(() => {
            const totalPets = [...newPets];
            if (selectedPetId) {
              setSelectedCustomer(
                totalPets.find((pet) => pet.id === selectedPetId)
              );
            }
            return [...totalPets];
          });
        } else {
          await setFilteredPets((pets) => {
            const totalPets = [...pets, ...newPets];
            if (selectedPetId) {
              setSelectedCustomer(
                totalPets.find((pet) => pet.id === selectedPetId)
              );
            }
            return [...totalPets];
          });
        }
        setHasNext(newPets.length !== 0);
      } finally {
        setLoading(false);
      }
    },
    [debouncedSearchText, fetchPets, setSelectedCustomer, setFilteredPets]
  );

  useEffect(() => {
    setFilteredPets([]);
    setPageNum(0);
    setHasNext(true);
    fetchFilteredPets(0, true);
  }, [fetchFilteredPets, debouncedSearchText]);

  useEffect(() => {
    if (pageNum === 0) {
      return;
    }
    fetchFilteredPets(pageNum, hasNext);
  }, [fetchFilteredPets, pageNum, hasNext]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  if (selectedCustomer) {
    return (
      <VFlex g-24 p-24 ovf-a>
        <HFlex a-c>
          <Text f-1 t-18-600-s11>
            상세정보
          </Text>
          {!editingReservation && (
            <Image
              size={24}
              src={`/icons/popup_close.png`}
              clickable
              onClick={() => {
                setSelectedCustomer(undefined);
                setSelectedGuardian(undefined);
                setPastSchedules([]);
              }}
            />
          )}
        </HFlex>
        <HFlex a-c p-12-t g-16>
          <Image
            bc-s6
            bdr-24
            size={48}
            src={selectedCustomer.attachment?.path ?? `/profile_example.png`}
          />
          <VFlex f-1>
            <Text f-1 t-14-s4>
              {selectedCustomer.species}
            </Text>
            <Text f-1 t-18-s8>
              {selectedCustomer.name}
            </Text>
          </VFlex>
        </HFlex>

        {editingReservation &&
          (notice ? (
            <Button
              height={54}
              bc-w
              bd-gr5
              bdr-16
              g-6
              onClick={() =>
                pushPopup({
                  kind: PopupKind.Drawer,
                  element: NoticeDetail,
                  props: {
                    reservationNumber: editingReservation.reservationNumber,
                  },
                })
              }
            >
              <>
                <Image src="/icons/checkbox_checked.png" size={24} />
                <Text t-16-500-gr5>보낸 알림장 확인하기</Text>
              </>
            </Button>
          ) : editingReservation.status === 4 &&
            !isNil(editingReservation.reservationNumber) ? (
            <Button
              height={54}
              bc-w
              t-16-500-gr5
              bd-gr5
              bdr-16
              caption="알림장 작성하기"
              onClick={() => {
                pushPopup({
                  kind: PopupKind.Drawer,
                  element: SendNotice,
                  props: {
                    petName: editingReservation.pet.petInfo.name,
                    reservationId: editingReservation.id,
                    loadSchedule,
                  },
                });
                trackClickAddServiceNote();
              }}
            />
          ) : (
            <></>
          ))}

        <VFlex p-24-rl p-16-tb bdr-16 bc-t1 sized>
          <Text
            l-m
            t-16-s9={!!selectedCustomer.note}
            t-16-s4={!selectedCustomer.note}
          >
            {selectedCustomer.note || "메모 없음"}
          </Text>
        </VFlex>
        <Divider m-16-t height={0.1} />

        <VFlex g-36>
          <VFlex>
            <Text t-16-700-s7 m-16-b>
              지난 내역 ({pastSchedules.length})
            </Text>
            <VFlex f-1 g-24>
              {pastSchedules.map((schedule, i) => (
                <VFlex
                  g-8
                  key={i}
                  clickable
                  onClick={() => {
                    pushPopup({
                      key: `pastSchedule_${schedule.id}`,
                      kind: PopupKind.Popup,
                      element: AddSchedule,
                      props: {
                        reservationId: schedule.id,
                      },
                    });
                  }}
                >
                  <VFlex>
                    <HFlex a-c g-16 m-4-b>
                      <Text t-14-500-s3>
                        {format(new Date(schedule.date), "yyyy. M. d (EEEEE)", {
                          locale: ko,
                        })}
                      </Text>
                      <Text t-14-500-s3 l-1>
                        {schedule.productName}
                      </Text>
                    </HFlex>

                    <Text
                      t-14-s9={!!schedule.note}
                      t-14-s4={!schedule.note}
                      l-2
                      m-8-b
                    >
                      {schedule.note || "메모 없음"}
                    </Text>

                    <HFlex a-c g-8>
                      {schedule.amount && (
                        <Text t-14-700-s8>
                          {schedule.amount.toLocaleString()}원
                        </Text>
                      )}
                      <Text
                        t-14-700
                        style={{ color: scheduleStatusColor[schedule.status] }}
                      >
                        {scheduleStatusString[schedule.status]}
                      </Text>
                    </HFlex>
                  </VFlex>
                </VFlex>
              ))}
            </VFlex>
          </VFlex>

          {notificationHistory.length > 0 && (
            <VFlex>
              <Text t-16-700-s7 m-16-b>
                알림톡 내역
              </Text>
              <VFlex g-24>
                {notificationHistory.map((history, i) => (
                  <VFlex key={i} g-8>
                    <Text t-14-500-s3>
                      {format(
                        new Date(history.sendDate * 1000),
                        "yyyy. M. d (EEEEE) a h:mm",
                        {
                          locale: ko,
                        }
                      )}
                    </Text>
                    <HFlex g-8>
                      <Text t-14-700-s8>{"[알림톡] " + history.title}</Text>
                      {notificationHistoryStatus(
                        history.alimtalkSucceed,
                        history.smsSucceed,
                        history.scheduled
                      )}
                    </HFlex>
                  </VFlex>
                ))}
              </VFlex>
            </VFlex>
          )}

          <VFlex>
            <Text t-16-700-s7 m-16-b>
              예약자 정보
            </Text>
            <VFlex m-24-b>
              <Text t-14-500-s12>{"예약자명"}</Text>
              <Text t-18-600-s11>
                {selectedGuardian
                  ? selectedGuardian.name
                  : selectedCustomer.guardianList[0].name}
              </Text>
            </VFlex>
            <Flex
              l-m
              bc-t1
              p-24-rl
              p-16-tb
              bdr-16
              t-16-500-bl3={
                editingReservation?.guardian.note ||
                (selectedGuardian
                  ? selectedGuardian.note
                  : selectedCustomer?.guardianList[0].note)
              }
              t-16-500-s2={
                !(
                  editingReservation?.guardian.note ||
                  (selectedGuardian
                    ? selectedGuardian.note
                    : selectedCustomer?.guardianList[0].note)
                )
              }
            >
              {editingReservation?.guardian.note ||
                (selectedGuardian
                  ? selectedGuardian.note
                  : selectedCustomer?.guardianList[0].note) ||
                "메모 없음"}
            </Flex>
          </VFlex>
        </VFlex>
      </VFlex>
    );
  } else {
    return (
      <VFlex f-1 ovf-h>
        <HFlex a-c g-8 p-16>
          <VFlex f-1 bdr-16 p-24-rl bd-t3 ovf-h>
            <SearchInput
              height={56}
              placeholder="고객명을 검색하세요"
              value={searchText}
              onChangeValue={(value) => {
                setSearchText(reg(value));
              }}
            />
          </VFlex>
          <Button
            bdr-16
            width={55}
            height={55}
            p-0
            type="cta-lg"
            onClick={() =>
              pushPopup({
                kind: PopupKind.Popup,
                element: AddCustomer,
                props: {
                  fetchCurrentPage: (customerId?: number) => {
                    fetchFilteredPets(0, true, customerId);
                  },
                },
              })
            }
          >
            <Image size={24} src={`/icons/icon_add.png`} />
          </Button>
        </HFlex>
        <VFlex f-1 ovf-a>
          {customerList.map(
            (customerGroup, i) =>
              customerGroup.list.length > 0 && (
                <VFlex key={i}>
                  <HFlex a-c p-32-rl height={34} bc-t2>
                    <Text t-16-700-s6>{customerGroup.group}</Text>
                  </HFlex>
                  <VFlex m-16-tb p-24-rl>
                    {customerGroup.list.map((customer, j) => (
                      <HFlex
                        a-c
                        g-16
                        p-8-rl
                        height={56}
                        key={j}
                        clickable
                        onClick={() => {
                          setSelectedCustomer(customer);
                        }}
                      >
                        <Image
                          width={40}
                          height={40}
                          bc-s8
                          bdr-24
                          src={
                            customer.attachment?.path ?? `/profile_example.png`
                          }
                        />
                        <VFlex f-1>
                          <Text t-14-700-s9 l-1>
                            {customer.name} ({customer.species})
                          </Text>

                          <Text f-1 t-12-s4 l-m>
                            {customer.guardianList
                              .map((guardian) => guardian.cellnumber)
                              .join(", ")}
                          </Text>
                        </VFlex>
                        {!loading && hasNext && <VFlex ref={setLastElement} />}
                      </HFlex>
                    ))}
                  </VFlex>
                </VFlex>
              )
          )}
        </VFlex>
      </VFlex>
    );
  }
}

export default AddScheduleCustomer;
