import { MouseEvent, useEffect, useState } from "react";
import ColumnResize from "react-table-column-resizer";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import toast from "react-hot-toast";

import HFlex from "../../../layouts/HFlex";
import VFlex from "../../../layouts/VFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import Flex from "../../../layouts/Flex";
import Pagination from "../../../components/Pagination";
import Dropdown from "../../../layouts/Dropdown";
import GuardianDropdown from "../../../components/GurdianDropdown";
import CustomerListFilter from "../../../components/Customers/CustomerListFilter";
import CustomerDetail from "./CustomerDetail";
import TagDropdown from "../../../components/TagDropdown";
import LoadingView from "../../../layouts/LoadingView";

import { Pet } from "../../../providers/types";
import useCustomersContext from "../../../providers/customers";
import useDeletedCustomersContext from "../../../providers/deletedCustomers";
import { pushPopup } from "../../../providers/popups";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { numberWithCommas } from "../../../common/utils";

// TODO
const ITEMS_PER_PAGE = 10;

const DefaultCell = () => <Text t-14-500-s2>-</Text>;

function CustomerList() {
  const [selectedPets, setSelectedPets] = useState<number[]>([]);
  const {
    totalCount,
    searchText,
    pets,
    page,
    fetchCurrentPage,
    deleteCustomers,
    setPage,
    isLoading,
  } = useCustomersContext();
  const { refreshDeletedCustomers } = useDeletedCustomersContext();

  useEffect(() => {
    setSelectedPets([]);
  }, [searchText, page]);

  const deleteCustomerList = () => {
    pushPopup({
      element: ConfirmPopup,
      align: PopupAlign.TopCenter,
      kind: PopupKind.Popup,
      props: {
        title:
          "선택된 고객을 삭제할까요?\n삭제 후에도 삭제된 고객 목록에서 확인할 수 있습니다.",
        confirmButtonLabel: "삭제하기",
        confirmButtonType: "delete",
        onConfirm: () => {
          (async () => {
            try {
              await deleteCustomers(selectedPets);
              await fetchCurrentPage();

              toast.success(
                `${selectedPets.length}명의 고객이 삭제되었습니다.`
              );
              setSelectedPets([]);

              await refreshDeletedCustomers();
            } catch (e) {
              toast.error("고객 삭제에 실패하였습니다.");
            }
          })();
        },
        Contents: () => (
          <Text t-12-500-s4>
            (*복구하기 기능은 플러스 플랜 이상부터 이용할 수 있습니다.)
          </Text>
        ),
      },
    });
  };

  return (
    <VFlex f-1 rel>
      <CustomerListFilter />

      <VFlex p-24-rl f-1>
        <HFlex height={56} a-c>
          <Text t-16-600-s8>전체 ({numberWithCommas(totalCount)})</Text>
        </HFlex>

        <LoadingView f-1 loading={isLoading}>
          <VFlex ovf-a>
            {selectedPets.length > 0 && (
              <HFlex a-c p-20-rl g-12 height={46}>
                <Flex
                  c-c
                  clickable
                  onClick={() => {
                    if (selectedPets.length === 0) {
                      setSelectedPets(pets.map((pet) => pet.id));
                    } else {
                      setSelectedPets([]);
                    }
                  }}
                >
                  <Image
                    src={
                      selectedPets.length > 0 &&
                      selectedPets.length >= pets.length
                        ? "/icons/checkbox_checked.svg"
                        : "/icons/checkbox_unchecked.svg"
                    }
                    size={20}
                  />
                </Flex>
                <HFlex g-4 a-c clickable onClick={deleteCustomerList}>
                  <Image src="/icons/icon_delete.svg" size={20} />
                  <Text t-14-500 style={{ color: "#FF4F17" }}>
                    삭제하기
                  </Text>
                </HFlex>

                <Text t-14-500-s4>
                  이 페이지의{" "}
                  <b className="t-14-600-s8">{selectedPets.length}명</b>의
                  고객이 선택되었습니다.
                </Text>
              </HFlex>
            )}
            {pets.length > 0 ? (
              <table>
                <thead
                  style={{
                    height: selectedPets.length > 0 ? "0px" : "46px",
                  }}
                >
                  <tr>
                    <th>
                      <Flex
                        c-c
                        clickable
                        onClick={() => {
                          if (selectedPets.length === 0) {
                            setSelectedPets(pets.map((pet) => pet.id));
                          } else {
                            setSelectedPets([]);
                          }
                        }}
                      >
                        {selectedPets.length === 0 && (
                          <Image
                            src={
                              selectedPets.length > 0 &&
                              selectedPets.length >= pets.length
                                ? "/icons/checkbox_checked.svg"
                                : "/icons/checkbox_unchecked.svg"
                            }
                            size={20}
                          />
                        )}
                      </Flex>
                    </th>
                    <ColumnResize
                      id={1}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={36}
                      minWidth={36}
                      maxWidth={null}
                      disabled
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text p-12-tb t-14-500-s4>
                          고객명 (품종)
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={2}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={220}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text a-c p-12-tb t-14-500-s4>
                          보호자 정보
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={3}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={230}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text a-c p-12-tb t-14-500-s4>
                          최근 방문 날짜
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={4}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={160}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text a-c p-12-tb t-14-500-s4>
                          고객 태그
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={5}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={120}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text a-c p-12-tb t-14-500-s4>
                          반려동물 메모
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={6}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={200}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                  </tr>
                </thead>
                <tbody>
                  {pets.map((pet, index) => (
                    <CustomerListItem
                      key={pet.id}
                      pet={pet}
                      isSelected={!!selectedPets.find((p) => p === pet.id)}
                      setSelectedPets={setSelectedPets}
                      fetchCurrentPage={fetchCurrentPage}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <VFlex
                a-c
                j-c
                bd-t3
                bdr-16
                m-12-t
                g-8
                style={{
                  padding: "100px 0",
                }}
              >
                <Image size={24} src={`/icons/icon_noti.svg`} />
                <Text t-16-s2>등록된 고객이 없어요</Text>
              </VFlex>
            )}
          </VFlex>
        </LoadingView>
      </VFlex>
      <VFlex p-16-tb>
        <Pagination
          page={page}
          pageCount={totalCount / ITEMS_PER_PAGE}
          onPageChange={(n) => setPage(n)}
        />
      </VFlex>
    </VFlex>
  );
}

export default CustomerList;

interface CustomerListItemProps {
  pet: Pet;
  isSelected: boolean;
  setSelectedPets: React.Dispatch<React.SetStateAction<number[]>>;
  fetchCurrentPage: () => void;
}

function CustomerListItem({
  pet,
  isSelected,
  setSelectedPets,
  fetchCurrentPage,
}: CustomerListItemProps) {
  const pushCustomerDetailPopup = () => {
    pushPopup({
      kind: PopupKind.Popup,
      element: CustomerDetail,
      props: {
        petId: pet.id,
        fetchCurrentPage,
      },
      dimClick: "none",
    });
  };

  return (
    <tr onClick={() => pushCustomerDetailPopup()} className="hover">
      <td>
        <VFlex
          c-c
          clickable
          onClick={(e: MouseEvent) => {
            if (isSelected) {
              setSelectedPets((pets) => pets.filter((p) => p !== pet.id));
            } else {
              setSelectedPets((pets) => [...pets, pet.id]);
            }
            e.stopPropagation();
          }}
        >
          <Image
            src={
              isSelected
                ? "/icons/checkbox_checked.svg"
                : "/icons/checkbox_unchecked.svg"
            }
            size={20}
          />
        </VFlex>
      </td>
      <td className="column_resizer_body none" />
      <td>
        <HFlex a-c g-16>
          <Image
            src={pet.attachment?.path ?? "/icons/default_profile.png"}
            size={36}
            bdr-12
            sized
          />
          <Text t-14-500-s8 l-1>
            {pet.name} ({pet.species})
          </Text>
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex a-c g-6 rel>
          <Text t-14-500-s8 l-1>
            {pet.guardianList[0].name ?? "-"}
          </Text>

          <Text t-14-500-s8>({pet.guardianList[0].cellnumber})</Text>

          {pet.guardianList.length > 1 && (
            <Dropdown
              position="right"
              trigger={<Text t-14-500-s4>+{pet.guardianList.length - 1}</Text>}
              renderOptions={() => (
                <GuardianDropdown guardianList={pet.guardianList.slice(1)} />
              )}
              triggerAction="hover"
            />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex a-c g-6>
          {pet.lastVisited ? (
            <Text t-14-500-s8 l-1>
              {format(new Date(pet.lastVisited * 1000), "yyyy.MM.dd (EEE)", {
                locale: ko,
              })}
            </Text>
          ) : (
            <DefaultCell />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex a-c g-6>
          {pet.tags && pet.tags?.length > 0 ? (
            <HFlex rel g-4>
              <HFlex
                p-6-rl
                p-2-tb
                a-c
                bdr-6
                style={{
                  backgroundColor: pet.tags[0].backgroundColor,
                }}
              >
                <Text t-12 style={{ color: pet.tags[0].textColor }}>
                  {pet.tags[0].title}
                </Text>
              </HFlex>
              {pet.tags.length > 1 && (
                <Dropdown
                  position="left"
                  triggerAction="hover"
                  trigger={<Text t-14-500-s4>+{pet.tags.length - 1}</Text>}
                  renderOptions={() => (
                    <TagDropdown tagList={pet.tags!.slice(1)} />
                  )}
                />
              )}
            </HFlex>
          ) : (
            <DefaultCell />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex g-6 a-c ovf-h>
          {pet.note ? (
            <Text t-14-500-s8 l-1>
              {pet.note}
            </Text>
          ) : (
            <DefaultCell />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body none" />
    </tr>
  );
}
