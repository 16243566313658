import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Histories, Partner } from "./types";

const revenueHistoryStore = create(
  immer<{
    revenueHistoryCount: number;
    fetchRevenueHistory: (
      partner: Partner
    ) => ({
      startDate,
      endDate,
      page,
    }: {
      startDate: number;
      endDate: number;
      page: number;
    }) => Promise<Histories[]>;
  }>((set) => ({
    revenueHistoryCount: 0,
    fetchRevenueHistory:
      (partner: Partner) =>
      async ({
        startDate,
        endDate,
        page,
      }: {
        startDate: number;
        endDate: number;
        page: number;
      }) => {
        const { count, histories } = await api.get(
          `/rest/group/${
            partner!.id
          }/revenue-history?startDate=${startDate}&endDate=${endDate}&size=10&page=${
            10 * page
          }`
        );
        set((state) => ({
          ...state,
          revenueHistoryCount: count,
        }));

        return histories;
      },
  }))
);

export const useRevenueHistory = () => {
  const { partner } = usePartner();
  const { fetchRevenueHistory, ...rest } = revenueHistoryStore();
  return {
    fetchRevenueHistory: useMemo(
      () => fetchRevenueHistory(partner),
      [fetchRevenueHistory, partner]
    ),
    ...rest,
  };
};
