// base
import { useCallback, useEffect, useMemo, useState } from "react";

// common
import api from "../../../common/api";
import { scheduleStatusString } from "../../../common/constants";
import Spinner from "../../../common/Spinner/Spinner";
import {
  minutesToTimeString,
  timeStringToMinutes,
} from "../../../common/format";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

// components
import Dropdown from "../../../components/Dropdown";
import DatePicker from "../../../components/DatePicker";
import PhotosSwiperPopup from "../../../components/PhotosSwiperPopup";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import CancelSchedulePopup from "../../../popup/ConfirmPopup/CancelSchedulePopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import MenuSelect from "./MenuSelect";
import AddScheduleCustomer from "./AddScheduleCustomer";
import ParticalMemo from "./ParticalMemo";
import Questionnaire from "./Questionnaire";

// providers
import {
  DetailsType,
  Pet,
  QuestionHistoryInterface,
  Reservation,
  ScheduleStatus,
  Staff,
  Guardian,
  Service,
} from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { usePartner } from "../../../providers/partner";
import { useStaffs } from "../../../providers/staffs";
import { useSchedules } from "../../../providers/schedules";
import { useCount } from "../../../providers/count";
import { useDeleteds } from "../../../providers/deleteds";

// layouts
import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import TextArea from "../../../layouts/TextArea";
import Button from "../../../layouts/Button";
import Divider from "../../../layouts/Divider";
import Flex from "../../../layouts/Flex";
import Absolute from "../../../layouts/Absolute";

// lib
import _ from "lodash";
import { differenceInMinutes, format, parseISO, startOfDay } from "date-fns";
import { toast } from "react-hot-toast";

function AddSchedule({
  popPopup,
  reservationId,
  selectedPet,
  initialStaffs,
  startTime,
  fetchCurrentPage,
  ...props
}: {
  popPopup: () => void;
  reservationId?: number;
  initialStaffs?: Staff[];
  selectedPet?: Pet;
  startDateTime?: Date;
  endDateTime?: Date;
  startTime?: Date;
  fetchCurrentPage?: () => void;
}) {
  const {
    trackClickSaveReservationSchedule,
    trackEditReservationSchedule,
    trackLandReservationDetail,
  } = useAmplitudeTrack();

  useEffect(() => {
    if (!reservationId) {
      return;
    } else {
      trackLandReservationDetail();
    }
  }, []);
  const { partner } = usePartner();
  const { staffs } = useStaffs();
  const { fetchSchedules } = useSchedules();
  const { fetchCount } = useCount();
  const [editingSchedule, setEditingSchedule] = useState<Reservation>();
  const [loading, setLoading] = useState<boolean>(false);
  const [questionHistory, setQuestionHistory] =
    useState<QuestionHistoryInterface>();
  const [selectedStaffs, setSelectedStaffs] = useState<Staff[] | undefined>(
    initialStaffs
  );
  const [date, setDate] = useState<Date | undefined>(
    props.startDateTime ? startOfDay(props.startDateTime) : undefined
  );
  const [startMinute, setStartMinute] = useState<number | undefined>(
    date && props.startDateTime
      ? differenceInMinutes(props.startDateTime, date)
      : undefined
  );
  const [endMinute, setEndMinute] = useState<number | undefined>(
    date && props.endDateTime
      ? differenceInMinutes(props.endDateTime, date)
      : undefined
  );
  const [selectedProducts, setSelectedProducts] = useState<Service[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Pet | undefined>(
    selectedPet
  );
  const [selectedGuardian, setSelectedGuardian] = useState<
    Guardian | undefined
  >();
  const [note, setNote] = useState<string>("");
  const [status, setStatus] = useState<ScheduleStatus | undefined>();
  const isSavable = useMemo(() => {
    return (
      !editingSchedule?.status ||
      ![
        ScheduleStatus.USER_CANCEL,
        ScheduleStatus.PARTNER_CANCEL,
        ScheduleStatus.NO_SHOW,
        ScheduleStatus.COMPLETE,
      ].includes(editingSchedule.status)
    );
  }, [editingSchedule]);
  const isCancellable = useMemo(
    () => editingSchedule?.status === ScheduleStatus.CONFIRM,
    [editingSchedule]
  );
  const isUnhidable = useMemo(
    () => !!editingSchedule?.hidden,
    [editingSchedule]
  );
  const isHidable = useMemo(
    () =>
      editingSchedule?.status &&
      [
        ScheduleStatus.PARTNER_CANCEL,
        ScheduleStatus.NO_SHOW,
        ScheduleStatus.COMPLETE,
      ].includes(editingSchedule?.status),
    [editingSchedule]
  );

  const loadQuestionnaire = async () => {
    const questionHistory = await api.get<QuestionHistoryInterface[]>(
      `/ext/questionnaire/history?groupId=${partner.id}&petId=${editingSchedule?.petId}`
    );
    if (questionHistory) {
      const question = questionHistory[0];
      setQuestionHistory(question);
    }
  };

  const loadSchedule = useCallback(async () => {
    const reservation = await api.get<Reservation>(
      `/rest/reservation/${reservationId}?groupId=${partner.id}`
    );
    if (reservation) {
      const attachments = await api.get(
        `/rest/attachments?objectId=${reservation.id}&objectType=reservation`
      );
      setSelectedStaffs(reservation.staffs);
      setDate(parseISO(reservation.date));
      setStartMinute(timeStringToMinutes(reservation.startTime));
      setEndMinute(timeStringToMinutes(reservation.endTime));
      setSelectedProducts(reservation.products);
      setSelectedCustomer({
        id: reservation.petId,
        name: reservation.pet.petInfo.name,
        species: reservation.pet.petInfo.species.name,
        note: reservation.pet.petInfo.note,
        attachment: reservation.pet.petInfo.attachment,
        guardianList: [reservation.guardian],
      });
      setNote(reservation.memo);
      setStatus(reservation.status);
      setEditingSchedule({ ...reservation, attachments });
      setLoading(false);
    }
  }, [partner, reservationId]);
  useEffect(() => {
    if (reservationId) {
      setLoading(true);
      loadSchedule();
    }
  }, [loadSchedule, reservationId]);
  useEffect(() => {
    if (editingSchedule) {
      loadQuestionnaire();
    }
  }, [editingSchedule]);
  const popSchedulePopup = useCallback(
    async (toastText: string) => {
      await fetchSchedules(date);
      if (reservationId) {
        await loadSchedule();
      }
      toast.success(toastText);
      popPopup();
    },
    [fetchSchedules, loadSchedule, popPopup, reservationId, date]
  );

  const [isStaff, setIsStaff] = useState<DetailsType>();
  const fetchDetils = async () => {
    const res = await api.get(
      `/rest/group/${partner.id}/details?type=CALENDAR_S`
    );
    setIsStaff(res[0]);
  };
  useEffect(() => {
    fetchDetils();
  }, []);

  const isCompleted = useCallback(() => {
    return (
      !!date &&
      !_.isNil(startMinute) &&
      !_.isNil(endMinute) &&
      startMinute < endMinute &&
      !!selectedCustomer &&
      selectedProducts.length > 0 &&
      (isStaff?.value === "FALSE" &&
      !!(selectedStaffs?.length === 0 || selectedStaffs === undefined)
        ? false
        : true)
    );
  }, [
    date,
    startMinute,
    endMinute,
    selectedCustomer,
    selectedProducts,
    selectedStaffs,
  ]);

  const isStatusCompleted = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.COMPLETE &&
        status === ScheduleStatus.COMPLETE
      );
    }
  }, [status, editingSchedule]);
  const isStatusNoShow = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.NO_SHOW &&
        status === ScheduleStatus.NO_SHOW
      );
    }
  }, [status, editingSchedule]);
  const isStatusCancelled = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.PARTNER_CANCEL &&
        status === ScheduleStatus.PARTNER_CANCEL
      );
    }
  }, [status, editingSchedule]);
  const isStaffChanged = useCallback(() => {
    if (editingSchedule) {
      return (
        JSON.stringify(selectedStaffs) !==
        JSON.stringify(editingSchedule.staffs)
      );
    }
  }, [editingSchedule, selectedStaffs]);
  const isNoteChanged = useCallback(() => {
    if (editingSchedule) {
      return note !== editingSchedule.memo;
    }
  }, [note, editingSchedule]);
  const isUserNotifiedFieldsChanged = useCallback(() => {
    if (editingSchedule) {
      return (
        date?.getTime() !== parseISO(editingSchedule.date).getTime() ||
        startMinute !== timeStringToMinutes(editingSchedule.startTime) ||
        endMinute !== timeStringToMinutes(editingSchedule.endTime) ||
        JSON.stringify(selectedProducts) !==
          JSON.stringify(editingSchedule?.products) ||
        selectedCustomer?.id !== editingSchedule.petId
      );
    }
  }, [
    editingSchedule,
    date,
    startMinute,
    endMinute,
    selectedProducts,
    selectedCustomer,
  ]);
  const isChanged = useCallback(() => {
    if (editingSchedule) {
      return (
        JSON.stringify(selectedStaffs) !==
          JSON.stringify(editingSchedule.staffs) ||
        date?.getTime() !== parseISO(editingSchedule.date).getTime() ||
        startMinute !== timeStringToMinutes(editingSchedule.startTime) ||
        endMinute !== timeStringToMinutes(editingSchedule.endTime) ||
        JSON.stringify(selectedProducts) !==
          JSON.stringify(editingSchedule?.products) ||
        selectedCustomer?.id !== editingSchedule.petId ||
        note !== editingSchedule.memo ||
        status !== editingSchedule.status
      );
    } else {
      return (
        !!date ||
        !_.isNil(startMinute) ||
        !_.isNil(endMinute) ||
        !!selectedCustomer ||
        selectedProducts.length > 0
      );
    }
  }, [
    date,
    startMinute,
    endMinute,
    selectedCustomer,
    selectedProducts,
    note,
    editingSchedule,
    status,
    selectedStaffs,
  ]);
  const cancelSchedule = useCallback(() => {
    pushPopup({
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      element: CancelSchedulePopup,
      props: {
        title: "예약을 취소하면 고객에게 취소에 대한 메세지가 보내집니다.",
        confirmButtonLabel: "예약 취소하고 메세지 보내기",
        confirmButtonStyle: "warning",
        onConfirm: async (reason: any) => {
          setLoading(true);
          popPopup();
          await api.put(
            `/rest/reservation/${reservationId}/cancel?accountType=2`,
            {
              cancelNote: reason,
              id: reservationId,
              requestNote: "",
              type: "",
            }
          );
          popSchedulePopup("예약이 취소되었습니다.");
          if (fetchCurrentPage) fetchCurrentPage();
        },
      },
    });
  }, [reservationId, popSchedulePopup, popPopup]);

  const isStatusConfirmVisited = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.CONFIRM_VISIT &&
        status === ScheduleStatus.CONFIRM_VISIT
      );
    }
  }, [status, editingSchedule]);

  useEffect(() => {
    if (!reservationId && !_.isNil(startMinute) && !props.endDateTime) {
      const totalDuration = selectedProducts
        .map((p) => p.product.duration)
        .reduce((acc, cur) => acc + cur, 0);

      if (totalDuration <= 0) {
        setEndMinute(startMinute + 30);
      } else {
        setEndMinute(startMinute + totalDuration);
      }
    }
  }, [selectedProducts, startMinute, reservationId]);

  const { fetchDeleteds } = useDeleteds();
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(0);
  }, [date]);

  return (
    <VFlex
      a-st
      width={1043}
      height={800}
      style={{ maxWidth: "95vw", maxHeight: "95vh" }}
    >
      <VFlex bd-b-t3>
        <HFlex m-32>
          <Text f-1 t-20-700-s9>
            {reservationId ? "예약 상세" : "새로운 예약"}
          </Text>
          <Image
            size={24}
            src={`/icons/popup_close.png`}
            clickable
            onClick={() => {
              isChanged()
                ? pushPopup({
                    kind: PopupKind.Popup,
                    align: PopupAlign.TopCenter,
                    element: ConfirmPopup,
                    props: {
                      title: "변경사항을 저장하지 않고 닫을까요?",
                      onConfirm: () => {
                        popPopup();
                      },
                    },
                  })
                : popPopup();
            }}
          />
        </HFlex>
      </VFlex>
      {loading ? (
        <VFlex c-c f-1>
          <Spinner />
        </VFlex>
      ) : (
        <HFlex f-1 a-st ovf-h>
          <VFlex width={320} style={{ boxSizing: "border-box" }}>
            <AddScheduleCustomer
              popPopup={popPopup}
              editingReservation={editingSchedule}
              selectedCustomer={selectedCustomer}
              selectedGuardian={selectedGuardian}
              setSelectedCustomer={setSelectedCustomer}
              setSelectedGuardian={setSelectedGuardian}
              loadSchedule={loadSchedule}
            />
          </VFlex>
          <Divider width={1} />
          <VFlex f-1 ovf-a>
            <VFlex
              f-1
              g-16
              ovf-a
              p-24-tb
              p-32-l
              style={{ paddingRight: "75px" }}
            >
              {questionHistory && (
                <HFlex style={{ justifyContent: "flex-end" }}>
                  <HFlex
                    p-8-tb
                    p-16-rl
                    g-8
                    c-c
                    bd-t2
                    bdr-8
                    clickable
                    onClick={() =>
                      pushPopup({
                        kind: PopupKind.Drawer,
                        element: Questionnaire,
                        props: {
                          questionnaireId: questionHistory.id,
                          petName: editingSchedule?.pet.petInfo.name ?? "",
                          questionnaireSubmitedDate:
                            questionHistory?.registeredDate,
                        },
                      })
                    }
                  >
                    <Image src={"/icons/questionnaire.png"} size={16} />
                    <Text t-14-700-gr5>작성된 안전 문진표 보기</Text>
                  </HFlex>
                </HFlex>
              )}
              <HFlex a-c g-16>
                {editingSchedule && (
                  <VFlex f-1>
                    <Dropdown
                      isDisabled={!isSavable}
                      value={status}
                      options={[
                        ScheduleStatus.CONFIRM,
                        ScheduleStatus.COMPLETE,
                        ScheduleStatus.NO_SHOW,
                        ScheduleStatus.PARTNER_CANCEL,
                        ScheduleStatus.CONFIRM_VISIT,
                      ].map((value) => ({
                        value,
                        label: scheduleStatusString[value],
                      }))}
                      caption={"예약상태"}
                      onChange={(value) => {
                        setStatus(value?.value);
                      }}
                      required={true}
                    />
                  </VFlex>
                )}
                <VFlex f-1>
                  <Dropdown
                    multi={true}
                    values={selectedStaffs?.map((staff) => staff.id.toString())}
                    options={staffs.map((staff) => ({
                      value: staff.id.toString(),
                      obj: staff,
                      label: `${staff.name} ${staff.jobTitle}`,
                    }))}
                    caption={"담당자"}
                    placeholder={
                      isStaff?.value === "FALSE"
                        ? "담당자를 선택하세요"
                        : "미지정"
                    }
                    onChangeValues={(values) => {
                      setSelectedStaffs(values?.map((value) => value.obj));
                    }}
                    required={true}
                  />
                </VFlex>
              </HFlex>

              <HFlex a-st g-16>
                <VFlex f-1 g-8>
                  <HFlex g-8>
                    <Text t-14-500-s6>예약일자</Text>
                    <Text t-14-500-r1>*</Text>
                  </HFlex>
                  <DatePicker
                    date={date}
                    setDate={setDate}
                    trigger={
                      <HFlex p-24-rl p-16-tb bdr-16 bd-t3 bc-t1 clickable j-b>
                        {date ? (
                          <Text>{`${format(date, "yyyy년 MM월 dd일")}`}</Text>
                        ) : (
                          <Text t-16-500-s2>날짜를 선택하세요</Text>
                        )}

                        <Image
                          src="/icons/navigation/arrow_drop_down_s3.svg"
                          size={22}
                        />
                      </HFlex>
                    }
                  />
                </VFlex>
                <VFlex f-1>
                  <Dropdown
                    placeholder="서비스를 선택하세요"
                    isDisabled={true}
                    value={selectedProducts.length > 0 ? 0 : undefined}
                    options={[
                      {
                        value: 0,
                        label: selectedProducts
                          .map(
                            (product) =>
                              `${product.product.name}${
                                product.quantity > 1
                                  ? `*${product.quantity}`
                                  : ""
                              }`
                          )
                          .join(", "),
                      },
                    ]}
                    caption={"서비스"}
                    onClick={() => {
                      if (isSavable) {
                        pushPopup({
                          kind: PopupKind.Popup,
                          element: MenuSelect,
                          props: {
                            selectedProducts: selectedProducts,
                            onSubmit: (selectedProducts: Service[]) => {
                              setSelectedProducts(selectedProducts);
                            },
                          },
                          width: 950,
                        });
                      }
                    }}
                    required={true}
                  />
                </VFlex>
              </HFlex>
              <HFlex a-st g-16>
                <VFlex f-1>
                  <Dropdown
                    placeholder="시작 시간을 선택하세요"
                    isDisabled={!isSavable}
                    value={startMinute}
                    options={[...Array(283).keys()]
                      .map((value) => value * 5)
                      .map((value) => ({
                        value,
                        label: `${value >= 720 ? "오후" : "오전"} ${(
                          "0" + (Math.floor(value / 60) % 12 || 12)
                        ).slice(-2)}:${("0" + (value % 60)).slice(-2)}`,
                      }))}
                    onChange={(value) => {
                      setStartMinute(value?.value);
                      setEndMinute(undefined);
                      const totalDuration = selectedProducts
                        .map((p) => p.product.duration)
                        .reduce((acc, cur) => acc + cur, 0);

                      if (totalDuration > 0) {
                        setEndMinute(value?.value + totalDuration);
                      }
                    }}
                    caption={"시작 시간"}
                    required={true}
                  />
                </VFlex>
                <VFlex f-1>
                  <Dropdown
                    placeholder="소요 시간을 선택하세요"
                    isDisabled={!isSavable || _.isNil(startMinute)}
                    value={
                      _.isNil(startMinute)
                        ? undefined
                        : endMinute! - startMinute
                    }
                    options={[...Array(283 - (startMinute ?? 0) / 5).keys()]
                      .map((value) => value * 5 + 30)
                      .map((value) => ({
                        value,
                        label: `${
                          value >= 60 ? `${Math.floor(value / 60)}시간` : ""
                        } ${value % 60}분 (~${(
                          "0" + Math.floor((startMinute! + value) / 60)
                        ).slice(-2)}:${(
                          "0" +
                          ((startMinute! + value) % 60)
                        ).slice(-2)})`,
                      }))}
                    caption={"소요 시간"}
                    onChange={(value) =>
                      setEndMinute(
                        value ? startMinute! + value.value : undefined
                      )
                    }
                    required={true}
                  />
                </VFlex>
              </HFlex>
              <HFlex g-16 a-s>
                <Flex f-1>
                  <TextArea
                    caption="메모"
                    value={note ?? ""}
                    onChangeValue={(value) => setNote(value ?? "")}
                    placeholder={"메모를 입력하세요"}
                    height={note ? 80 : 56}
                  />
                </Flex>
                {editingSchedule && (
                  <Button
                    t-16-500-s6
                    m-28-t
                    bc-t2
                    p-16
                    bdr-16
                    height={56}
                    onClick={() =>
                      pushPopup({
                        kind: PopupKind.Drawer,
                        element: ParticalMemo,
                        props: {
                          reservationId,
                          loadSchedule,
                        },
                      })
                    }
                  >
                    부위별 메모
                  </Button>
                )}
              </HFlex>
              {editingSchedule ? (
                <HFlex g-8>
                  {!editingSchedule.attachments.length && (
                    <VFlex
                      c-c
                      width={120}
                      height={120}
                      bdr-16
                      clickable
                      style={{ background: "#F1F6F4" }}
                      onClick={() => {
                        pushPopup({
                          kind: PopupKind.Drawer,
                          element: ParticalMemo,
                          props: {
                            reservationId,
                            loadSchedule,
                          },
                        });
                      }}
                    >
                      <Image
                        size={32}
                        src={`/icons/icon_schedule_camera.png`}
                      />
                    </VFlex>
                  )}

                  {editingSchedule.attachments
                    .slice(0, 3)
                    .map((attachment, index) =>
                      index !== 2 ? (
                        <Image
                          key={attachment.id}
                          bdr-16
                          clickable
                          size={120}
                          src={attachment.path}
                          onClick={() =>
                            pushPopup({
                              kind: PopupKind.Swiper,
                              element: PhotosSwiperPopup,
                              props: {
                                attachments: editingSchedule.attachments,
                                petName: editingSchedule.pet.petInfo.name,
                                date: parseISO(editingSchedule.date),
                              },
                            })
                          }
                        />
                      ) : (
                        <Flex
                          style={{ position: "relative" }}
                          clickable
                          onClick={() =>
                            pushPopup({
                              element: ParticalMemo,
                              kind: PopupKind.Drawer,
                              props: {
                                reservationId,
                                loadSchedule,
                              },
                            })
                          }
                        >
                          <Absolute
                            width={120}
                            height={120}
                            c-c
                            bdr-16
                            style={{ background: "rgba(0,0,0,0.7)" }}
                          >
                            <Text t-14-700-w>+더보기</Text>
                          </Absolute>
                          <Image
                            bdr-16
                            clickable
                            size={120}
                            src={attachment.path}
                          />
                        </Flex>
                      )
                    )}
                </HFlex>
              ) : null}
            </VFlex>
            <Divider height={1} />
            <VFlex a-e p-32>
              <HFlex g-16>
                {isUnhidable ? (
                  <>
                    <VFlex c-c>
                      <Text t-14-400-err1>캘린더에서 숨겨진 예약입니다.</Text>
                    </VFlex>
                    <Button
                      type="warning-lg"
                      caption="캘린더로 되돌리기"
                      onClick={async () => {
                        pushPopup({
                          kind: PopupKind.Popup,
                          align: PopupAlign.TopCenter,
                          element: ConfirmPopup,
                          props: {
                            title: "선택된 예약을 캘린더로 되돌리기할까요?",
                            icon: "/icons/icon_good_warning.png",
                            confirmButtonLabel: "캘린더로 되돌리기",
                            confirmButtonType: "confirm",
                            onConfirm: async () => {
                              setLoading(true);
                              await api.put(
                                `/rest/reservation/${reservationId}/delete?status=false`
                              );
                              popSchedulePopup("예약 캘린더로 되돌렸습니다.");
                              await fetchDeleteds({
                                page,
                              });
                              await fetchCount({
                                date: date!.getTime() / 1000,
                              });
                            },
                          },
                        });
                      }}
                    />
                  </>
                ) : isHidable ? (
                  <Button
                    type="warning-lg"
                    caption="캘린더에서 숨기기"
                    onClick={() => {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title:
                            "이 예약을 캘린더에서 숨기기 할까요?\n숨긴 후에도 숨긴 예약 목록에서 확인 할 수 있습니다",
                          confirmButtonLabel: "캘린더에서 숨기기",
                          confirmButtonType: "confirm",
                          onConfirm: async () => {
                            setLoading(true);
                            popPopup();
                            await api.put(
                              `/rest/reservation/${reservationId}/delete?status=true`
                            );
                            popSchedulePopup("예약을 숨겼습니다.");
                            await fetchCount({
                              date: date!.getTime() / 1000,
                            });
                          },
                        },
                      });
                    }}
                  />
                ) : isCancellable ? (
                  <Button
                    type="warning-lg"
                    caption="예약 취소"
                    onClick={() => cancelSchedule()}
                  />
                ) : null}
                <Button
                  type="confirm-lg"
                  enabled={editingSchedule ? isChanged() : isCompleted()}
                  onClick={async () => {
                    if (!(editingSchedule ? isChanged() : isCompleted())) {
                      return;
                    }
                    if (editingSchedule) {
                      const save = async () => {
                        await api.put(
                          `/rest/reservation/group/${partner?.id}?reservationId=${editingSchedule.id}`,
                          {
                            id: editingSchedule.id,
                            date: format(date!, "yyyy-MM-dd"),
                            startTime: minutesToTimeString(startMinute!),
                            endTime: minutesToTimeString(endMinute!),
                            petId: selectedCustomer!.id ?? 1,
                            // productIdList: selectedProducts.map(
                            //   (product) => product.product.id
                            // ),
                            products: selectedProducts.map((product) => {
                              return {
                                product: {
                                  id: product.product.id,
                                },
                                quantity: product.quantity,
                              };
                            }),
                            userIdList: selectedStaffs?.map(
                              (staff) => staff.id
                            ),
                            guardianId: selectedCustomer!.guardianList[0].id,
                            prepaid: 0,
                          }
                        );
                        popSchedulePopup("예약이 수정되었습니다.");
                        if (fetchCurrentPage) fetchCurrentPage();

                        trackEditReservationSchedule({
                          reservationId: reservationId!,
                          date: format(date!, "yyyy-MM-dd"),
                          startTime: minutesToTimeString(startMinute!),
                          endTime: minutesToTimeString(endMinute!),
                          petId: selectedCustomer!.id,
                          productIdList: selectedProducts.map(
                            (product) => product.product.id
                          ),
                          userIdList: selectedStaffs?.map((staff) => staff.id),
                          guardianId: selectedGuardian
                            ? selectedGuardian!.id
                            : selectedCustomer!.guardianList[0].id,
                          note,
                        });
                      };
                      if (isNoteChanged()) {
                        await api.put(
                          `/rest/reservation/${editingSchedule.id}/note`,
                          {
                            requestNote: note,
                          }
                        );
                      }
                      if (isUserNotifiedFieldsChanged()) {
                        pushPopup({
                          kind: PopupKind.Popup,
                          align: PopupAlign.TopCenter,
                          element: ConfirmPopup,
                          props: {
                            title: `날짜, 서비스, 시간을 변경하면\n고객에게 변경 사항에 대한 메세지가 보내집니다.`,
                            icon: "/icons/icon_good_warning.png",
                            confirmButtonLabel: "예약 변경하기",
                            confirmButtonType: "confirm",
                            onConfirm: async () => {
                              setLoading(true);
                              popPopup();
                              await save();
                              if (fetchCurrentPage) fetchCurrentPage();
                            },
                          },
                        });
                      } else if (isStatusCancelled()) {
                        await cancelSchedule();
                      } else if (isStatusNoShow()) {
                        const startDate = startTime;
                        if (startDate!.getTime() > new Date().getTime()) {
                          pushPopup({
                            kind: PopupKind.Popup,
                            align: PopupAlign.TopCenter,
                            element: ConfirmPopup,
                            props: {
                              title:
                                "노쇼 처리는 예약시간 이후부터 가능합니다.",
                              icon: "/icons/icon_good_warning.png",
                              cancelButtonDisplay: "none",
                              confirmButtonLabel: "확인",
                              confirmButtonType: "confirm",
                              onConfirm: () => {
                                // popPopup();
                              },
                            },
                          });
                        } else {
                          pushPopup({
                            kind: PopupKind.Popup,
                            align: PopupAlign.TopCenter,
                            element: ConfirmPopup,
                            props: {
                              title: `고객이 연락 없이 오지 않았다면, 노쇼 처리가 가능합니다.\n노쇼 처리시, 고객에게 메세지가 전송됩니다.`,
                              icon: "/icons/icon_good_warning.png",
                              confirmButtonLabel: "노쇼 처리하고 메세지 보내기",
                              confirmButtonType: "confirm",
                              onConfirm: async () => {
                                setLoading(true);
                                popPopup();
                                await api.put(
                                  `/rest/reservation/${reservationId}/no-show`
                                );
                                popSchedulePopup("노쇼 처리되었습니다.");
                                if (fetchCurrentPage) fetchCurrentPage();
                              },
                            },
                          });
                        }
                      } else if (isStatusCompleted()) {
                        const startDate = startTime;
                        const endDate = startDate;
                        endDate!.setMinutes(endDate!.getMinutes() + 30);
                        if (endDate!.getTime() > new Date().getTime()) {
                          pushPopup({
                            kind: PopupKind.Popup,
                            align: PopupAlign.TopCenter,
                            element: ConfirmPopup,
                            props: {
                              title:
                                "이용완료 처리는 예약시간 30분 이후부터 가능합니다.",
                              icon: "/icons/icon_good_warning.png",
                              cancelButtonDisplay: "none",
                              confirmButtonLabel: "확인",
                              confirmButtonType: "confirm",
                              onConfirm: () => {
                                // popPopup();
                              },
                            },
                          });
                        } else {
                          pushPopup({
                            kind: PopupKind.Popup,
                            align: PopupAlign.TopCenter,
                            element: ConfirmPopup,
                            props: {
                              title: `이용 완료 처리를 하면\n고객에게 서비스 후 주의사항 메세지가 보내집니다.`,
                              icon: "/icons/icon_good_warning.png",
                              confirmButtonLabel: "이용 완료 변경하기",
                              confirmButtonType: "confirm",
                              onConfirm: async () => {
                                setLoading(true);
                                popPopup();
                                await api.put(
                                  `/rest/reservation/${reservationId}/complete?groupId=${partner?.id}`
                                );
                                popSchedulePopup("이용완료 처리되었습니다.");
                                if (fetchCurrentPage) fetchCurrentPage();
                              },
                            },
                          });
                        }
                      } else if (isStaffChanged()) {
                        if (
                          isStaff?.value === "FALSE" &&
                          selectedStaffs?.length === 0
                        ) {
                          return toast.error("담당자를 선택해주세요.");
                        } else {
                          await api.put(
                            `/rest/reservation/${reservationId}/chargers?${selectedStaffs
                              ?.map((staff) => `chargers=${staff.id}`)
                              .join("&")}`
                          );
                          popSchedulePopup("담당자가 변경되었습니다.");
                          if (fetchCurrentPage) fetchCurrentPage();
                        }
                      } else if (status === ScheduleStatus.CONFIRM) {
                        setLoading(true);
                        await save();
                      } else if (isStatusConfirmVisited()) {
                        pushPopup({
                          kind: PopupKind.Popup,
                          align: PopupAlign.TopCenter,
                          element: ConfirmPopup,
                          props: {
                            title: `방문확정은 보호자의 방문 여부를 알려주는 상태로,\n보호자만 변경할 수 있습니다.`,
                            icon: "/icons/icon_good_warning.png",
                            confirmButtonLabel: "확인",
                            confirmButtonType: "confirm",
                            onConfirm: () => {
                              popPopup();
                            },
                          },
                        });
                      } else {
                        setLoading(true);
                        popSchedulePopup("저장되었습니다.");
                        if (fetchCurrentPage) fetchCurrentPage();
                      }
                    } else {
                      if (
                        isStaff?.value === "FALSE" &&
                        (selectedStaffs?.length === undefined ||
                          selectedStaffs?.length === 0)
                      ) {
                        return toast.error("담당자를 선택해주세요.");
                      } else {
                        setLoading(true);
                        await api.post(
                          `/rest/reservation/group/${partner?.id}`,
                          {
                            date: format(date!, "yyyy-MM-dd"),
                            startTime: minutesToTimeString(startMinute!),
                            endTime: minutesToTimeString(endMinute!),
                            petId: selectedCustomer!.id ?? 1,
                            // productIdList: selectedProducts.map(
                            //   (product) => product.product.id
                            // ),
                            products: selectedProducts.map((product) => {
                              return {
                                product: {
                                  id: product.product.id,
                                },
                                quantity: product.quantity,
                              };
                            }),
                            userIdList: selectedStaffs?.map(
                              (staff) => staff.id
                            ),
                            guardianId: selectedGuardian
                              ? selectedGuardian?.id
                              : selectedCustomer?.guardianList[0].id,
                            note,
                            prepaid: 0,
                          }
                        );
                        popSchedulePopup("예약이 확정되었습니다.");
                        await fetchCount({
                          date: date!.getTime() / 1000,
                        });

                        trackClickSaveReservationSchedule({
                          date: format(date!, "yyyy-MM-dd"),
                          startTime: minutesToTimeString(startMinute!),
                          endTime: minutesToTimeString(endMinute!),
                          petId: selectedCustomer!.id,
                          productIdList: selectedProducts.map(
                            (product) => product.product.id
                          ),
                          userIdList: selectedStaffs?.map((staff) => staff.id),
                          guardianId: selectedGuardian
                            ? selectedGuardian!.id
                            : selectedCustomer!.guardianList[0].id,
                          note,
                        });
                      }
                    }
                  }}
                >
                  <HFlex g-4 c-c>
                    <Image src={"/icons/checkbox_check.png"} size={20} />
                    <Text t-14-700-w>저장</Text>
                  </HFlex>
                </Button>
              </HFlex>
            </VFlex>
          </VFlex>
        </HFlex>
      )}
    </VFlex>
  );
}

export default AddSchedule;
