import React, { MouseEvent, useEffect, useState } from "react";

import api from "../../../common/api";
import { reg } from "../../../common/format";
import useDebounce from "../../../common/useDebounce";

import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { useTags } from "../../../providers/tags";
import { IColor, ITag } from "../../../providers/types";
import { PopupKind } from "../../../popup/Popup";
import TagDetailPopup from "./TagDetailPopup";
import TagSetting from "./TagSetting";

import ToggleV2 from "../../../components/ToggleV2";
import TagSearchInput from "../../../components/TagSearchInput";
import DnDList from "../../../components/DnDList";
import Pagination from "../../../components/Pagination";
import DropdownWithAction from "../../../components/DropdownWithAction";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import Flex from "../../../layouts/Flex";
import Absolute from "../../../layouts/Absolute";
import { Link } from "react-router-dom";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

const ITEMS_PER_PAGE = 10;

function CustomersTag() {
  const { trackClickTagDetail, trackClickTagSetting } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { fetchTags, totalCount } = useTags();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  const debouncedSearchText = useDebounce({ value: searchText, delay: 300 });
  const [tags, setTags] = useState<ITag[]>([]);
  const [tagColors, setTagColors] = useState<IColor[]>([]);

  const [opened, setOpened] = useState(false);

  const fetchTagList = async () => {
    try {
      setLoading(true);
      const data = await fetchTags({ page, search: searchText });
      if (data) {
        setTags(data.data);
        setTagColors(data.colors);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTagList();
  }, [debouncedSearchText, page]);

  useEffect(() => {
    setPage(0);
  }, []);

  const [tagsOrder, setTagsOrder] = useState<
    {
      id: string;
      title: string;
      description: string;
      textColor: string;
      backgroundColor: string;
      userCount: number;
      criterionLabel: string;
      enabled: boolean;
      sortOrder: number;
    }[]
  >([]);

  useEffect(() => {
    setTagsOrder(
      tags?.map((tag) => ({
        id: tag.id.toString(),
        title: tag.title,
        description: tag.description,
        textColor: tag.textColor,
        backgroundColor: tag.backgroundColor,
        userCount: tag.userCount,
        criterionLabel: tag.criterionLabel,
        enabled: tag.enabled,
        sortOrder: tag.sortOrder,
      }))
    );
  }, [debouncedSearchText, tags]);

  const handleChangeSortOrder = async (items: any) => {
    try {
      await api.put(
        `/rest/group/${partner.id}/tags/sort-order?tagIds=${items
          .map((v: any) => v.id)
          .join(",")}`
      );
    } catch (e) {}
  };

  return (
    <VFlex f-1 a-st rel height={"100%"}>
      <HFlex a-c p-32-rl bd-b-t3 sized height={96}>
        <Text t-24-700 f-1>
          고객 태그
        </Text>

        <Image
          src="/icons/icon_help_s6.svg"
          size={24}
          clickable
          onClick={() =>
            window.open(
              "https://www.notion.so/pawdly/PC-e50d5c2d0def4b789abea80798ff4369?pvs=4",
              "_blank"
            )
          }
        />
      </HFlex>

      <HFlex j-b p-24-rl a-c>
        <Text t-16-600>전체 &#40;{totalCount}&#41;</Text>
        <VFlex j-c height={70}>
          <TagSearchInput
            placeholder="고객 태그 검색"
            value={searchText}
            onChangeValue={(value) => {
              setSearchText(reg(value));
            }}
          />
        </VFlex>
      </HFlex>

      <VFlex p-24-rl f-1>
        <HFlex height={46} bd-b-t8>
          {!searchText && (
            <VFlex>
              <VFlex width={40}></VFlex>
            </VFlex>
          )}

          <Text
            width={240}
            p-12-tb
            p-16-rl
            t-14-500-s4
            style={{ boxSizing: "border-box" }}
          >
            태그명
          </Text>
          <Text
            width={360}
            a-c
            p-12-tb
            p-16-rl
            t-14-500-s4
            style={{ boxSizing: "border-box" }}
          >
            태그 조건
          </Text>
          <Text
            f-1
            width={100}
            a-c
            p-12-tb
            p-16-rl
            t-14-500-s4
            style={{ boxSizing: "border-box" }}
          >
            태그 설명
          </Text>
          <Text
            width={100}
            a-c
            p-12-tb
            p-16-rl
            t-14-500-s4
            style={{ boxSizing: "border-box" }}
          >
            일치 고객
          </Text>
          <Text
            width={100}
            a-c
            p-12-tb
            p-16-rl
            t-14-500-s4
            style={{ boxSizing: "border-box" }}
          >
            사용 여부
          </Text>
          <VFlex>
            <VFlex width={40}></VFlex>
          </VFlex>
        </HFlex>

        {tags.length === 0 ? (
          <VFlex
            a-c
            j-c
            bd-t3
            bdr-16
            m-12-t
            g-8
            style={{
              padding: "100px 0",
            }}
          >
            <Image size={24} src={`/icons/icon_noti.svg`} />
            <Text t-16-s2>검색 결과가 없어요</Text>
          </VFlex>
        ) : searchText ? (
          tags.map((item) => {
            return (
              <HFlex
                height={50}
                bd-b-t2
                clickable
                classes={["table-row"]}
                hover
                onClick={() => {
                  trackClickTagDetail("search");
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: TagDetailPopup,
                    props: { item, tagColors, fetchTagList },
                  });
                }}
              >
                <HFlex
                  width={240}
                  a-c
                  g-16
                  p-16-rl
                  style={{ boxSizing: "border-box" }}
                >
                  <Text
                    p-2-tb
                    p-8-rl
                    t-14-500
                    l-1
                    bdr-8
                    style={{
                      background: item.backgroundColor,
                      color: item.textColor,
                    }}
                  >
                    {item.title}
                  </Text>
                </HFlex>
                <HFlex
                  width={360}
                  a-c
                  p-16-rl
                  t-14-500-s8
                  style={{ boxSizing: "border-box" }}
                  dangerouslySetInnerHTML={{
                    __html: item.criterionLabel,
                  }}
                />
                <HFlex
                  f-1
                  width={100}
                  a-c
                  g-6
                  p-16-rl
                  style={{ boxSizing: "border-box" }}
                >
                  {item.description ? (
                    <Text t-14-500-s8 l-1>
                      {item.description}
                    </Text>
                  ) : (
                    <Text t-14-500-s2>작성된 내용이 없습니다.</Text>
                  )}
                </HFlex>
                <HFlex
                  width={100}
                  a-c
                  g-6
                  p-16-rl
                  style={{ boxSizing: "border-box" }}
                >
                  <Text t-14-500-s8 l-1>
                    {item.userCount}
                  </Text>
                </HFlex>
                <HFlex
                  width={100}
                  a-c
                  g-6
                  p-16-rl
                  style={{ boxSizing: "border-box" }}
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                  }}
                >
                  <ToggleV2
                    value={item.enabled}
                    onChange={async (value) => {
                      await api.put(
                        `/rest/group/${partner.id}/customer/tag/${item.id}/enabled?enabled=${value}`
                      );
                      await fetchTagList();
                    }}
                  />
                </HFlex>
                <VFlex
                  width={40}
                  p-12-tb
                  p-8-rl
                  rel
                  onClick={(e: MouseEvent) => {
                    setOpened((o) => !o);
                    e.stopPropagation();
                  }}
                >
                  <Image size={24} src={`/icons/icon_more.png`} />
                  {opened && (
                    <Absolute
                      top={40}
                      left={"auto"}
                      right={8}
                      bottom={"auto"}
                      style={{ zIndex: 2 }}
                    >
                      <DropdownWithAction
                        title={"조건 수정하기"}
                        subTitle={"색상"}
                        handleAction={() => {
                          setOpened((o) => !o);
                          trackClickTagSetting("list");
                          pushPopup({
                            kind: PopupKind.Popup,
                            element: TagSetting,
                            props: { item, tagColors, fetchTagList },
                          });

                          // pushPopup({
                          //   element: ConfirmPopup,
                          //   kind: PopupKind.Popup,
                          //   align: PopupAlign.TopCenter,
                          //   props: {
                          //     icon: "/icons/icon_noti_info.png",
                          //     title: `플러스 플랜으로 업그레이드하고 고객 태그 조건을\n수정하시겠어요?`,
                          //     confirmButtonType: "confirm",
                          //     confirmButtonLabel: "업그레이드",
                          //     onConfirm: () => {
                          //       window.open(
                          //         "https://www.pawdlypartners.com/pricing/"
                          //       );
                          //     },
                          //   },
                          // });
                        }}
                      >
                        {tagColors.map((tag, i) => {
                          return (
                            <DropdownWithAction.ListItem
                              key={i}
                              handleClick={async () => {
                                await api.put(
                                  `/rest/tag/${item.id}/color?color=${tag.label}`
                                );
                                await fetchTagList();

                                // pushPopup({
                                //   element: ConfirmPopup,
                                //   kind: PopupKind.Popup,
                                //   align: PopupAlign.TopCenter,
                                //   props: {
                                //     icon: "/icons/icon_noti_info.png",
                                //     title: `플러스 플랜으로 업그레이드하고 고객 태그 조건을\n수정하시겠어요?`,
                                //     confirmButtonType: "confirm",
                                //     confirmButtonLabel: "업그레이드",
                                //     onConfirm: () => {
                                //       window.open(
                                //         "https://www.pawdlypartners.com/pricing/"
                                //       );
                                //     },
                                //   },
                                // });
                              }}
                            >
                              <Flex
                                p-8-rl
                                p-2-tb
                                bdr-8
                                style={{ backgroundColor: tag.bgColor }}
                              >
                                <Text t-14-500 style={{ color: tag.textColor }}>
                                  {item.title}
                                </Text>
                              </Flex>
                            </DropdownWithAction.ListItem>
                          );
                        })}
                      </DropdownWithAction>
                    </Absolute>
                  )}
                </VFlex>
                {opened && (
                  <Absolute
                    onClick={(e: MouseEvent) => {
                      setOpened(false);
                      e.stopPropagation();
                    }}
                    style={{ zIndex: 1 }}
                  />
                )}
              </HFlex>
            );
          })
        ) : (
          <DnDList
            items={tagsOrder}
            onDragEnd={async (items: any) => {
              setTagsOrder(items);
              await handleChangeSortOrder(items);
            }}
            renderItem={({ item }) => (
              <Item
                item={{
                  id: item.id,
                  title: item.title,
                  description: item.description,
                  textColor: item.textColor,
                  backgroundColor: item.backgroundColor,
                  userCount: item.userCount,
                  criterionLabel: item.criterionLabel,
                  enabled: item.enabled,
                  sortOrder: item.sortOrder,
                }}
                tagColors={tagColors}
                gropId={partner.id}
                fetchTagList={fetchTagList}
              />
            )}
          />
        )}
      </VFlex>

      {totalCount > 10 && (
        <VFlex p-16-tb>
          <Pagination
            page={page}
            pageCount={totalCount / ITEMS_PER_PAGE}
            onPageChange={(n) => setPage(n)}
          />
        </VFlex>
      )}
    </VFlex>
  );
}

export default CustomersTag;

const Item = ({
  gropId,
  item,
  tagColors,
  fetchTagList,
}: {
  gropId: number;
  item: {
    id: string;
    title: string;
    description: string;
    textColor: string;
    backgroundColor: string;
    userCount: number;
    criterionLabel: string;
    enabled: boolean;
    sortOrder: number;
  };
  tagColors: IColor[];
  fetchTagList: () => void;
}) => {
  const { trackClickTagDetail } = useAmplitudeTrack();
  const [opened, setOpened] = useState(false);

  return (
    <HFlex
      height={50}
      bd-b-t2
      clickable
      classes={["table-row"]}
      hover
      onClick={() => {
        trackClickTagDetail();
        pushPopup({
          kind: PopupKind.Popup,
          element: TagDetailPopup,
          props: { item, tagColors, fetchTagList },
        });
      }}
    >
      <VFlex
        width={40}
        p-12-tb
        p-8-rl
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <Image size={24} src={`/icons/drag.svg`} />
      </VFlex>
      <HFlex width={240} a-c g-16 p-16-rl style={{ boxSizing: "border-box" }}>
        <Text
          p-2-tb
          p-8-rl
          t-14-500
          l-1
          bdr-8
          style={{
            background: item.backgroundColor,
            color: item.textColor,
          }}
        >
          {item.title}
        </Text>
      </HFlex>
      <HFlex
        width={360}
        a-c
        p-16-rl
        t-14-500-s8
        style={{ boxSizing: "border-box" }}
        dangerouslySetInnerHTML={{ __html: item.criterionLabel }}
      />
      <HFlex
        f-1
        width={100}
        a-c
        g-6
        p-16-rl
        style={{ boxSizing: "border-box" }}
      >
        {item.description ? (
          <Text t-14-500-s8 l-1>
            {item.description}
          </Text>
        ) : (
          <Text t-14-500-s2>작성된 내용이 없습니다.</Text>
        )}
      </HFlex>
      <HFlex width={100} a-c g-6 p-16-rl style={{ boxSizing: "border-box" }}>
        <Text t-14-500-s8 l-1>
          {item.userCount}
        </Text>
      </HFlex>
      <HFlex
        width={100}
        a-c
        g-6
        p-16-rl
        style={{ boxSizing: "border-box" }}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <ToggleV2
          value={item.enabled}
          onChange={async (value) => {
            await api.put(
              `/rest/group/${gropId}/customer/tag/${item.id}/enabled?enabled=${value}`
            );
            await fetchTagList();
          }}
        />
      </HFlex>
      <VFlex
        width={40}
        p-12-tb
        p-8-rl
        rel
        onClick={(e: MouseEvent) => {
          setOpened((o) => !o);
          e.stopPropagation();
        }}
      >
        <Image size={24} src={`/icons/icon_more.png`} />
        {opened && (
          <Absolute
            top={40}
            left={"auto"}
            right={8}
            bottom={"auto"}
            style={{ zIndex: 2 }}
          >
            <DropdownWithAction
              title={"조건 수정하기"}
              subTitle={"색상"}
              handleAction={() => {
                setOpened((o) => !o);
                pushPopup({
                  kind: PopupKind.Popup,
                  element: TagSetting,
                  props: { item, tagColors, fetchTagList },
                });

                // pushPopup({
                //   element: ConfirmPopup,
                //   kind: PopupKind.Popup,
                //   align: PopupAlign.TopCenter,
                //   props: {
                //     icon: "/icons/icon_noti_info.png",
                //     title: `플러스 플랜으로 업그레이드하고 고객 태그 조건을\n수정하시겠어요?`,
                //     confirmButtonType: "confirm",
                //     confirmButtonLabel: "업그레이드",
                //     onConfirm: () => {
                //       window.open("https://www.pawdlypartners.com/pricing/");
                //     },
                //   },
                // });
              }}
            >
              {tagColors.map((tag, i) => {
                return (
                  <DropdownWithAction.ListItem
                    key={i}
                    handleClick={async () => {
                      await api.put(
                        `/rest/tag/${item.id}/color?color=${tag.label}`
                      );
                      await fetchTagList();

                      // pushPopup({
                      //   element: ConfirmPopup,
                      //   kind: PopupKind.Popup,
                      //   align: PopupAlign.TopCenter,
                      //   props: {
                      //     icon: "/icons/icon_noti_info.png",
                      //     title: `플러스 플랜으로 업그레이드하고 고객 태그 조건을\n수정하시겠어요?`,
                      //     confirmButtonType: "confirm",
                      //     confirmButtonLabel: "업그레이드",
                      //     onConfirm: () => {
                      //       window.open(
                      //         "https://www.pawdlypartners.com/pricing/"
                      //       );
                      //     },
                      //   },
                      // });
                    }}
                  >
                    <Flex
                      p-8-rl
                      p-2-tb
                      bdr-8
                      style={{ backgroundColor: tag.bgColor }}
                    >
                      <Text t-14-500 style={{ color: tag.textColor }}>
                        {item.title}
                      </Text>
                    </Flex>
                  </DropdownWithAction.ListItem>
                );
              })}
            </DropdownWithAction>
          </Absolute>
        )}
      </VFlex>
      {opened && (
        <Absolute
          onClick={(e: MouseEvent) => {
            setOpened(false);
            e.stopPropagation();
          }}
          style={{ zIndex: 1 }}
        />
      )}
    </HFlex>
  );
};
