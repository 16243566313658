import { useEffect, useRef, useState } from "react";

import api from "../../../common/api";

import { ko } from "date-fns/locale";
import { format, isValid } from "date-fns";
import { pushPopup } from "../../../providers/popups";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Flex from "../../../layouts/Flex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import EditParticalMemo from "./EditParticalMemo";
import { PopupKind } from "../../../popup/Popup";
import {
  Memo,
  MemoType,
  MEMO_STATUS_VALUE,
  Photo,
} from "../../../providers/types";
import { getLastestDateTime } from "../../../common/utils";

function ParticalMemo({
  reservationId,
  loadSchedule,
  popPopup,
}: {
  reservationId?: number;
  loadSchedule: () => void;
  popPopup: () => void;
}) {
  const [memos, setMemos] = useState<Memo<keyof typeof MemoType>[]>([]);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [editedDate, setEditedDate] = useState<Date | undefined>();

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isAtTop, setIsAtTop] = useState(true);

  const handleScroll = () => {
    const { scrollTop } = scrollRef.current!;
    setIsAtTop(scrollTop === 0);
  };

  const loadParticalMemo = async () => {
    if (reservationId) {
      const memoResponse = await api.get(
        `/rest/v2/reservation/${reservationId}/note`
      );
      const imgResponse = await api.get(
        `/rest/attachments?objectType=reservation&objectId=${reservationId}`
      );

      const editedDate = new Date(
        getLastestDateTime(
          memoResponse.map((m: { lastModified: number }) => m.lastModified)
        ) * 1000
      );

      setEditedDate(isValid(editedDate) ? editedDate : undefined);

      const memoList = Object.keys(MemoType).map((type, index) => {
        const data = memoResponse.find((r: any) => r.type.value === type);

        return {
          type: data?.type?.value ?? type,
          note: data?.note,
          value: data?.value?.value,
          displayOrder: index,
        };
      });

      setMemos(memoList);
      setPhotos(imgResponse);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current!;
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    loadParticalMemo();
  }, []);

  return (
    <VFlex p-32-b ovf-a style={{ position: "relative" }} ref={scrollRef}>
      <VFlex
        width={600}
        bc-w
        p-24-t
        sdw-6={!isAtTop}
        style={{ position: "fixed" }}
      >
        <HFlex p-24-rl>
          <Flex className="f-1" />
          <Image
            size={24}
            clickable
            src={`/icons/popup_close.png`}
            alt="popup_close"
            onClick={() => {
              popPopup();
            }}
          />
        </HFlex>
        <VFlex p-24-rl p-16-t p-24-b g-4>
          <Text t-20-700-s8>예약 메모</Text>
          {editedDate && (
            <Text t-16 c-s4>
              {`마지막 수정 날짜: 
            ${format(new Date(editedDate), "yyyy년 M월 d일", {
              locale: ko,
            })}`}
            </Text>
          )}
          <Text t-16-500-gr5>우리 업체만 볼 수 있는 메모입니다.</Text>
        </VFlex>
      </VFlex>

      <VFlex f-1 style={{ marginTop: "180px" }}>
        <VFlex p-24-rl m-8-rl p-16-t g-32>
          <VFlex g-24>
            <HFlex j-b>
              <Text t-18-600-s8>기타메모</Text>
              <Image
                src="/icons/icon_edit.png"
                size={24}
                clickable
                onClick={() =>
                  pushPopup({
                    element: EditParticalMemo,
                    kind: PopupKind.Drawer,
                    width: 600,
                    props: {
                      initialIndex: 0,
                      reservationId,
                      loadSchedule,
                      loadParticalMemo,
                    },
                  })
                }
              />
            </HFlex>

            <HFlex a-s>
              <Text width={120} t-16-500-s4 sized>
                메모
              </Text>
              {memos[0]?.note ? (
                <Text t-16-500-s7 l-m>
                  {memos[0].note}
                </Text>
              ) : (
                <Text f-1 t-16-500-s2>
                  입력된 메모가 없습니다
                </Text>
              )}
            </HFlex>

            <HFlex a-s>
              <Text width={120} t-16-500-s4>
                사진
              </Text>

              {photos.length ? (
                <HFlex
                  f-1
                  g-8
                  ovf-a
                  style={{ flexWrap: "nowrap", overflowX: "auto" }}
                >
                  {photos.map((photo) => (
                    <Image
                      src={photo.path}
                      size={56}
                      bdr-24
                      key={photo.id}
                      style={{ flex: "0 0 auto" }}
                    />
                  ))}
                </HFlex>
              ) : (
                <Text f-1 t-16-500-s2>
                  등록된 사진이 없습니다
                </Text>
              )}
            </HFlex>
          </VFlex>

          <VFlex g-24>
            <HFlex j-b>
              <Text t-18-600-s8>컨디션 메모</Text>
              <Image
                src="/icons/icon_edit.png"
                size={24}
                clickable
                onClick={() =>
                  pushPopup({
                    element: EditParticalMemo,
                    kind: PopupKind.Drawer,
                    width: 600,
                    props: {
                      initialIndex: 1,
                      reservationId,
                      loadSchedule,
                      loadParticalMemo,
                    },
                  })
                }
              />
            </HFlex>

            {memos.slice(1, 7).map((memo) => (
              <HFlex a-s key={memo.type}>
                <Text width={120} t-16-500-s4>
                  {MemoType[memo.type]}
                </Text>
                {memo.value ? (
                  memo.value == 2 ? (
                    <VFlex f-1 bc-t1 bdr-8 bd-t2 p-16 g-4>
                      <Text t-16-500-sf>관찰이 필요해요</Text>
                      {memo.note && (
                        <Text t-16-500-s7 l-m>
                          {memo.note}
                        </Text>
                      )}
                    </VFlex>
                  ) : (
                    <Text t-16-500-s7>{MEMO_STATUS_VALUE[memo.value]}</Text>
                  )
                ) : (
                  <Text f-1 t-16-500-s2>
                    미입력
                  </Text>
                )}
              </HFlex>
            ))}
          </VFlex>

          <VFlex g-24>
            <HFlex j-b>
              <Text t-18-600-s8>스타일 메모</Text>
              <Image
                src="/icons/icon_edit.png"
                size={24}
                clickable
                onClick={() =>
                  pushPopup({
                    element: EditParticalMemo,
                    kind: PopupKind.Drawer,
                    width: 600,
                    props: {
                      initialIndex: 2,
                      reservationId,
                      loadSchedule,
                      loadParticalMemo,
                    },
                  })
                }
              />
            </HFlex>

            {memos.slice(7).map((memo) => (
              <HFlex a-s key={memo.type}>
                <Text width={120} t-16-500-s4 sized>
                  {MemoType[memo.type]}
                </Text>
                {memo.note ? (
                  <Text t-16-500-s7 l-m>
                    {memo.note}
                  </Text>
                ) : (
                  <Text f-1 t-16-500-s2>
                    미입력
                  </Text>
                )}
              </HFlex>
            ))}
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default ParticalMemo;
