import { useEffect, useMemo, useState } from "react";

import api from "../../../common/api";

import { pushPopup, usePopups } from "../../../providers/popups";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Flex from "../../../layouts/Flex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import TabBar, { TabItemType } from "../../../components/TabBar";
import Divider from "../../../layouts/Divider";
import TextArea from "../../../layouts/TextArea";
import React from "react";
import TextInput from "../../../layouts/TextInput";
import Button from "../../../layouts/Button";
import GroupRadioButton from "../../../components/GroupRadioButton";
import {
  Memo,
  MemoType,
  MEMO_STATUS_VALUE,
  Photo,
} from "../../../providers/types";

import { isNil } from "lodash";
import { useParticalMemos } from "../../../providers/particalMemos";
import { toast } from "react-hot-toast";
import FilesUpload from "../../../components/FilesUpload";
import { checkIsValidMemo } from "../../../common/utils/memos";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import ConfirmPopup from "../../../popup/ConfirmPopup";

function EditParticalMemo({
  reservationId,
  initialIndex,
  loadSchedule,
  loadParticalMemo,
  popPopup,
}: {
  reservationId?: number;
  loadSchedule: () => void;
  initialIndex?: number;
  loadParticalMemo?: () => void;
  popPopup: () => void;
}) {
  const [editingMemo, setEditingMemo] = useState<Memo<keyof typeof MemoType>[]>(
    []
  );
  const [memos, setMemos] = useState<Memo<keyof typeof MemoType>[]>([]);
  const [photos, setPhotos] = useState<Photo[]>([]);

  const tabs = useMemo<TabItemType[]>(() => {
    return [
      {
        key: "1",
        name: "기타 메모",
        component: EtcMemoTab,
      },
      {
        key: "2",
        name: "컨디션 메모",
        component: ConditionMemoTab,
      },
      {
        key: "3",
        name: "스타일 메모",
        component: StyleMemoTab,
      },
    ];
  }, [photos, memos]);

  const [selectedTab, setSelectedTab] = useState<TabItemType>(
    tabs[initialIndex ?? 0]
  );
  const isChanged = useMemo(() => {
    if (editingMemo) {
      return JSON.stringify(editingMemo) !== JSON.stringify(memos);
    }
  }, [memos, editingMemo]);

  const { fetchSaveParticalMemo, fetchParticalMemo, fetchDeleteParticalMemo } =
    useParticalMemos();

  const loadMemos = async () => {
    if (!reservationId) return;

    const memoList = await fetchParticalMemo({ reservationId });
    return memoList;
  };

  const loadPhotos = async () => {
    if (!reservationId) return;

    const imgResponse = await api.get<Photo[]>(
      `/rest/attachments?objectType=reservation&objectId=${reservationId}`
    );

    return imgResponse;
  };

  const postPhotos = (files: File[]) => {
    files.forEach(async (file) => {
      const formData = new FormData();
      formData.append(`files`, file);

      formData.append("objectId", reservationId + "");
      formData.append("objectType", "reservation");
      await api.post(`/rest/attachments`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPhotos((await loadPhotos())!);
      loadParticalMemo && loadParticalMemo();
    });
  };
  const deletePhoto = async (photo: Photo) => {
    await api.delete(
      `/rest/attachment/${photo.id}?objectType=reservation&objectId=${reservationId}`
    );
    setPhotos((await loadPhotos())!);

    loadParticalMemo && loadParticalMemo();
  };

  useEffect(() => {
    (async () => {
      const photos = await loadPhotos();
      const memos = await loadMemos();

      setPhotos(photos!);
      setMemos(memos!);
      setEditingMemo(memos!);
    })();
  }, []);

  return (
    <VFlex f-1 ovf-a style={{ position: "relative" }}>
      <HFlex p-24-t p-32-rl>
        <Flex f-1 />
        <Image
          size={24}
          clickable
          src={`/icons/popup_close.png`}
          alt="popup_close"
          onClick={async () => {
            if (isChanged) {
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: "변경사항을 저장하지 않고 닫을까요?",
                  onConfirm: () => {
                    popPopup();
                  },
                },
              });
            } else {
              popPopup();
            }
          }}
        />
      </HFlex>
      <HFlex p-32-rl m-16-t j-b a-c>
        <Text t-20-700-s8>예약 메모</Text>
        <Text t-14-600-s12>우리 업체 공유용</Text>
      </HFlex>

      <Flex p-32-rl m-24-t height={42}>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </Flex>
      <Divider height={1} />

      <Flex p-24-t p-32-rl ovf-a style={{ paddingBottom: "120px" }}>
        {selectedTab &&
          React.createElement(selectedTab.component, {
            photos,
            memos,
            setMemos,
            setPhotos,
            postPhotos,
            deletePhoto,
          })}
      </Flex>

      <HFlex
        p-24-tb
        p-32-rl
        g-8
        bd-t
        bc-w
        width={600}
        style={{ position: "fixed", bottom: 0 }}
      >
        <Flex f-1 />
        <Button
          type="cancel"
          onClick={() => {
            if (isChanged) {
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: "변경사항을 저장하지 않고 닫을까요?",
                  onConfirm: () => {
                    popPopup();
                  },
                },
              });
            } else {
              popPopup();
            }
          }}
        >
          취소
        </Button>
        <Button
          type="confirm"
          enabled={isChanged}
          onClick={async () => {
            const newMemos: Memo<keyof typeof MemoType>[] = [];
            const delMemos: Memo<keyof typeof MemoType>[] = [];

            memos.forEach((memo, index) => {
              const isValidMemo = checkIsValidMemo(memo);

              if (isValidMemo) {
                newMemos.push(memo);
              } else {
                if (checkIsValidMemo(editingMemo[index])) delMemos.push(memo);
              }
            });

            if (reservationId) {
              newMemos.length &&
                (await fetchSaveParticalMemo({
                  reservationId,
                  memos: newMemos,
                }));
              delMemos.length &&
                (await fetchDeleteParticalMemo({
                  reservationId,
                  memos: delMemos,
                }));
              toast.success("저장되었습니다");
              await loadSchedule();
              loadParticalMemo && (await loadParticalMemo());
              popPopup();
            }
          }}
        >
          저장하기
        </Button>
      </HFlex>
    </VFlex>
  );
}

function EtcMemoTab({
  photos,
  memos,
  postPhotos,
  deletePhoto,
  setMemos,
}: {
  photos: Photo[];
  memos: Memo<keyof typeof MemoType>[];
  postPhotos: (file: File[]) => void;
  deletePhoto: (photo: Photo) => void;
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) {
  if (!memos.length) return <></>;
  return (
    <VFlex g-16>
      <TextArea
        caption="메모"
        value={memos[0]?.note}
        onChange={(e) => {
          const newMemos = [...memos];
          newMemos[0] = { ...newMemos[0], note: e.target.value };
          setMemos(newMemos);
        }}
      />
      {/* <VFlex g-8>
        <Text t-14-500-s7>사진 등록하기</Text>
        <HFlex g-8 ovf-a>
          <label htmlFor="upload">
            <Image clickable src="/icons/icon_addimgfile.png" size={58} />
            <FilesUpload id="upload" onLoad={postPhotos} multiple />
          </label>

          {photos?.map((photo) => (
            <Flex style={{ position: "relative" }} key={photo.id}>
              <Flex
                c-c
                width={16}
                height={16}
                bc-s8
                bdr-16
                p-2
                clickable
                style={{ position: "absolute", right: 0, top: 0 }}
                onClick={() => deletePhoto(photo)}
              >
                <Image src="/icons/close.png" size={12} />
              </Flex>
              <Image src={photo.path} bdr-24 size={58} />
            </Flex>
          ))}
        </HFlex>
      </VFlex> */}

      {/* TEST TODO */}
      <VFlex g-8>
        <Text t-14-500-s7>사진 등록하기</Text>
        <HFlex g-8 ovf-a>
          <label htmlFor="upload">
            <Image clickable src="/icons/icon_addimgfile.png" size={58} />
            <FilesUpload
              id="upload"
              onLoad={(files: File[]) => {
                postPhotos(files);
                toast.success("사진이 등록되었습니다");
              }}
              multiple
            />
          </label>

          {photos?.map((photo) => (
            <Flex style={{ position: "relative" }} key={photo.id}>
              <Flex
                c-c
                width={16}
                height={16}
                bc-s8
                bdr-16
                p-2
                clickable
                style={{ position: "absolute", right: 0, top: 0 }}
                onClick={() => {
                  deletePhoto(photo);
                  toast.success("사진이 삭제되었습니다");
                }}
              >
                <Image src="/icons/delete_photo.svg" size={12} />
              </Flex>
              <Image src={photo.path} bdr-24 size={58} />
            </Flex>
          ))}
        </HFlex>
      </VFlex>
    </VFlex>
  );
}

function ConditionMemoTab({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) {
  if (!memos.length) return <></>;

  return (
    <VFlex g-16>
      {memos.slice(1, 6).map((i, index) => {
        return (
          <VFlex key={i.type} g-16>
            <GroupRadioButton
              value={
                !isNil(i.value)
                  ? {
                      value: i.value,
                      label: MEMO_STATUS_VALUE[i.value],
                    }
                  : undefined
              }
              caption={MemoType[i.type]}
              options={[
                { label: "좋아요", value: 0 },
                { label: "보통", value: 1 },
                { label: "관찰이 필요해요", value: 2 },
              ]}
              onChange={(e) => {
                if (!isNil(e?.value)) {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: i?.type,
                    value: e?.value,
                    note: i?.note,
                    displayOrder: i.displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                } else {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1].type,
                    value: undefined,
                    note: memos[0]?.note,
                    displayOrder: memos[0].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                }
              }}
            />
            {i.value == 2 && (
              <TextInput
                value={i.note}
                onChange={(e) => {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1]?.type,
                    value: memos[index + 1]?.value,
                    note: e.target.value,
                    displayOrder: memos[index + 1].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                }}
              />
            )}
          </VFlex>
        );
      })}
      <VFlex key={memos[6]?.type} g-16>
        <GroupRadioButton
          value={
            !isNil(memos[6].value)
              ? {
                  value: memos[6]?.value,
                  label: MEMO_STATUS_VALUE[memos[6]?.value],
                }
              : undefined
          }
          caption={MemoType[memos[6].type]}
          options={[
            { label: "없어요", value: 3 },
            { label: "있어요", value: 4 },
          ]}
          onChange={(e) => {
            if (!isNil(e?.value)) {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: e?.value,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            } else {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6].type,
                value: undefined,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }
          }}
        />
        {memos[6].value == 2 && (
          <TextInput
            value={memos[6].note}
            onChange={(e) => {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: memos[6]?.value,
                note: e.target.value,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }}
          />
        )}
      </VFlex>
    </VFlex>
  );
}

function StyleMemoTab({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) {
  if (!memos.length) return <></>;

  return (
    <VFlex g-16>
      {memos.slice(7).map((i, index) => (
        <TextInput
          key={i.type}
          caption={MemoType[i.type]}
          placeholder={"메모하기"}
          value={i.note}
          onChange={(e) => {
            const newMemos = [...memos];

            const newMemo: Memo<keyof typeof MemoType> = {
              type: i?.type,
              value: i?.value,
              note: e.target.value,
              displayOrder: i.displayOrder,
            };
            newMemos[index + 7] = newMemo;

            setMemos(newMemos);
          }}
        />
      ))}
    </VFlex>
  );
}

export default EditParticalMemo;
