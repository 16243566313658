import React, { ReactElement, useState } from "react";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

interface Item extends Object {
  id: string;
}

const reorder = <T extends Item>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DnDList = <T extends Item>({
  items,
  onDragEnd,
  renderItem,
}: {
  items: T[];
  onDragEnd: (items: T[]) => void;
  renderItem: ({ item, index }: { item: T; index: number }) => ReactElement;
}) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reOrderedList = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    onDragEnd(reOrderedList);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided: any) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              listStyleType: "none",
              padding: 0,
              margin: 0,
              height: "100%",
              overflow: "auto",
            }}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided: any) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {renderItem({ item, index })}
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDList;
