import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import api from "../../../common/api";
import { days, staffColors } from "../../../common/constants";
import useDebounce from "../../../common/useDebounce";
import { reg } from "../../../common/format";
import { PopupAnchor, PopupAnchorActions } from "../../../App";

import { usePartner } from "../../../providers/partner";
import {
  CalendarType,
  InquiryListType,
  ReservationStatus,
  Staff,
} from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { useStaffs } from "../../../providers/staffs";
import { useSchedules } from "../../../providers/schedules";
import { useInquiries } from "../../../providers/inquiries";
import { usePanel } from "../../../providers/panel";
import { useDetails } from "../../../providers/details";
import { useCount } from "../../../providers/count";

import UpdatePopup from "./UpdatePopup"; // UpdatePopup
import NoshowUpdatePopup from "./NoshowUpdatePopup";
import AddSchedule from "./AddSchedule";
import ReservationDetail from "./ReservationDetail";
import AddPersonalSchedule from "./AddPersonalSchedule";
import DailyCalendar from "./DailyCalendar";
import WeeklyCalendar from "./WeeklyCalendar";
import MonthlyCalendar from "./MonthlyCalendar";
import ListCalendar, { getDateRangeString } from "./ListCalendar";
import ListCalendarBySearch from "./ListCalendarBySearch";
import StaffCalendar from "./StaffCalendar";
import InquiryList from "./InquiryList";
import InquiryDetail from "./InquiryDetail/index";
import SettingCalendar from "./SettingCalendar";
import { PopupKind } from "../../../popup/Popup";
import PetSearchInput from "../../../components/PetSearchInput";
import StatusDropdown from "../../../components/StatusDropdown";

import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Button from "../../../layouts/Button";
import Image from "../../../layouts/Image";
import Divider from "../../../layouts/Divider";

import _, { isNil } from "lodash";
import ReactCalendar from "react-calendar";
import {
  addDays,
  addMonths,
  format,
  formatDistance,
  startOfDay,
} from "date-fns";
import { ko } from "date-fns/locale";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

import "./ReactCalendar.scss";
import "swiper/css";
import "swiper/css/navigation";
// TODO
import NewReservation from "./NewReservtaion";
import { Autoplay, Navigation } from "swiper/modules";

SwiperCore.use([Navigation, Autoplay]);

const updateNoteData = {
  note: "5월 4주차 업데이트 노트",
  text: "더 강력해진 예약관리와 고객관리",
  link: "https://pawdly.notion.site/2024-05-24-2944427e9e4b4608b8c2407224b6aafb",
};

const getWeekOfTheMonth = (date: Date) => {
  const firstWeekday = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();
  const offsetDate = date.getDate() + firstWeekday - 1;
  return Math.floor(offsetDate / 7);
};

export const AddScheduleContextMenu = ({
  popPopup,
  type,
  initialStaffs,
  startDateTime,
  endDateTime,
}: {
  popPopup: () => void;
  type?: string;
  initialStaffs: Staff[] | undefined;
  startDateTime: Date;
  endDateTime: Date;
}) => {
  const { trackClickAddReservationSchedule, trackClickAddPersonalSchedule } =
    useAmplitudeTrack();

  return (
    <VFlex g-8>
      <HFlex
        a-c
        bc-w
        bdr-16
        sdw-1
        g-16
        height={56}
        p-16-rl
        clickable
        onClick={() => {
          popPopup();
          pushPopup({
            kind: PopupKind.Popup,
            element: NewReservation,
            props: { initialStaffs, startDateTime, endDateTime, type },
            dimClick: "none",
          });
          trackClickAddReservationSchedule();
        }}
      >
        <Image size={24} src={`/icons/icon_service_menu.svg`} />
        <Text t-18-600-s9>예약 일정 추가</Text>
      </HFlex>
      <HFlex
        a-c
        bc-w
        bdr-16
        sdw-1
        g-16
        height={56}
        p-16-rl
        clickable
        onClick={() => {
          popPopup();
          pushPopup({
            kind: PopupKind.Drawer,
            element: AddPersonalSchedule,
            props: { initialStaffs, startDateTime, endDateTime },
          });
          trackClickAddPersonalSchedule();
        }}
      >
        <Image size={24} src={`/icons/icon_manager.svg`} />
        <Text t-18-600-s9>기타 일정 추가</Text>
      </HFlex>
    </VFlex>
  );
};

const Calendar = () => {
  const {
    trackLandMain,
    setIdentifyGropId,
    setIdentifyTitle,
    trackClickNewReservation,
    trackClickInquiryList,
    trackClickInquiryDetail,
    trackClickCalendarDisplay,
    trackClickToday,
    trackClickResSearch,
    trackClickPanel,
    trackClickPanelAppointmentsState,
    trackClickPanelAppointmentsVisible,
    trackClickPanelPersonalScheduleState,
    trackClickPanelPersonalScheduleVisible,
    trackClickPanelStaffsVisible,
    trackClickPanelCalendarSettings,
  } = useAmplitudeTrack();
  useEffect(() => {
    trackLandMain();
    setIdentifyGropId(partner.id);
    setIdentifyTitle(partner.title);
    if (!isNil(state?.newReservation)) {
      pushPopup({
        kind: PopupKind.Popup,
        element: NewReservation,
        dimClick: "none",
      });
    }
    // else if (!isNil(state?.reservationDetail)) {

    //   pushPopup({
    //     key: "addSchedule",
    //     kind: PopupKind.Popup,
    //     element: ReservationDetail,
    //     props: {
    //       reservationId: state?.reservationDetail.id,
    //     },
    //   });
    // }
  }, []);

  const { partner } = usePartner();
  const { staffs } = useStaffs();
  const { inquiriesCount, fetchInquiries } = useInquiries();
  const { details, fetchDetails } = useDetails();
  const {
    fetchSchedules,
    calendarDate,
    setCalendarDate,
    calendarType,
    setCalendarType,
  } = useSchedules();
  const [selectedStaffs, setSelectedStaffs] = useState<Staff[] | undefined>(
    staffs
  );
  const location = useLocation();
  const state = location.state as any;
  const [inquiries, setInquiries] = useState<InquiryListType[]>([]);
  const initInquiries = async () => {
    const list = await fetchInquiries({ page: 0 });
    setInquiries(list);
  };

  useEffect(() => {
    initInquiries();
  }, [inquiriesCount]);
  useEffect(() => {
    setCalendarNowTileHack(true);
  }, [calendarDate]);
  useEffect(() => {
    setSelectedStaffs(
      staffs.filter((s) => {
        return s.filterActived;
      })
    );
  }, [staffs]);

  const [searchText, setSearchText] = useState<string>();
  const debouncedSearchText = useDebounce({ value: searchText, delay: 500 });
  const [calendarNowTileHack, setCalendarNowTileHack] = useState(false);
  const [isIndicatorShown, setIsIndicatorShown] = useState<boolean>(true);
  const addScheduleRef = useRef<PopupAnchorActions>(null);
  const navigate = useNavigate();

  const newCalendarType = useMemo(
    () => (!!debouncedSearchText ? CalendarType.SCHEDULE : calendarType),
    [calendarType, debouncedSearchText]
  );

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const currentMonth = useMemo(() => calendarDate.getMonth(), [calendarDate]);
  const startDate = useMemo(() => {
    const startDate = new Date(calendarDate);
    startDate.setDate(startDate.getDate() - startDate.getDay());
    return startDate;
  }, [calendarDate]);

  const { count, fetchCount } = useCount();
  const [activeStartDate, setActiveStartDate] = useState<number>(
    Math.floor(new Date().getTime() / 1000) // today
  );
  const [scheduleCountVisible, setScheduleCountVisible] = useState(true);

  useEffect(() => {
    (async () =>
      await fetchCount({
        date: activeStartDate,
      }))();
  }, [fetchCount, activeStartDate]);

  const [statusList, setStatusList] = useState<ReservationStatus[]>([]);
  const [personalList, setPersonalList] = useState<ReservationStatus[]>([]);
  const [reservationStateOpen, setReservationStateOpen] = useState(true);
  const [personalStateOpen, setPersonalStateOpen] = useState(true);
  const [staffsOpen, setStaffsOpen] = useState(true);
  const { panelCollapsed, setPanelCollapsed } = usePanel();

  const fetchMeta = async () => {
    const res = await api.get(
      `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB&type=ALL`
    );
    if (res) {
      setStatusList(res.reservationStatus);
      setPersonalList(res.staffSchedule);
    }
  };

  useEffect(() => {
    fetchMeta();
  }, []);

  const handleTodayClick = () => {
    const today = new Date();
    setCalendarDate(today);
    setActiveStartDate(Math.floor(today.getTime() / 1000));
    fetchSchedules(today);
    setIsIndicatorShown(true);
    setScheduleCountVisible(true);
    trackClickToday();
  };

  return (
    <VFlex height={"100%"}>
      <HFlex a-c p-32-rl sized height={96}>
        <Text t-24-700 f-1>
          예약
        </Text>
        <HFlex g-8>
          <HFlex
            g-6
            a-c
            bd-t2
            p-8-tb
            p-16-rl
            bdr-16
            bc-t1
            clickable
            onClick={() => {
              trackClickInquiryList();
              pushPopup({
                kind: PopupKind.Drawer,
                element: InquiryList,
                width: 400,
              });
            }}
          >
            <Image size={18} src={`/icons/notification.png`} />
            <Text t-14-600-s7>문의목록</Text>
            <Text bc-sf t-12-700-w p-4-rl m-2-l bdr-24>
              {inquiriesCount}
            </Text>
          </HFlex>
          <VFlex rel>
            <PopupAnchor
              ref={addScheduleRef}
              popup={{
                kind: PopupKind.Popup,
                element: AddScheduleContextMenu,
                offsetX: 174.34,
                offsetY: -8,
              }}
            >
              <Button
                type="add"
                onClick={() => {
                  trackClickNewReservation();
                }}
              >
                <HFlex g-4 a-c>
                  <Image size={18} src={`/icons/icon_add.png`} />
                  <Text t-14-700-w>새로운 일정 등록</Text>
                </HFlex>
              </Button>
            </PopupAnchor>
          </VFlex>
        </HFlex>
      </HFlex>

      <VFlex
        style={{
          padding: "12px 32px",
          width: "100%",
          background: "#E8F1FF",
        }}
      >
        <HFlex a-c>
          <Image m-16-r size={28} src={`/icons/icon_notice.svg`} />
          <Swiper
            style={{ width: "100%", height: 25 }}
            direction={"vertical"}
            slidesPerView={1}
            // autoplay={{
            //   delay: 5500,
            //   disableOnInteraction: false,
            // }}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onActiveIndexChange={(swiper: any) => {
              setSlideIndex(swiper.activeIndex);
            }}
          >
            {inquiries &&
              inquiries.map((value) => {
                return (
                  <SwiperSlide
                    key={value.id}
                    onClick={() => {
                      trackClickInquiryDetail("banner");

                      pushPopup({
                        key: `inquiryDetail_${value.id}`,
                        kind: PopupKind.Popup,
                        element: InquiryDetail,
                        props: {
                          inquiryId: value.id,
                        },
                      });
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <VFlex t-14-s8 style={{ display: "inline-block" }}>
                      <Text m-12-r t-14-600-s8>
                        &#91;예약문의&#93;
                      </Text>
                      {value.pet.petInfo.name}・
                      {value.products
                        .map((product) => {
                          return product.name;
                        })
                        ?.join(", ") ?? []}
                      ・
                      {format(
                        value.reservationDate * 1000,
                        "yyyy년 M월 d일 (E)",
                        { locale: ko }
                      )}
                      ・{value?.time?.map((t) => t).join(", ")}
                      <span
                        style={{
                          display: "inline-block",
                          margin: "0 12px",
                          width: "1px",
                          height: "10px",
                          background: "#383B3A",
                        }}
                      ></span>
                      <Text style={{ fontSize: 12 }}>
                        {formatDistance(
                          new Date(value.inquiredDateTime * 1000),
                          new Date(),
                          {
                            addSuffix: true,
                            locale: ko,
                          }
                        )}
                        &nbsp;문의
                      </Text>
                    </VFlex>
                  </SwiperSlide>
                );
              })}
            <HFlex
              a-c
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
                background: "#E8F1FF",
              }}
            >
              <HFlex m-8-r m-12-l>
                <Text t-12-s8>{slideIndex + 1}</Text>
                <Text t-12-s8>/{inquiries.length + 1}</Text>
              </HFlex>
              <HFlex>
                <VFlex clickable ref={navigationPrevRef}>
                  <Image size={24} src={`/icons/icon_swiper_prev.svg`} />
                </VFlex>
                <VFlex clickable ref={navigationNextRef}>
                  <Image size={24} src={`/icons/icon_swiper_next.svg`} />
                </VFlex>
              </HFlex>
            </HFlex>

            <SwiperSlide
              onClick={() => {
                window.open(`${updateNoteData.link}`, `_blank`);
              }}
            >
              <VFlex clickable t-14-s8 style={{ display: "inline-block" }}>
                <Text m-12-r t-14-600-s8>
                  &#91;{updateNoteData.note}&#93;
                </Text>
                {updateNoteData.text}
              </VFlex>
            </SwiperSlide>
          </Swiper>
        </HFlex>
      </VFlex>

      <HFlex f-1 rel ovf-h>
        {/* 왼쪽 영역 */}
        <VFlex
          anim-1
          style={{
            width: panelCollapsed ? 258 : 0,
            opacity: !!searchText ? 0.5 : 1,
            zIndex: 2,
          }}
        >
          <HFlex
            g-8
            sized
            ovf-h
            classes={[calendarNowTileHack ? "" : "calendar-hack"]}
            rel
          >
            {isIndicatorShown &&
              (newCalendarType === CalendarType.WEEKLY ||
                newCalendarType === CalendarType.SCHEDULE) && (
                <HFlex
                  width={226}
                  height={24}
                  bdr-8
                  abs
                  bc-t2
                  style={{
                    left: 16,
                    // 100 = navigation height + month-view__weekdays height
                    // 46 = month-view__days height
                    top: `${100 + getWeekOfTheMonth(calendarDate) * 46}px`,
                  }}
                />
              )}
            <ReactCalendar
              key={calendarDate.getTime()}
              onChange={(date: Date) => {
                setCalendarDate(date);
                fetchSchedules(date);
                setIsIndicatorShown(true);
              }}
              onDrillDown={(props) => {
                setIsIndicatorShown(
                  props.activeStartDate.getFullYear() ===
                    calendarDate.getFullYear() &&
                    props.activeStartDate.getMonth() === calendarDate.getMonth()
                );
                if (props.view === "month") {
                  setScheduleCountVisible(true);
                }
              }}
              onViewChange={(props) => {
                setIsIndicatorShown(props.view === "month");
                setScheduleCountVisible(false);
              }}
              onActiveStartDateChange={(props) => {
                setActiveStartDate(
                  Math.floor(props.activeStartDate.getTime() / 1000)
                );
              }}
              value={calendarDate}
              calendarType={"Hebrew"}
              formatMonthYear={(_, date: Date) =>
                `${date.getFullYear()}년 ${date.getMonth() + 1}월`
              }
              tileClassName={"calendar-tile-disable"}
              tileContent={(props) => {
                switch (props.view) {
                  case "month":
                    return (
                      <VFlex
                        classes={[
                          "calendar-tile",
                          startOfDay(calendarDate).getTime() ===
                          startOfDay(props.date).getTime()
                            ? "calendar-tile-selected"
                            : "",
                        ]}
                      >
                        {props.date.getDate()}
                      </VFlex>
                    );
                  case "year":
                    return (
                      <VFlex classes={["calendar-tile"]}>
                        {props.date.getMonth() + 1}월
                      </VFlex>
                    );
                }
                return null;
              }}
              formatShortWeekday={(_, date: Date) => days[date.getDay()]}
              prevLabel={
                <Image size={24} src={`/icons/icon_calendar_prev.svg`} />
              }
              prev2Label={null}
              nextLabel={
                <Image size={24} src={`/icons/icon_calendar_next.svg`} />
              }
              next2Label={null}
            />

            {scheduleCountVisible && (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 110,
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0 16px",
                }}
              >
                {count?.map((value) => {
                  return (
                    <VFlex
                      key={value.date}
                      width={32.28}
                      height={46}
                      a-c
                      j-c
                      t-10-s2
                    >
                      {value.reservationCount === 0
                        ? null
                        : value.reservationCount}
                    </VFlex>
                  );
                })}
              </div>
            )}
          </HFlex>

          <VFlex f-1 j-b p-16-t ovf-s>
            <VFlex p-16-rl p-36-b g-20>
              <VFlex g-12>
                <HFlex j-b>
                  <VFlex t-14-600-s8>예약 일정</VFlex>
                  <HFlex g-4 a-c>
                    <StatusDropdown
                      value={statusList
                        .filter((i: ReservationStatus) => i.showed)
                        ?.map((status) => status.value)}
                      options={statusList?.map((status) => ({
                        value: status.value,
                        obj: status,
                        label: status.title,
                      }))}
                      onChange={async (values) => {
                        await api.post(
                          `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB`,
                          {
                            meta: [
                              {
                                type: "CAL_F_RESV_STATUS",
                                value: values
                                  .map((value) => {
                                    return value.obj;
                                  })
                                  .map((s) => {
                                    return s.value;
                                  })
                                  .join(","),
                                valueType: "LIST",
                              },
                            ],
                          }
                        );
                        await fetchMeta();
                      }}
                    />
                    <Image
                      clickable
                      size={20}
                      src={`${
                        reservationStateOpen
                          ? "/icons/icon_arrow_up.svg"
                          : "/icons/icon_arrow_down.svg"
                      }`}
                      onClick={() => {
                        setReservationStateOpen((prev) => !prev);
                        trackClickPanelAppointmentsVisible(
                          !reservationStateOpen
                        );
                      }}
                    />
                  </HFlex>
                </HFlex>
                <VFlex ovf-h height={reservationStateOpen ? "auto" : "0px"}>
                  <HFlex
                    g-6
                    clickable
                    style={{
                      flexWrap: "wrap",
                    }}
                  >
                    {statusList?.map((status) => {
                      return (
                        status.showed && (
                          <VFlex
                            key={status.value}
                            onClick={async () => {
                              const filterStatus = statusList.filter(
                                (s) => s.actived
                              );
                              let updateData = [];

                              if (
                                filterStatus.findIndex(
                                  (v) => v.value === status.value
                                ) >= 0
                              ) {
                                // 비활성화
                                updateData = filterStatus
                                  .filter((v) => v.value !== status.value)
                                  .map((i) => i.value);
                              } else {
                                // 활성화
                                updateData = [
                                  ...filterStatus.map((i) => i.value),
                                  status.value,
                                ];
                              }

                              await api.post(
                                `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB`,
                                {
                                  meta: [
                                    {
                                      type: "CAL_F_RESV_A_STATUS",
                                      value: updateData.join(","),
                                      valueType: "LIST",
                                    },
                                  ],
                                }
                              );
                              await fetchMeta();
                              trackClickPanelAppointmentsState(
                                status.title,
                                !status.actived
                              );
                            }}
                          >
                            <HFlex
                              g-2
                              a-c
                              p-2-tb
                              p-6-rl
                              bc-t2={status.actived}
                              bc-t1={!status.actived}
                              bdr-4
                            >
                              <Text
                                t-14-s7={status.actived}
                                t-14-t8={!status.actived}
                              >
                                {status.title}
                              </Text>
                              <Image
                                size={16}
                                src={`/icons/icon_${
                                  status.actived ? "minus" : "plus"
                                }.svg`}
                                style={{ opacity: status.actived ? 1 : 0.3 }}
                              />
                            </HFlex>
                          </VFlex>
                        )
                      );
                    })}
                  </HFlex>
                </VFlex>
              </VFlex>

              <VFlex g-12>
                <HFlex j-b>
                  <VFlex t-14-600-s8>기타 일정</VFlex>
                  <HFlex g-4>
                    {/* <Image clickable size={20} src={`/icons/icon_plus.svg`} /> */}
                    <Image
                      clickable
                      size={20}
                      src={`${
                        personalStateOpen
                          ? "/icons/icon_arrow_up.svg"
                          : "/icons/icon_arrow_down.svg"
                      }`}
                      onClick={() => {
                        setPersonalStateOpen((prev) => !prev);
                        trackClickPanelPersonalScheduleVisible(
                          !personalStateOpen
                        );
                      }}
                    />
                  </HFlex>
                </HFlex>
                <VFlex ovf-h height={personalStateOpen ? "auto" : "0px"}>
                  <HFlex
                    g-6
                    style={{
                      flexWrap: "wrap",
                    }}
                  >
                    {personalList.map((per) => {
                      return (
                        <VFlex
                          key={per.value}
                          p-2-tb
                          p-6-rl
                          bc-t2={per.actived}
                          bc-t1={!per.actived}
                          bdr-4
                        >
                          <HFlex
                            g-2
                            a-c
                            clickable
                            onClick={async () => {
                              await api.post(
                                `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB`,
                                {
                                  meta: [
                                    {
                                      type: "CAL_F_STAFF_SCHEDULE_A_STATUS",
                                      value: per.actived ? "" : "0",
                                      valueType: "LIST",
                                    },
                                  ],
                                }
                              );
                              await fetchMeta();
                              trackClickPanelPersonalScheduleState(
                                per.title,
                                !per.actived
                              );
                            }}
                          >
                            <Text t-14-s7={per.actived} t-14-t8={!per.actived}>
                              {per.title}
                            </Text>
                            <Image
                              size={16}
                              src={`/icons/icon_${
                                per.actived ? "minus" : "plus"
                              }.svg`}
                              style={{ opacity: per.actived ? 1 : 0.3 }}
                            />
                          </HFlex>
                        </VFlex>
                      );
                    })}
                  </HFlex>
                </VFlex>
              </VFlex>

              <VFlex g-12>
                <HFlex j-b>
                  <HFlex
                    a-c
                    clickable
                    onClick={async () => {
                      if (selectedStaffs?.length === 0) {
                        setSelectedStaffs(staffs);

                        await api.post(
                          `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB`,
                          {
                            meta: [
                              {
                                type: "CAL_F_STAFF_A_STATUS",
                                value: staffs?.map((s) => s.id).join(","),
                                valueType: "LIST",
                              },
                            ],
                          }
                        );
                      } else {
                        setSelectedStaffs([]);

                        await api.post(
                          `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB`,
                          {
                            meta: [
                              {
                                type: "CAL_F_STAFF_A_STATUS",
                                value: "",
                                valueType: "LIST",
                              },
                            ],
                          }
                        );
                      }
                      await fetchMeta();
                    }}
                  >
                    <Image
                      src={
                        selectedStaffs &&
                        selectedStaffs?.length > 0 &&
                        selectedStaffs?.length >= staffs.length
                          ? "/icons/checkbox_on.svg"
                          : "/icons/checkbox_off.svg"
                      }
                      size={18}
                    />
                    <Text m-12-l t-14-600-s8>
                      담당자
                    </Text>
                    <Text m-4-l t-14-600-s3>
                      {selectedStaffs?.length}
                    </Text>
                  </HFlex>
                  <HFlex g-4>
                    {/* <Image clickable size={20} src={`/icons/icon_plus.svg`} /> */}
                    <Image
                      clickable
                      size={20}
                      src={`${
                        staffsOpen
                          ? "/icons/icon_arrow_up.svg"
                          : "/icons/icon_arrow_down.svg"
                      }`}
                      onClick={() => {
                        setStaffsOpen((prev) => !prev);
                        trackClickPanelStaffsVisible(!staffsOpen);
                      }}
                    />
                  </HFlex>
                </HFlex>
                <VFlex ovf-h height={staffsOpen ? "auto" : "0px"}>
                  <VFlex g-8>
                    {staffs.map((staff) => {
                      return (
                        <HFlex
                          clickable
                          key={staff.id}
                          g-12
                          t-14-s7
                          onClick={async () => {
                            let update = [];

                            if (
                              selectedStaffs &&
                              selectedStaffs?.findIndex(
                                (s) => s.id === staff.id
                              ) >= 0
                            ) {
                              update =
                                selectedStaffs
                                  ?.filter((s) => s.id !== staff.id)
                                  .map((s) => s.id) ?? [];
                              setSelectedStaffs((v) =>
                                v?.filter((vv) => vv.id !== staff.id)
                              );
                            } else {
                              update = selectedStaffs
                                ? [...selectedStaffs.map((s) => s.id), staff.id]
                                : [staff.id];
                              setSelectedStaffs((v) =>
                                v ? _.uniq([...v, staff]) : [staff]
                              );
                            }

                            await api.post(
                              `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB`,
                              {
                                meta: [
                                  {
                                    type: "CAL_F_STAFF_A_STATUS",
                                    value: update.join(","),
                                    valueType: "LIST",
                                  },
                                ],
                              }
                            );
                            await fetchMeta();
                          }}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {selectedStaffs
                              ?.map((staff) => staff.id)
                              .includes(staff.id) ? (
                              <>
                                <rect
                                  width="18"
                                  height="18"
                                  rx="4"
                                  fill={staffColors[staff.color]?.mainColor}
                                />
                                <path
                                  d="M7 13.4L3 9.4L4.4 8L7 10.6L13.6 4L15 5.4L7 13.4Z"
                                  fill="white"
                                />
                              </>
                            ) : (
                              <rect
                                x="0.5"
                                y="0.5"
                                width="17"
                                height="17"
                                rx="3.5"
                                fill="white"
                                stroke={staffColors[staff.color]?.mainColor}
                              />
                            )}
                          </svg>
                          {staff.name} {staff.jobTitle}
                        </HFlex>
                      );
                    })}
                  </VFlex>
                </VFlex>
              </VFlex>
            </VFlex>
          </VFlex>

          <VFlex p-16-rl bd-t-t3={panelCollapsed} j-c height={56}>
            <HFlex j-b ovf-h>
              <HFlex g-16>
                {/* TODO : 라우팅 후 */}
                {/* <Image
                  clickable
                  size={24}
                  src={`/icons/icon_trash.png`}
                  onClick={() => {
                    navigate("");
                  }}
                /> */}
                <Image
                  clickable
                  size={24}
                  src={`/icons/icon_settings.png`}
                  onClick={() => {
                    trackClickPanelCalendarSettings();

                    pushPopup({
                      kind: PopupKind.Popup,
                      element: SettingCalendar,
                      props: {
                        groupId: partner.id,
                        details,
                        fetchDetails,
                      },
                    });
                  }}
                />
              </HFlex>
              <Image
                clickable
                size={!panelCollapsed ? 36 : 24}
                src={`/icons/icon_collapse.png`}
                style={{
                  position: !panelCollapsed ? "absolute" : "static",
                  left: !panelCollapsed ? 16 : 0,
                  bottom: !panelCollapsed ? 24 : 0,
                  padding: !panelCollapsed ? 8 : 0,
                  background: !panelCollapsed ? " #fff" : "none",
                  border: !panelCollapsed ? "1px solid #E4E8E6" : "none",
                  borderRadius: !panelCollapsed ? "100%" : "none",
                  boxShadow: !panelCollapsed
                    ? "0px 2px 6px 0px rgba(0, 0, 0, 0.20)"
                    : "none",
                  transform: !panelCollapsed ? "scaleX(-1)" : "scaleX(1)",
                }}
                onClick={() => {
                  setPanelCollapsed(!panelCollapsed);
                  trackClickPanel(!panelCollapsed ? "열기" : "접기");
                }}
              />
            </HFlex>
          </VFlex>
        </VFlex>

        {panelCollapsed && <Divider width={1} />}

        {/* 오른쪽 영역 */}
        <VFlex rel p-16-t p-32-rl width={"100%"}>
          <HFlex a-c j-b>
            <HFlex g-8>
              <HFlex p-4 bc-t1 bdr-8 sized ovf-h height={40}>
                {Object.values(CalendarType).map((type, i) => (
                  <HFlex key={i}>
                    <HFlex
                      p-16-rl
                      c-c
                      bdr-6
                      bc-w={newCalendarType === type}
                      onClick={() => {
                        setCalendarType(type);
                        fetchSchedules(calendarDate);
                        trackClickCalendarDisplay(type);
                      }}
                      clickable
                      style={{
                        boxShadow:
                          newCalendarType === type
                            ? "0px 2px 6px 0px rgba(0, 0, 0, 0.20)"
                            : "none",
                      }}
                    >
                      <Text
                        t-14-600-s8={newCalendarType === type}
                        t-14-s2={newCalendarType !== type}
                      >
                        {type}
                      </Text>
                    </HFlex>
                  </HFlex>
                ))}
              </HFlex>
              <VFlex
                j-c
                bdr-8
                p-16-rl
                bd-t2
                clickable
                height={40}
                t-14-s6
                onClick={() => {
                  handleTodayClick();
                }}
              >
                오늘
              </VFlex>
            </HFlex>

            {newCalendarType === CalendarType.SCHEDULE && searchText ? null : (
              <HFlex height={40} sized c-c>
                <Image
                  size={24}
                  src={`/icons/icon_calendar_prev.svg`}
                  clickable
                  onClick={async () => {
                    if (
                      // 일, 담당자
                      newCalendarType === CalendarType.DAILY ||
                      newCalendarType === CalendarType.STAFF
                    ) {
                      const prevDay = addDays(calendarDate, -1);
                      setCalendarDate(prevDay);
                      fetchSchedules(prevDay);
                      let startOfDay = new Date(
                        prevDay.getFullYear(),
                        prevDay.getMonth(),
                        prevDay.getDate(),
                        0,
                        0,
                        0,
                        0
                      );
                      await fetchCount({
                        date: startOfDay.getTime() / 1000,
                      });
                    } else if (
                      // 주, 스케줄
                      newCalendarType === CalendarType.WEEKLY ||
                      newCalendarType === CalendarType.SCHEDULE
                    ) {
                      const prevWeek = addDays(calendarDate, -7);
                      setCalendarDate(prevWeek);
                      fetchSchedules(prevWeek);
                      let startOfWeek = new Date(
                        prevWeek.getFullYear(),
                        prevWeek.getMonth(),
                        prevWeek.getDate(),
                        0,
                        0,
                        0,
                        0
                      );
                      await fetchCount({
                        date: startOfWeek.getTime() / 1000,
                      });
                    } else {
                      // 월
                      const prevMonth = addMonths(calendarDate, -1);
                      setCalendarDate(prevMonth);
                      fetchSchedules(prevMonth);
                      let firstDayOfMonth = new Date(
                        prevMonth.getFullYear(),
                        prevMonth.getMonth(),
                        1,
                        0,
                        0,
                        0,
                        0
                      );
                      await fetchCount({
                        date: firstDayOfMonth.getTime() / 1000,
                      });
                    }
                  }}
                />
                <Text m-12-rl t-18-600-s8>
                  {/* 일, 담당자 */}
                  {(newCalendarType === CalendarType.DAILY ||
                    newCalendarType === CalendarType.STAFF) &&
                    format(calendarDate, "M월 d일 EEEE", { locale: ko })}

                  {/* 주, 스케줄 */}
                  {(newCalendarType === CalendarType.WEEKLY ||
                    newCalendarType === CalendarType.SCHEDULE) &&
                    getDateRangeString(startDate, currentMonth)}

                  {/* 월 */}
                  {newCalendarType === CalendarType.MONTHLY &&
                    format(calendarDate, "yyyy년 M월")}
                </Text>
                <Image
                  size={24}
                  src={`/icons/icon_calendar_next.svg`}
                  clickable
                  onClick={async () => {
                    if (
                      // 일, 담당자
                      newCalendarType === CalendarType.DAILY ||
                      newCalendarType === CalendarType.STAFF
                    ) {
                      const nextDay = addDays(calendarDate, 1);
                      setCalendarDate(nextDay);
                      fetchSchedules(nextDay);
                      let startOfDay = new Date(
                        nextDay.getFullYear(),
                        nextDay.getMonth(),
                        nextDay.getDate(),
                        0,
                        0,
                        0,
                        0
                      );
                      await fetchCount({
                        date: startOfDay.getTime() / 1000,
                      });
                    } else if (
                      // 주, 스케줄
                      newCalendarType === CalendarType.WEEKLY ||
                      newCalendarType === CalendarType.SCHEDULE
                    ) {
                      const nextWeek = addDays(calendarDate, 7);
                      setCalendarDate(nextWeek);
                      fetchSchedules(nextWeek);
                      let startOfWeek = new Date(
                        nextWeek.getFullYear(),
                        nextWeek.getMonth(),
                        nextWeek.getDate(),
                        0,
                        0,
                        0,
                        0
                      );
                      await fetchCount({
                        date: startOfWeek.getTime() / 1000,
                      });
                    } else {
                      // 월
                      const nextMonth = addMonths(calendarDate, 1);
                      setCalendarDate(nextMonth);
                      fetchSchedules(nextMonth);
                      let firstDayOfMonth = new Date(
                        nextMonth.getFullYear(),
                        nextMonth.getMonth(),
                        1,
                        0,
                        0,
                        0,
                        0
                      );
                      await fetchCount({
                        date: firstDayOfMonth.getTime() / 1000,
                      });
                    }
                  }}
                />
              </HFlex>
            )}

            <PetSearchInput
              placeholder="검색"
              value={searchText}
              onChangeValue={(value) => {
                setSearchText(reg(value));
              }}
              onClick={() => {
                trackClickResSearch();
              }}
            />
          </HFlex>

          <HFlex f-1 p-16-t bdr-8 sized ovf-h>
            {newCalendarType === CalendarType.DAILY && (
              <DailyCalendar
                selectedStaffs={selectedStaffs ?? staffs}
                searchText={searchText}
                addScheduleRef={addScheduleRef}
                personalList={personalList}
                statusList={statusList}
              />
            )}
            {newCalendarType === CalendarType.WEEKLY && (
              <WeeklyCalendar
                selectedStaffs={selectedStaffs ?? staffs}
                searchText={searchText}
                addScheduleRef={addScheduleRef}
                personalList={personalList}
                statusList={statusList}
              />
            )}
            {newCalendarType === CalendarType.MONTHLY && (
              <MonthlyCalendar
                selectedStaffs={selectedStaffs ?? staffs}
                searchText={searchText}
                personalList={personalList}
                statusList={statusList}
              />
            )}
            {newCalendarType === CalendarType.SCHEDULE &&
              (searchText ? (
                <ListCalendarBySearch
                  selectedStaffs={selectedStaffs ?? staffs}
                  searchText={searchText}
                  statusList={statusList}
                />
              ) : (
                <ListCalendar
                  selectedStaffs={selectedStaffs ?? staffs}
                  personalList={personalList}
                  statusList={statusList}
                />
              ))}
            {newCalendarType === CalendarType.STAFF && (
              <StaffCalendar
                selectedStaffs={selectedStaffs ?? staffs}
                searchText={searchText}
                addScheduleRef={addScheduleRef}
                personalList={personalList}
                statusList={statusList}
              />
            )}
          </HFlex>
        </VFlex>
      </HFlex>
    </VFlex>
  );
};

export default Calendar;
