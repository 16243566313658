import { isNil } from "lodash";
import { Memo, MemoType } from "../../providers/types";

export const checkIsValidMemo = (memo: Memo<keyof typeof MemoType>) => {
  return Object.keys(MemoType)
    .slice(1, 7)
    .find((m) => m === memo.type)
    ? !isNil(memo.value) && !isNaN(memo.value)
    : !!memo.note;
};
