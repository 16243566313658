import { useEffect, useMemo, useRef } from "react";

import { days, staffColors } from "../../../common/constants";
import Spinner from "../../../common/Spinner/Spinner";

import AddSchedule from "./AddSchedule";
import AddPersonalSchedule from "./AddPersonalSchedule";
import {
  checkIsBusinessHoliday,
  checkIsTemporaryHoliday,
} from "./DailyCalendar";
import MoreMini from "./MoreMini";
import { PopupKind } from "../../../popup/Popup";

import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";

import { ReservationStatus, Schedule, Staff } from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { useSchedules } from "../../../providers/schedules";
import { useBusinessHours } from "../../../providers/businessHours";

import _ from "lodash";
import { addDays, format, startOfDay, startOfMonth } from "date-fns";
import { ko } from "date-fns/locale";
import hangul from "hangul-js";
import ReservationDetail from "./ReservationDetail";

const MoreScheduleBlock = ({
  n,
  schedules,
  personalList,
  statusList,
}: {
  n: number;
  schedules: Schedule[];
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <VFlex
      ref={divRef}
      p-4-rl
      p-2-tb
      bdr-4
      clickable
      onClick={() => {
        const positionX = divRef.current?.getBoundingClientRect().x;
        const positionY = divRef.current?.getBoundingClientRect().y;

        pushPopup({
          key: "moreSchedule",
          kind: PopupKind.Mini,
          element: MoreMini,
          props: {
            schedules: schedules,
            personalList: personalList,
            statusList: statusList,
          },
          x:
            positionX && positionX < 320
              ? positionX + divRef.current?.getBoundingClientRect().width + 15
              : positionX && positionX - 315,
          y: positionY && positionY - 100,
        });
      }}
    >
      <Text t-12-600-s8 l-1>
        {n}개 더보기
      </Text>
    </VFlex>
  );
};

const HolidayBlock = ({ text }: { text: string }) => {
  return (
    <VFlex
      j-c
      p-4-rl
      bdr-4
      g-4
      height={18}
      clickable
      style={{
        background: "#CAD1CE",
      }}
    >
      <HFlex g-4 a-c>
        <Image size={14} src={`/icons/icon_holiday.png`} />
        <Text t-12-600-w>{text}</Text>
      </HFlex>
    </VFlex>
  );
};

const ScheduleBlock = ({
  schedule,
  personalList,
  statusList,
}: {
  schedule: Schedule;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) => {
  const isStatusShow = statusList
    ?.filter((i: ReservationStatus) => i.showed)
    ?.map((status) => status.value)
    .map(Number);

  const isStatusActive = statusList
    ?.filter((i: ReservationStatus) => i.actived)
    ?.map((status) => status.value)
    .map(Number);

  const isPersonalActive = personalList && personalList[0]?.actived;

  const startTime = useMemo(
    () =>
      new Date(
        Math.min(
          schedule.startDateTime.getTime(),
          schedule.endDateTime.getTime()
        )
      ),
    [schedule]
  );

  const isPersonalSchedule = useMemo(() => {
    return schedule.type === "PARTNER_SCHEDULE";
  }, [schedule]);

  const isInquiriesSchedule = useMemo(() => {
    return (
      schedule.incomingPlatform === "PUA" || schedule.incomingPlatform === "PUW"
    );
  }, [schedule]);

  return (
    <VFlex
      j-c
      bdr-4
      g-4
      height={18}
      clickable
      style={{
        display:
          (isPersonalSchedule && isPersonalActive) ||
          (isStatusShow?.includes(schedule.status) &&
            isStatusActive?.includes(schedule.status))
            ? "inline-flex"
            : "none",
        background: isPersonalSchedule
          ? schedule.staffs.length <= 1
            ? `repeating-linear-gradient(-45deg, ${schedule.backgroundColor}, ${schedule.backgroundColor} 2px, transparent 0, transparent 5px)`
            : `repeating-linear-gradient(-45deg, #fff, #fff 2px, transparent 0, transparent 5px), linear-gradient(to left, ${
                staffColors[schedule.staffs[schedule.staffs.length - 1]?.color]
                  ?.bgColor
              }, ${staffColors[schedule.staffs[0]?.color]?.bgColor})`
          : schedule.backgroundColor,
        textDecoration: [5, 6, 7].includes(schedule.status)
          ? "line-through"
          : "none",
        opacity: [4, 6, 7].includes(schedule.status) ? 0.5 : 1,
        border: [6, 7].includes(schedule.status)
          ? schedule.staffs.length === 0
            ? `2px solid #979E9B`
            : schedule.staffs.length === 1
            ? `2px solid ${staffColors[schedule.staffs[0]?.color]?.bgColor}`
            : `2px solid transparent`
          : "none",
        borderRadius: "4px",
        backgroundImage:
          [6, 7].includes(schedule.status) && schedule.staffs.length > 1
            ? `linear-gradient(#fff, #fff), linear-gradient(to left, ${
                staffColors[schedule.staffs[schedule.staffs.length - 1]?.color]
                  ?.bgColor
              }, ${staffColors[schedule.staffs[0]?.color]?.bgColor})`
            : "#fff",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
      }}
      onClick={() => {
        isPersonalSchedule
          ? pushPopup({
              key: "addPersonalSchedule",
              kind: PopupKind.Drawer,
              element: AddPersonalSchedule,
              props: {
                personalSchedule: schedule,
              },
            })
          : pushPopup({
              key: "addSchedule",
              kind: PopupKind.Popup,
              element: ReservationDetail,
              props: {
                reservationId: schedule.reservationId,
                petId: schedule.petId,
                schedule: schedule,
              },
              dimClick: "none",
            });
      }}
    >
      {isPersonalSchedule ? (
        <HFlex g-4 a-c>
          <VFlex
            style={{
              marginLeft: 4,
              width: 10,
              height: 10,
              background: schedule.mainColor,
              borderRadius: "100%",
            }}
          ></VFlex>
          <Text f-1 t-12-600-s8 l-1>
            {schedule.title}
          </Text>
        </HFlex>
      ) : (
        <HFlex g-4 j-b p-4-l>
          <HFlex g-4>
            <Text t-12-700-s8>
              {format(startTime, "a h:mm", { locale: ko })}
            </Text>
            <Text t-12-600-s8 l-1>
              {schedule.petName}
            </Text>
          </HFlex>
          <HFlex g-4>
            {/* TODO : 결제 추가 후 작업 */}
            {/* <Image size={14} src={`/icons/icon_money.png`} /> */}
            {isInquiriesSchedule && (
              <Image p-4-r size={14} src={`/icons/icon_send.png`} />
            )}
          </HFlex>
        </HFlex>
      )}
    </VFlex>
  );
};

const MonthlyCalendar = ({
  selectedStaffs,
  searchText,
  personalList,
  statusList,
}: {
  selectedStaffs: Staff[] | undefined;
  searchText?: string;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) => {
  const { schedules, calendarDate } = useSchedules();
  const { businessHours, businessHolidays, temporaryHolidays } =
    useBusinessHours();
  const divRef = useRef<HTMLDivElement>(null);

  const openHour = useMemo(
    () =>
      Math.max(
        ..._.compact(
          businessHours.map((businessHour) => {
            try {
              return parseInt(businessHour.start?.split(":")[0]);
            } catch {
              return undefined;
            }
          })
        ),
        0
      ),
    [businessHours]
  );

  useEffect(() => {
    divRef.current?.scrollTo({ top: 38 * openHour * 2, behavior: "smooth" });
  }, [divRef, openHour]);

  const filteredSchedules = useMemo(() => {
    return schedules?.filter(
      (schedule) =>
        startOfMonth(schedule.startDateTime).getTime() ===
          startOfMonth(calendarDate).getTime() &&
        _.some(
          [
            schedule.petName,
            schedule.nickname ?? "",
            schedule.cellNumber ?? "",
          ],
          (text) => hangul.search(text, searchText ?? "") >= 0
        ) &&
        (schedule.staffs.length === 0 ||
          _.intersection(
            selectedStaffs?.map((staff) => staff.id),
            schedule.staffs.map((staff) => staff.id)
          ).length > 0)
    );
  }, [schedules, searchText, calendarDate, selectedStaffs]);

  const schedulesByDate = useMemo(
    () =>
      filteredSchedules &&
      _(filteredSchedules)
        .groupBy((schedule: Schedule) =>
          format(schedule.startDateTime, "yyyy-MM-dd")
        )
        .mapValues((schedules) =>
          _.orderBy(
            schedules,
            (schedule) => schedule.startDateTime.getTime(),
            searchText ? "desc" : "asc"
          )
        )
        .value(),
    [filteredSchedules, searchText]
  );

  const sList = statusList?.filter((i: ReservationStatus) => i.actived);
  const pList = personalList?.filter((i: ReservationStatus) => i.actived);
  const filterList = sList?.concat(pList as []);
  const filterStatus = filterList
    ?.filter((i: ReservationStatus) => i.actived)
    ?.map((status) => Number(status.value));

  return (
    <VFlex f-1 rel>
      <HFlex>
        <HFlex f-1>
          {days.map((day, i) => (
            <VFlex key={i} f-1 c-c height={56} bd-b-t3 ovf-h>
              <Text l-1 t-16-600-s8>
                {day}
              </Text>
            </VFlex>
          ))}
        </HFlex>
      </HFlex>
      <HFlex f-1 ovf-a>
        <VFlex f-1 rel>
          {[...Array(6)].map(
            (__, i) =>
              (i === 0 ||
                addDays(
                  startOfMonth(calendarDate),
                  i * 7 - startOfMonth(calendarDate).getDay()
                ).getMonth() === calendarDate.getMonth()) && (
                <HFlex key={i} bd-b-t3>
                  {days.map((__, j) => (
                    <VFlex
                      key={j}
                      f-1
                      bd-r-t3={j < 6}
                      p-2-l
                      p-8-r
                      height={105}
                      sized
                      ovf-h
                      style={
                        addDays(
                          startOfMonth(calendarDate),
                          i * 7 + j - startOfMonth(calendarDate).getDay()
                        ).getMonth() === calendarDate.getMonth()
                          ? {}
                          : { background: "#F8F9F8" }
                      }
                    >
                      {schedulesByDate && (
                        <>
                          <Text
                            t-14-600-s8
                            p-8-l
                            height={22}
                            style={{
                              opacity:
                                addDays(
                                  startOfMonth(calendarDate),
                                  i * 7 +
                                    j -
                                    startOfMonth(calendarDate).getDay()
                                ).getMonth() === calendarDate.getMonth()
                                  ? ""
                                  : 0.3,
                              color:
                                i * 7 +
                                  j -
                                  startOfMonth(calendarDate).getDay() ===
                                startOfDay(new Date()).getDate() - 1
                                  ? "#27BD63"
                                  : "#383B39",
                            }}
                          >
                            {format(
                              addDays(
                                startOfMonth(calendarDate),
                                i * 7 + j - startOfMonth(calendarDate).getDay()
                              ),
                              "d"
                            )}
                          </Text>
                          {((schedules) => {
                            const date = addDays(
                              startOfMonth(calendarDate),
                              i * 7 + j - startOfMonth(calendarDate).getDay()
                            );

                            return (
                              <VFlex
                                g-2
                                style={
                                  addDays(
                                    startOfMonth(calendarDate),
                                    i * 7 +
                                      j -
                                      startOfMonth(calendarDate).getDay()
                                  ).getMonth() === calendarDate.getMonth()
                                    ? {}
                                    : { opacity: 0.5 }
                                }
                              >
                                {businessHolidays.map(
                                  (holiday, i) =>
                                    checkIsBusinessHoliday(holiday, date) && (
                                      <HolidayBlock
                                        key={i}
                                        text={"정기 휴무"}
                                      />
                                    )
                                )}
                                {temporaryHolidays.map(
                                  (holiday, i) =>
                                    checkIsTemporaryHoliday(holiday, date) && (
                                      <HolidayBlock
                                        key={i}
                                        text={"임시 휴무"}
                                      />
                                    )
                                )}
                                {schedules
                                  ?.filter((s) => {
                                    return filterStatus?.includes(s.status);
                                  })
                                  ?.slice(0, 2)
                                  ?.map((schedule, k) => (
                                    <ScheduleBlock
                                      key={k}
                                      schedule={schedule}
                                      personalList={personalList}
                                      statusList={statusList}
                                    />
                                  ))}

                                {schedules?.filter((s) => {
                                  return filterStatus?.includes(s.status);
                                })?.length > 2 && (
                                  <MoreScheduleBlock
                                    n={
                                      schedules?.filter((s) => {
                                        return filterStatus?.includes(s.status);
                                      })?.length - 2
                                    }
                                    schedules={schedules}
                                    personalList={personalList}
                                    statusList={statusList}
                                  />
                                )}
                              </VFlex>
                            );
                          })(
                            schedulesByDate[
                              format(
                                addDays(
                                  startOfMonth(calendarDate),
                                  i * 7 +
                                    j -
                                    startOfMonth(calendarDate).getDay()
                                ),
                                "yyyy-MM-dd"
                              )
                            ]
                          )}
                        </>
                      )}
                    </VFlex>
                  ))}
                </HFlex>
              )
          )}
          {!schedulesByDate && (
            <VFlex abs c-c style={{ inset: 0 }}>
              <Spinner />
            </VFlex>
          )}
        </VFlex>
      </HFlex>
    </VFlex>
  );
};

export default MonthlyCalendar;
