import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Deleteds, Partner } from "./types";

const deletedsStore = create(
  immer<{
    deleteds: Deleteds[];
    totalDeletedCount: number;
    fetchDeleteds: (
      partner: Partner
    ) => ({
      page,
      search,
      date,
    }: {
      page: number;
      search?: string;
      date?: number;
    }) => void;
  }>((set) => ({
    deleteds: [],
    totalDeletedCount: 0,
    fetchDeleteds:
      (partner: Partner) =>
      async ({
        page,
        search,
        date,
      }: {
        page: number;
        search?: string;
        date?: number;
      }) => {
        const deleteds = await api.get(
          `/rest/reservation/search?groupId=${
            partner.id
          }&hiddenType=TRUE&size=10&page=${page * 10}&platform=PARTNER_WEB` +
            (date ? `&startDate=${date}` : "") +
            (search ? `&search=${search}` : "")
        );
        set((state) => ({
          ...state,
          deleteds: deleteds.data ?? [],
          totalDeletedCount: deleteds.totalCount ?? 0,
        }));
      },
  }))
);

export const useDeleteds = () => {
  const { partner } = usePartner();
  const { fetchDeleteds, ...rest } = deletedsStore();
  return {
    fetchDeleteds: useMemo(
      () => fetchDeleteds(partner),
      [fetchDeleteds, partner]
    ),
    ...rest,
  };
};
