import { isNil } from "lodash";
import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import {
  Memo,
  MemoType,
  MEMO_STATUS_VALUES,
  Partner,
  Attachment,
} from "./types";

const particalMemosStore = create(
  immer<{
    particalMemo: Memo<keyof typeof MemoType>[];
    fetchParticalMemo: (
      partner: Partner
    ) => ({
      reservationId,
    }: {
      reservationId: number;
    }) => Promise<Memo<keyof typeof MemoType>[]>;
    fetchSaveParticalMemo: (
      partner: Partner
    ) => ({
      reservationId,
      memos,
    }: {
      reservationId: number;
      memos: Memo<keyof typeof MemoType>[];
    }) => void;
    fetchDeleteParticalMemo: (
      partner: Partner
    ) => ({
      reservationId,
      memos,
    }: {
      reservationId: number;
      memos: Memo<keyof typeof MemoType>[];
    }) => void;
  }>((set) => ({
    particalMemo: [],
    fetchParticalMemo:
      (partner: Partner) =>
      async ({ reservationId }: { reservationId: number }) => {
        const memoResponse = await api.get<
          {
            type: {
              value: keyof typeof MemoType;
            };
            value?: {
              value: 0 | 1 | 2 | 3 | 4;
            };
            note?: string;
            attachments?: Attachment[];
          }[]
        >(`/rest/v2/reservation/${reservationId}/note`);

        const memoList = Object.keys(MemoType).map((type, index) => {
          const data = memoResponse.find((r) => r.type.value === type);

          return {
            type: (data?.type?.value ?? type) as keyof typeof MemoType,
            note: data?.note,
            value: (Number(data?.value?.value) ?? undefined) as
              | 0
              | 1
              | 2
              | 3
              | 4
              | undefined,
            displayOrder: index,
            attachments: data?.attachments,
          };
        });
        return memoList;
      },

    fetchSaveParticalMemo:
      (partner: Partner) =>
      async ({
        reservationId,
        memos,
      }: {
        reservationId: number;
        memos: Memo<keyof typeof MemoType>[];
      }) => {
        const formData = new FormData();
        memos.forEach((memo, index) => {
          formData.append(`notes[${index}].type`, memo.type);
          if (!isNil(memo.value) && !isNaN(memo.value)) {
            formData.append(
              `notes[${index}].condition`,
              MEMO_STATUS_VALUES[memo.value]
            );
          }
          if (memo.note) {
            formData.append(`notes[${index}].note`, memo.note);
          }
        });

        await api.put(`/rest/v2/reservation/${reservationId}/note`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },
    fetchDeleteParticalMemo:
      (partner: Partner) =>
      async ({
        reservationId,
        memos,
      }: {
        reservationId: number;
        memos: Memo<keyof typeof MemoType>[];
      }) => {
        const formData = new FormData();
        let memoIndex = 0;

        memos.forEach((memo) => {
          formData.append(`notes[${memoIndex}].type`, memo.type);
          formData.append(`notes[${memoIndex}].note`, memo.note ?? "");
          memoIndex += 1;
        });

        await api.delete(`/rest/v2/reservation/${reservationId}/note`, {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },
  }))
);

export const useParticalMemos = () => {
  const { partner } = usePartner();
  const {
    fetchSaveParticalMemo,
    fetchParticalMemo,
    fetchDeleteParticalMemo,
    ...rest
  } = particalMemosStore();
  return {
    fetchSaveParticalMemo: useMemo(
      () => fetchSaveParticalMemo(partner),
      [fetchSaveParticalMemo, partner]
    ),
    fetchParticalMemo: useMemo(
      () => fetchParticalMemo(partner),
      [fetchParticalMemo, partner]
    ),
    fetchDeleteParticalMemo: useMemo(
      () => fetchDeleteParticalMemo(partner),
      [fetchDeleteParticalMemo, partner]
    ),
    ...rest,
  };
};
