import React, { useEffect, useState } from "react";

import { usePartner } from "../../../providers/partner";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import { shortOrdinals } from "../../../common/constants";

import SalesStatistics from "./SalesStatistics";
import SalesList from "./SalesList";
import { getNthWeeks } from "../Calendar/DailyCalendar";
import YearMonthPicker from "../../../components/YearMonthPicker";
import DatePicker from "../../../components/DatePicker";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";

import { format, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import { ko } from "date-fns/locale";

const CALENDAR_TYPES = ["일별", "주별", "월별"];

function Sales() {
  const { trackLandSales, trackClickSalesCalendarType } = useAmplitudeTrack();
  const { partner } = usePartner();
  const [calendarType, setCalendarType] = useState("일별");
  const [date, setDate] = useState<Date | undefined>(new Date());

  useEffect(() => {
    trackLandSales(partner.title);
  }, []);

  useEffect(() => {
    const newDate =
      calendarType === "일별"
        ? startOfDay(new Date())
        : calendarType === "주별"
        ? startOfWeek(new Date())
        : startOfMonth(new Date());

    setDate(newDate);
  }, [calendarType]);

  const [month, nth] = getNthWeeks(date as Date);

  const handleCalendarType = (type: string) => {
    setCalendarType(type);
    trackClickSalesCalendarType(type);
  };

  return (
    <VFlex a-st rel height={"100%"}>
      <HFlex a-c p-32-rl bd-b-t3 sized height={96}>
        <Text t-24-700 f-1>
          매출 · 통계
        </Text>
      </HFlex>

      <HFlex
        g-8
        a-c
        abs
        style={{
          top: 112,
          left: 52,
        }}
      >
        <HFlex p-4 bc-t1 bdr-8 sized ovf-h height={40}>
          {CALENDAR_TYPES.map((type) => (
            <HFlex
              key={type}
              p-16-rl
              c-c
              bdr-6
              bc-w={calendarType === type}
              onClick={(e) => {
                handleCalendarType(type);
                e.preventDefault();
              }}
              clickable
              style={{
                boxShadow:
                  calendarType === type
                    ? "0px 2px 6px 0px rgba(0, 0, 0, 0.20)"
                    : "none",
              }}
            >
              <Text
                t-14-600-s8={calendarType === type}
                t-14-s2={calendarType !== type}
              >
                {type}
              </Text>
            </HFlex>
          ))}
        </HFlex>
        {calendarType === "월별" ? (
          <YearMonthPicker
            value={date ? date : new Date()}
            setValue={setDate}
            customInput={
              <VFlex>
                <HFlex bdr-8 p-16-rl bc-t1 sized height={40} a-c clickable>
                  <Image size={18} src={`/icons/icon_today.png`} />
                  <Text t-14-s4 m-8-l>
                    {format(new Date(date!), "M월", {
                      locale: ko,
                    })}
                  </Text>
                  <Image size={24} src={`/icons/arrow_drop_down.png`} />
                </HFlex>
              </VFlex>
            }
          />
        ) : (
          <DatePicker
            date={date}
            setDate={setDate}
            trigger={
              <VFlex>
                <HFlex bdr-8 p-16-rl bc-t1 sized height={40} a-c clickable>
                  <Image size={18} src={`/icons/icon_today.png`} />
                  {calendarType === "주별" ? (
                    <Text t-14-s4 m-8-l>
                      {month + 1}월 {shortOrdinals[nth - 1]} 주
                    </Text>
                  ) : (
                    <Text t-14-s4 m-8-l>
                      {format(new Date(date!), "M월 d일 (EEE)", {
                        locale: ko,
                      })}
                    </Text>
                  )}
                  <Image size={24} src={`/icons/arrow_drop_down.png`} />
                </HFlex>
              </VFlex>
            }
          />
        )}
      </HFlex>

      <HFlex j-b height={"100%"}>
        <SalesStatistics
          calendarType={calendarType}
          date={date}
          month={month}
          nth={nth}
        />
        <SalesList calendarType={calendarType} date={date} />
      </HFlex>
    </VFlex>
  );
}

export default Sales;
