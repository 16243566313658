import { InputHTMLAttributes, useRef, useState } from "react";

import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";

function TagSearchInput({
  className,
  placeholder,
  value,
  onChangeValue,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  onChangeValue: (value: string) => void;
  [key: string]: any;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <HFlex
      height={38}
      ovf-h
      bd-t3
      bdr-12
      g-4
      a-c
      p-8
      onClick={() => {
        inputRef.current?.focus();
      }}
    >
      <Image size={24} src={`/icons/icon_search.svg`} />
      <HFlex a-c f-1 t-14-s2 j-b>
        <input
          placeholder={placeholder}
          ref={inputRef}
          value={value ?? ""}
          onChange={(e) => {
            onChangeValue(e.target.value);
          }}
          style={{ width: 240 }}
          {...props}
        />
        <Image
          clickable
          size={18}
          src={`/icons/input_cancel.png`}
          style={
            value
              ? { pointerEvents: "auto", opacity: 1 }
              : { pointerEvents: "none", opacity: 0 }
          }
          onClick={(e) => {
            onChangeValue("");
            e.stopPropagation();
          }}
        />
      </HFlex>
    </HFlex>
  );
}

export default TagSearchInput;
