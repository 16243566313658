import { ReactElement, useEffect, useMemo, useState } from "react";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import _ from "lodash";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";

export type DropdownItem = {
  value: any;
  label: string;
  obj?: any;
  icon?: string;
  status?: boolean;
};

function Dropdown({
  value,
  values,
  isDisabled,
  caption,
  description,
  required,
  placeholder,
  placeholderColor,
  multi,
  isSearchable,
  onChange,
  onChangeValues,
  onClick,
  options,
  controlBgColor,
}: {
  value?: any;
  values?: any[];
  isDisabled?: boolean;
  caption?: string;
  description?: string;
  required?: boolean;
  placeholder?: string;
  placeholderColor?: string;
  multi?: boolean;
  isSearchable?: boolean;
  onChange?: (value: DropdownItem | undefined) => void;
  onChangeValues?: (values: DropdownItem[] | undefined) => void;
  onClick?: () => void;
  options: OptionsOrGroups<DropdownItem, GroupBase<DropdownItem>>;
  controlBgColor?: string;
}): ReactElement {
  const flattenedOptions = useMemo(
    () =>
      _.flatten(
        options.map((option) =>
          (option as GroupBase<DropdownItem>).options
            ? (option as GroupBase<DropdownItem>).options
            : [option as DropdownItem]
        )
      ),
    [options]
  );
  const [innerValue, setInnerValue] = useState<DropdownItem | undefined>(
    flattenedOptions.find((option) => option.value === value)
  );
  const [innerValues, setInnerValues] = useState<DropdownItem[] | undefined>(
    values
      ? flattenedOptions.filter((option) => values.includes(option.value))
      : []
  );
  // when value/options is changed from outside, update innerValue
  useEffect(() => {
    setInnerValue(flattenedOptions.find((option) => option.value === value));
    setInnerValues(
      values
        ? flattenedOptions.filter((option) => values.includes(option.value))
        : []
    );
  }, [value, values, flattenedOptions, setInnerValue, setInnerValues]);
  return (
    <VFlex g-8 clickable onClick={() => onClick?.()}>
      {caption && (
        <HFlex g-4>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      {description && <Text t-12-400-s4>{description}</Text>}
      {multi ? (
        <Select
          key={JSON.stringify(innerValues)}
          isDisabled={isDisabled}
          isMulti={true}
          placeholder={placeholder ?? ""}
          noOptionsMessage={() => "검색 결과가 없습니다."}
          styles={{
            input: () => {
              return {
                fontSize: "16px",
                lineHeight: "24px",
                display: "flex",
                alignItems: "center",
              };
            },
            placeholder: () => ({
              position: "absolute",
              color: placeholderColor ?? "#BFC6C2",
              fontSize: "16px",
              lineHeight: "24px",
            }),
            option: (_provided, state) => ({
              width: "100%",
              height: "56px",
              color: "#191C1A",
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              backgroundColor: state.isFocused ? "#F8F9F8" : "white",
              fontSize: "16px",
              lineHeight: "24px",
              paddingLeft: "24px",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }),
            control: () => ({
              display: "flex",
              height: "56px",
              backgroundColor: !controlBgColor ? "#F8F9F8" : "#fff",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEDEC",
              borderRadius: "16px",
              alignItems: "center",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }),
            indicatorSeparator: () => ({}),
            valueContainer: () => ({
              marginTop: "16px",
              marginBottom: "16px",
              marginLeft: "24px",
              flex: 1,
              display: "flex",
              gap: "8px",
              overflow: "hidden",
            }),
            menu: () => ({
              marginTop: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEDEC",
              borderRadius: "8px",
              position: "absolute",
              backgroundColor: "white",
              top: "100%",
              width: "100%",
              maxHeight: "190px",
            }),
            menuPortal: (provided) => ({ ...provided, zIndex: 100000000000 }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: "190px",
            }),
            dropdownIndicator: () => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#1D201F",
              width: "24px",
              height: "24px",
              marginRight: "24px",
              overflow: "scroll",
            }),
            singleValue: () => {
              return {
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
              };
            },
            multiValue: (style) => {
              return {
                ...style,
                margin: "0px",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                height: "36px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                padding: "0px 8px",
              };
            },
          }}
          formatOptionLabel={(data) => (
            <HFlex a-c g-16>
              {!!data.icon && <Image size={24} src={`/${data.icon}.png`} />}
              <HFlex a-c g-8 l-1 style={{ whiteSpace: "nowrap" }}>
                {data.label}
                {!!data.status && <VFlex width={8} height={8} bc-gr5 bdr-8 />}
              </HFlex>
            </HFlex>
          )}
          components={{
            DropdownIndicator: () => (
              <VFlex c-c width={56} height={56}>
                <Image size={24} src={`/icons/chevron_down.png`} />
              </VFlex>
            ),
            MultiValueRemove: ({ innerProps: { className, ...rest } }) => {
              return (
                <div {...rest}>
                  <VFlex c-c>
                    <Image size={16} src={`/icons/icon_noti_negative.png`} />
                  </VFlex>
                </div>
              );
            },
          }}
          value={innerValues}
          onChange={(item) => {
            setInnerValues([...item]);
            onChangeValues?.([...item]);
          }}
          options={options}
          menuPortalTarget={document.querySelector("body")}
          isSearchable={isSearchable}
        />
      ) : (
        <Select
          key={innerValue?.value.toString()}
          isDisabled={isDisabled}
          isMulti={false}
          noOptionsMessage={() => "검색 결과가 없습니다."}
          placeholder={placeholder ?? ""}
          styles={{
            input: () => {
              return {
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
              };
            },
            placeholder: () => {
              return {
                position: "absolute",
                color: "#BFC6C2",
                fontSize: "16px",
                lineHeight: "24px",
              };
            },
            option: (_provided, state) => ({
              width: "100%",
              height: "46px",
              color: "#383B3A",
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              backgroundColor: state.isFocused ? "#F8F9F8" : "white",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "12px 8px",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              borderRadius: "8px",
            }),
            control: () => ({
              display: "flex",
              height: "56px",
              backgroundColor: !controlBgColor ? "#F8F9F8" : "#fff",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEDEC",
              borderRadius: "16px",
              alignItems: "center",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }),
            indicatorSeparator: () => ({}),
            valueContainer: () => ({
              flex: 1,
              display: "flex",
              marginTop: "16px",
              marginBottom: "16px",
              marginLeft: "24px",
              overflow: "hidden",
            }),
            menu: () => ({
              marginTop: "4px",
              padding: "8px 6px 8px 8px",
              borderRadius: "12px",
              position: "absolute",
              backgroundColor: "white",
              top: "100%",
              width: "100%",
              maxHeight: "200px",
              boxShadow:
                "0px 1px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 6px 1px rgba(0, 0, 0, 0.15)",
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 100000000000,
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: "190px",
            }),
            dropdownIndicator: () => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#1D201F",
              width: "24px",
              height: "24px",
              marginRight: "24px",
              overflow: "scroll",
            }),
            singleValue: () => {
              return {
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
              };
            },
          }}
          formatOptionLabel={(data) => (
            <HFlex a-c g-16>
              {!!data.icon && <Image size={24} src={`/${data.icon}.png`} />}
              <HFlex a-c g-8 l-1 style={{ whiteSpace: "nowrap" }}>
                {data.label}
                {!!data.status && <VFlex width={8} height={8} bc-gr5 bdr-8 />}
              </HFlex>
            </HFlex>
          )}
          components={{
            DropdownIndicator: () => (
              <VFlex c-c width={56} height={56}>
                <Image
                  size={22}
                  src={`/icons/navigation/arrow_drop_down_s3.svg`}
                  clickable
                />
              </VFlex>
            ),
            Option: ({ isSelected, data, selectOption }) => (
              <HFlex
                height={46}
                a-c
                j-b
                bdr-8
                clickable
                hover
                g-8
                m-12-r
                p-8-rl
                onClick={() => selectOption(data)}
              >
                <Text t-14-500-s8>{data.label}</Text>
                <Image
                  src={
                    isSelected
                      ? "/icons/checkbox_circle_outline_checked.svg"
                      : "/icons/checkbox_circle_outline_unchecked.svg"
                  }
                  size={20}
                />
              </HFlex>
            ),
          }}
          value={innerValue}
          onChange={(item) => {
            setInnerValue(item ?? undefined);
            onChange?.(item ?? undefined);
          }}
          options={options}
          menuPortalTarget={document.querySelector("body")}
          isSearchable={isSearchable}
        />
      )}
    </VFlex>
  );
}

export default Dropdown;
