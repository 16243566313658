// base
import { useEffect, useState } from "react";

import api from "../../../common/api";
import { usePartner } from "../../../providers/partner";
import { IColor, ITag, TagsDetail } from "../../../providers/types";
import { useTags } from "../../../providers/tags";

// layouts
import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import TextInput from "../../../layouts/TextInput";
import TextArea from "../../../layouts/TextareaV2";
import Flex from "../../../layouts/Flex";

import Dropdown from "../../../components/Dropdown";
import DnDList from "../../../components/DnDList";
import TagColorDropdown from "../../../components/TagColorDropdown";

import { toast } from "react-hot-toast";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function TagSetting({
  popPopup,
  item,
  tagColors,
  fetchTagList,
}: {
  popPopup: () => void;
  item: ITag;
  tagColors: IColor[];
  fetchTagList: () => void;
}) {
  const { trackSaveTag } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { fetchTags } = useTags();
  const [condition, setCondition] = useState<{
    value: string;
    label: string;
    id?: number;
    obj?: { dataType: string; id: number; lable: string };
  }>();
  const [conditionValue, setConditionValue] = useState<any>();
  const [newTagDes, setNewTagDes] = useState(item.description || "");
  const [tagColor, setTagColor] = useState<any>();
  const [res, setRes] = useState<TagsDetail>();
  const [startValue, setStartValue] = useState<string>();
  const [endValue, setEndValue] = useState<string>();
  const [valueId, setValueId] = useState<number>();
  const [valueType, setValueType] = useState<string>("");

  const fetchTagDetails = async () => {
    const data = await api.get(`/rest/group/${partner.id}/tags/${item.id}`);
    setRes(data);
  };

  useEffect(() => {
    fetchTagDetails();
  }, []);

  const [tagsOrder, setTagsOrder] = useState<
    { id: string; sortOrder: number; title: string }[]
  >([]);

  useEffect(() => {
    if (res) {
      setTagsOrder(
        res?.customerTags.map((tag) => ({
          id: tag.id.toString(),
          sortOrder: tag.sortOrder,
          title: tag.title,
        }))
      );
    }
  }, [res]);

  useEffect(() => {
    if (res) {
      const isDataType = res.criteria[0].variables?.filter((v) => {
        return v.id === res.criteria[0].variableId;
      })[0].dataType;

      setValueId(res.criteria[0].id);
      setValueType(isDataType || "");
      setCondition({
        value: res.criteria[0].variableId.toString(),
        label: res.criteria[0].label,
        id: res.criteria[0].variableId,
      });
      setConditionValue(res.criteria[0].value);
      setStartValue(res.criteria[0].startValue?.toString());
      setEndValue(res.criteria[0].endValue?.toString());
      setTagColor(res.textColor);
    }
  }, [res, fetchTags]);

  return (
    <VFlex
      width={530}
      p-24
      ovf-h
      style={{
        maxHeight: 800,
      }}
    >
      <HFlex p-24-b>
        <Text t-24-600-s8>고객 태그 설정</Text>
        <Flex f-1 />
        <Image
          src="/icons/close.svg"
          size={24}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>

      <VFlex
        f-1
        g-24
        ovf-s
        style={{
          maxHeight: 630,
        }}
      >
        <VFlex g-4>
          <VFlex t-14-s6>태그명</VFlex>
          <VFlex p-16 bc-t1 bd-t5 bdr-16 t-16-s1>
            {res?.title}
          </VFlex>
          <Text t-12-b1>기본 태그는 태그명을 수정할 수 없습니다.</Text>
        </VFlex>

        <VFlex g-4>
          <HFlex t-14-s6>
            태그 조건
            <Text m-4-l t-14-r1>
              *
            </Text>
          </HFlex>
          <VFlex p-16 bc-t1 bd-t5 bdr-16>
            <VFlex>
              <HFlex g-16 a-c>
                <Text t-16-s8>{res?.criteria[0].title} :</Text>
                <VFlex g-4 width={"100%"}>
                  <VFlex>
                    {res?.criteria[0].variables &&
                    res.criteria[0].dataType === "SELECT" ? (
                      <VFlex>
                        <Dropdown
                          value={condition?.id?.toString()}
                          options={res.criteria[0].variables.map((v) => ({
                            value: v.id.toString(),
                            obj: v,
                            label: v.label,
                          }))}
                          onChange={(value) => {
                            setCondition(value?.obj);
                            setValueType(value?.obj.dataType);

                            setConditionValue("");
                            setStartValue("");
                            setEndValue("");
                          }}
                          isSearchable={false}
                          controlBgColor={"#fff"}
                        />
                      </VFlex>
                    ) : (
                      <>
                        <VFlex rel>
                          <TextInput
                            value={conditionValue}
                            onChangeValue={(value) =>
                              setConditionValue(value || "")
                            }
                            bgColor="#fff"
                            noneIcon={true}
                          />
                          <Text
                            t-16-s8
                            style={{
                              position: "absolute",
                              right: 16,
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            {res?.criteria[0].label}
                          </Text>
                        </VFlex>
                        <Text t-12-s4 m-4-t>
                          1~1000까지 입력 가능
                        </Text>
                      </>
                    )}
                  </VFlex>
                </VFlex>
              </HFlex>
              <VFlex>
                {res?.criteria[0].variables &&
                  res.criteria[0].dataType === "SELECT" && (
                    <>
                      {/* 조건 1개 */}
                      {valueType === "SINGLE_INPUT" && (
                        <>
                          <VFlex rel m-16-t>
                            <TextInput
                              value={conditionValue}
                              onChangeValue={(value) => {
                                setConditionValue(value);
                              }}
                              bgColor="#fff"
                              noneIcon={true}
                              maxLength={4}
                            />
                            <Text
                              t-16-s8
                              style={{
                                position: "absolute",
                                right: 16,
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              회
                            </Text>
                          </VFlex>
                          <Text t-12-s4 m-4-t>
                            1~1000까지 입력 가능
                          </Text>
                        </>
                      )}

                      {/* 조건 2개 */}
                      {valueType === "DOUBLE_INPUT" && (
                        <>
                          <VFlex rel m-16-t>
                            <HFlex a-c g-8>
                              <VFlex rel width={"100%"}>
                                <TextInput
                                  value={startValue}
                                  onChangeValue={(value) =>
                                    setStartValue(value)
                                  }
                                  bgColor="#fff"
                                  noneIcon={true}
                                />
                                <Text
                                  t-16-s8
                                  style={{
                                    position: "absolute",
                                    right: 16,
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                >
                                  회
                                </Text>
                              </VFlex>

                              <Text t-18-s4>~</Text>

                              <VFlex rel width={"100%"}>
                                <TextInput
                                  value={endValue}
                                  onChangeValue={(value) => setEndValue(value)}
                                  bgColor="#fff"
                                  noneIcon={true}
                                />
                                <Text
                                  t-16-s8
                                  style={{
                                    position: "absolute",
                                    right: 16,
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                >
                                  회
                                </Text>
                              </VFlex>
                            </HFlex>
                          </VFlex>
                          <Text t-12-s4 m-4-t>
                            1~1000까지 입력 가능
                          </Text>
                        </>
                      )}
                    </>
                  )}
              </VFlex>
            </VFlex>
          </VFlex>
        </VFlex>

        <VFlex g-4>
          <VFlex t-14-s6>태그 설명</VFlex>
          <TextArea
            placeholder="내용을 입력해주세요"
            value={newTagDes}
            onChangeValue={(v) => {
              if (v?.length || 0 <= 800) setNewTagDes(v as string);
            }}
            height={120}
            maxLength={800}
          />
        </VFlex>

        <VFlex g-4>
          <VFlex t-14-s6>태그 색상</VFlex>
          <TagColorDropdown
            value={tagColor}
            options={tagColors.map((tag) => ({
              value: tag.textColor,
              obj: tag,
              label: item.title,
            }))}
            onChange={(value) => {
              setTagColor(value?.value);
            }}
            isSearchable={false}
          />
        </VFlex>

        <VFlex g-4 p-20-b>
          <VFlex t-16-600-s8>노출 순서</VFlex>
          <Text t-14-s4>
            노출 순서대로 고객 목록에서 보여집니다. 드래그하여 순서를 변경할 수
            있습니다.
          </Text>
          <VFlex m-12-t p-8-tb bd-t5 bdr-16>
            <DnDList
              items={tagsOrder}
              onDragEnd={(item: any) => {
                setTagsOrder(item);
              }}
              renderItem={({ item, index }) => (
                <HFlex p-16-tb p-24-rl j-b>
                  <HFlex g-16>
                    <Text t-14-s4>{index + 1}</Text>
                    <Text t-14-s8>{item.title}</Text>
                  </HFlex>
                  <VFlex clickable>
                    <Image size={24} src={`/icons/drag.svg`} />
                  </VFlex>
                </HFlex>
              )}
            />
          </VFlex>
        </VFlex>
      </VFlex>

      <Flex f-1 />

      <Flex
        c-c
        bc-g1
        bdr-16
        p-16-tb
        t-18-600-tw
        clickable
        onClick={async () => {
          if (
            (item.title === "NEW" && !conditionValue) ||
            (valueType === "DOUBLE_INPUT" && (!startValue || !endValue)) ||
            (valueType === "SINGLE_INPUT" && !conditionValue)
          ) {
            return toast("태그 조건을 입력해주세요.", {
              style: {
                color: "#fff",
                backgroundColor: "#383B3A",
              },
              icon: <Image src="/icons/icon_warning.svg" size={20} />,
              duration: 1500,
            });
          }

          if (
            valueType === "DOUBLE_INPUT" &&
            Number(startValue) >= Number(endValue)
          ) {
            return toast("태그 조건을 정확하게 입력해주세요.", {
              style: {
                color: "#fff",
                backgroundColor: "#383B3A",
              },
              icon: <Image src="/icons/icon_warning.svg" size={20} />,
              duration: 1500,
            });
          }

          await api
            .post(
              `/rest/group/${partner.id}/customer/tag?platform=PARTNER_WEB`,
              {
                id: item.id,
                groupId: partner.id,
                description: newTagDes,
                title: item.title,
                textColor: tagColor,
                sortOrder: item.sortOrder,
                enabled: item.enabled,
                criteria: [
                  {
                    id: valueId,
                    value: conditionValue ? conditionValue : undefined,
                    variableId: condition?.id,
                    startValue: startValue ? startValue : undefined,
                    endValue: endValue ? endValue : undefined,
                  },
                ],
                customerTags: tagsOrder.map((to) => ({
                  id: to.id,
                  sortOrder: to.sortOrder,
                })),
              }
            )
            .then(() => {
              toast.success("저장되었습니다");
              trackSaveTag();
              popPopup();
              fetchTagList();
            })
            .catch(() => {
              toast.error("다시 시도해주세요");
            });
        }}
      >
        <Text>저장</Text>
      </Flex>
    </VFlex>
  );
}

export default TagSetting;
