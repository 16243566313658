import { MouseEvent, useEffect, useState } from "react";
import ColumnResize from "react-table-column-resizer";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

import HFlex from "../../../layouts/HFlex";
import VFlex from "../../../layouts/VFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import Flex from "../../../layouts/Flex";
import Pagination from "../../../components/Pagination";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import SearchInput from "../../../components/SearchInput";
import Dropdown from "../../../layouts/Dropdown";
import GuardianDropdown from "../../../components/GurdianDropdown";

import CustomerDetail from "./CustomerDetail";
import CustomerDeletedReason from "./CustomerDeletedReason";

import { Pet } from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { numberWithCommas } from "../../../common/utils";
import useDeletedCustomersContext from "../../../providers/deletedCustomers";
import LoadingView from "../../../layouts/LoadingView";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

// TODO
const ITEMS_PER_PAGE = 10;

const DefaultCell = () => <Text t-14-500-s2>-</Text>;

function DeletedCustomerList() {
  const { trackLandDeletedCustomerList } = useAmplitudeTrack();
  useEffect(() => {
    trackLandDeletedCustomerList();
  }, []);

  const {
    pets,
    page,
    setPage,
    totalCount,
    searchText,
    setSearchText,
    fetchCurrentPage,
    restoreCustomer,
    restoreCustomers,
    isLoading,
  } = useDeletedCustomersContext();
  const [selectedPets, setSelectedPets] = useState<number[]>([]);

  const restoreDeletedPets = () => {
    // pushPopup({
    //   element: ConfirmPopup,
    //   kind: PopupKind.Popup,
    //   align: PopupAlign.TopCenter,
    //   props: {
    //     icon: "/icons/icon_noti_info.png",
    //     title: "선택된 고객을 복구할까요?",
    //     confirmButtonType: "confirm",
    //     confirmButtonLabel: "복구하기",
    //     onConfirm: async () => {
    //       (async () => {
    //         try {
    //           await restoreCustomers(selectedPets);
    //           await fetchCurrentPage();

    //           toast.success(
    //             `${selectedPets.length}명의 고객 정보가 복구되었습니다.`
    //           );
    //           setSelectedPets([]);

    //           await refreshPetListByVisited();
    //         } catch (e) {
    //           toast.error("고객 정보 복구에 실패하였습니다.");
    //         }
    //       })();
    //     },
    //   },
    // });

    pushPopup({
      element: ConfirmPopup,
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      props: {
        icon: "/icons/icon_noti_info.png",
        title: "플러스 플랜으로 업그레이드하고 삭제한 고객을 복구하시겠어요?",
        confirmButtonType: "confirm",
        confirmButtonLabel: "업그레이드",
        onConfirm: () => {
          window.open("https://www.pawdlypartners.com/pricing/");
        },
      },
    });
  };

  useEffect(() => {
    setSelectedPets([]);
  }, [searchText, page]);

  return (
    <VFlex f-1 rel>
      <VFlex height={66} bd-b-t2 p-24-rl j-c>
        {/* TODO */}

        <SearchInput
          value={searchText}
          onChangeValue={setSearchText}
          placeholder="고객의 정보로 검색해보세요"
          disabled={selectedPets.length > 0}
        />
      </VFlex>

      <VFlex p-24-rl f-1>
        <HFlex height={56} a-c>
          <Text t-16-600-s8>전체 ({numberWithCommas(totalCount)})</Text>
        </HFlex>
        <LoadingView f-1 loading={isLoading}>
          <VFlex ovf-a>
            {selectedPets.length > 0 && (
              <HFlex a-c p-20-rl g-12 height={46}>
                <Flex
                  c-c
                  clickable
                  onClick={() => {
                    if (selectedPets.length === 0) {
                      setSelectedPets(pets.map((pet) => pet.id));
                    } else {
                      setSelectedPets([]);
                    }
                  }}
                >
                  <Image
                    src={
                      selectedPets.length > 0 &&
                      selectedPets.length >= pets.length
                        ? "/icons/checkbox_checked.svg"
                        : "/icons/checkbox_unchecked.svg"
                    }
                    size={20}
                  />
                </Flex>
                <HFlex g-4 a-c clickable onClick={restoreDeletedPets}>
                  <Image src="/icons/icon_restore.svg" size={22} />
                  <Text t-14-500-b1>복구하기</Text>
                </HFlex>

                <Text t-14-500-s4>
                  이 페이지의{" "}
                  <b className="t-14-600-s8">{selectedPets.length}명</b>의
                  고객이 선택되었습니다.
                </Text>
              </HFlex>
            )}
            {pets.length > 0 ? (
              <table>
                <thead
                  style={{
                    height: selectedPets.length > 0 ? "0px" : "46px",
                  }}
                >
                  <tr>
                    <th>
                      <Flex
                        c-c
                        clickable
                        onClick={() => {
                          if (selectedPets.length === 0) {
                            setSelectedPets(pets.map((pet) => pet.id));
                          } else {
                            setSelectedPets([]);
                          }
                        }}
                      >
                        {selectedPets.length === 0 && (
                          <Image
                            src={
                              selectedPets.length > 0 &&
                              selectedPets.length >= pets.length
                                ? "/icons/checkbox_checked.svg"
                                : "/icons/checkbox_unchecked.svg"
                            }
                            size={20}
                          />
                        )}
                      </Flex>
                    </th>
                    <ColumnResize
                      id={1}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={36}
                      minWidth={36}
                      maxWidth={null}
                      disabled
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text p-12-tb t-14-500-s4>
                          고객명 (품종)
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={2}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={220}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text a-c p-12-tb t-14-500-s4>
                          보호자 정보
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={3}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={230}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text a-c p-12-tb t-14-500-s4>
                          삭제한 날짜
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={4}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={160}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text a-c p-12-tb t-14-500-s4>
                          삭제한 사람
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={5}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={120}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                    <th>
                      {selectedPets.length === 0 && (
                        <Text f-2 a-c p-12-tb t-14-500-s4>
                          삭제 사유
                        </Text>
                      )}
                    </th>
                    <ColumnResize
                      id={6}
                      resizeEnd={(width) => {}}
                      resizeStart={() => {}}
                      className="columnResizer"
                      defaultWidth={200}
                      minWidth={44}
                      maxWidth={null}
                      disabled={false}
                    />
                  </tr>
                </thead>
                <tbody>
                  {pets.map((pet, index) => (
                    <CustomerListItem
                      key={pet.id}
                      pet={pet}
                      isSelected={!!selectedPets.find((p) => p === pet.id)}
                      setSelectedPets={setSelectedPets}
                      fetchCurrentPage={fetchCurrentPage}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <VFlex
                a-c
                j-c
                bd-t3
                bdr-16
                m-12-t
                g-8
                style={{
                  padding: "100px 0",
                }}
              >
                <Image size={24} src={`/icons/icon_noti.svg`} />
                <Text t-16-s2>삭제된 고객이 없어요</Text>
              </VFlex>
            )}
          </VFlex>
        </LoadingView>
      </VFlex>
      <VFlex p-16-tb>
        <Pagination
          page={page}
          pageCount={totalCount / ITEMS_PER_PAGE}
          onPageChange={(n) => setPage(n)}
        />
      </VFlex>
    </VFlex>
  );
}

export default DeletedCustomerList;

interface CustomerListItemProps {
  pet: Pet;
  isSelected: boolean;
  setSelectedPets: React.Dispatch<React.SetStateAction<number[]>>;
  fetchCurrentPage: () => void;
}

function CustomerListItem({
  pet,
  isSelected,
  setSelectedPets,
  fetchCurrentPage,
}: CustomerListItemProps) {
  const pushCustomerDetailPopup = () => {
    pushPopup({
      kind: PopupKind.Popup,
      element: CustomerDetail,
      props: {
        petId: pet.id,
        fetchCurrentPage,
      },
    });
  };

  const openCustomerDeletedReasonPopup = (e: MouseEvent) => {
    e.stopPropagation();
    pushPopup({
      key: "customer_deleted_reason",
      element: CustomerDeletedReason,
      kind: PopupKind.Popup,
      props: {
        pet,
        fetchCurrentPage,
      },
    });
  };

  return (
    <tr onClick={() => pushCustomerDetailPopup()} className="hover">
      <td>
        <VFlex
          c-c
          clickable
          onClick={(e: MouseEvent) => {
            if (isSelected) {
              setSelectedPets((pets) => pets.filter((p) => p !== pet.id));
            } else {
              setSelectedPets((pets) => [...pets, pet.id]);
            }
            e.stopPropagation();
          }}
        >
          <Image
            src={
              isSelected
                ? "/icons/checkbox_checked.svg"
                : "/icons/checkbox_unchecked.svg"
            }
            size={20}
          />
        </VFlex>
      </td>
      <td className="column_resizer_body none" />
      <td>
        <HFlex a-c g-16>
          <Image
            src={pet.attachment?.path ?? "/icons/default_profile.png"}
            size={36}
            bdr-12
            sized
          />
          <Text t-14-500-s8 l-1>
            {pet.name} ({pet.species})
          </Text>
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex a-c g-6 rel>
          <Text t-14-500-s8 l-1>
            {pet.guardianList[0].name ?? "-"}
          </Text>

          <Text t-14-500-s8>({pet.guardianList[0].cellnumber})</Text>
          {pet.guardianList.length > 1 && (
            <Dropdown
              position="right"
              trigger={<Text t-14-500-s4>+{pet.guardianList.length - 1}</Text>}
              renderOptions={() => (
                <GuardianDropdown guardianList={pet.guardianList.slice(1)} />
              )}
              triggerAction="hover"
            />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex a-c g-6>
          {pet.deleted ? (
            <Text t-14-500-s8 l-1>
              {format(new Date(pet.deleted * 1000), "yyyy.MM.dd (EEE)", {
                locale: ko,
              })}
            </Text>
          ) : (
            <DefaultCell />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex a-c g-6>
          {pet.deletedUserNickname ? (
            <Text t-14-500-s8 l-1>
              {pet.deletedUserNickname}
            </Text>
          ) : (
            <DefaultCell />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body" />
      <td>
        <HFlex
          f-1
          g-6
          a-c
          ovf-h
          onClick={(e) => openCustomerDeletedReasonPopup(e)}
        >
          {pet.deletedReason ? (
            <Text t-14-500-s8 l-1>
              {pet.deletedReason}
            </Text>
          ) : (
            <DefaultCell />
          )}
        </HFlex>
      </td>
      <td className="column_resizer_body none" />
    </tr>
  );
}
