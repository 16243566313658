import React, { useMemo, useState } from "react";

import MenuList from "../Menus/MenuList";
import TabBar, { TabItemType } from "../../../components/TabBar";

import { Service } from "../../../providers/types";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Button from "../../../layouts/Button";
import Flex from "../../../layouts/Flex";

function MenuSelect({
  popPopup,
  selectedProducts,
  onSubmit,
}: {
  popPopup: () => void;
  selectedProducts: Service[];
  onSubmit: (selectedProducts: Service[]) => void;
}) {
  const [innerSelectedProducts, setInnerSelectedProducts] =
    useState<Service[]>(selectedProducts);
  const [searchText, setSearchText] = useState<string>();
  const tabs = useMemo<TabItemType[]>(
    () => [
      {
        key: "1",
        name: "전체",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuList
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ),
      },
      {
        key: "2",
        name: "메인 메뉴",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuList
            groupFilter="메인 메뉴"
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ),
      },
      {
        key: "3",
        name: "추가 메뉴",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuList
            groupFilter="추가 메뉴"
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ),
      },
      {
        key: "4",
        name: "기타",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuList
            groupFilter="기타"
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ),
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(tabs[0]);
  return (
    <VFlex height={850} ovf-h>
      <HFlex a-c p-32-rl sized height={96}>
        <Text t-24-700 f-1>
          서비스 메뉴
        </Text>
      </HFlex>
      <VFlex p-32-rl height={42} sized bd-b-t3>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </VFlex>
      {selectedTab &&
        React.createElement(selectedTab.component, {
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        })}
      <Flex f-1 />
      <VFlex p-24-b a-e height={110} sized>
        <HFlex g-8 p-24-tb p-32-rl>
          <Button
            type="cancel"
            caption="취소"
            onClick={() => {
              popPopup();
            }}
          />
          <Button
            type="confirm"
            caption="저장"
            enabled={innerSelectedProducts.length > 0}
            onClick={() => {
              onSubmit?.(innerSelectedProducts);
              popPopup();
            }}
          />
        </HFlex>
      </VFlex>
    </VFlex>
  );
}

export default MenuSelect;
