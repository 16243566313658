import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { toast } from "react-hot-toast";

import Flex from "../../../layouts/Flex";
import HFlex from "../../../layouts/HFlex";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import Divider from "../../../layouts/Divider";
import Absolute from "../../../layouts/Absolute";
import TabBar from "../../../components/TabBar";
import ButtonV2 from "../../../layouts/ButtonV2";
import Spinner from "../../../common/Spinner/Spinner";

import ServiceHistory from "../../../components/Customers/ServiceHistory";
import MileageHistory from "../../../components/Customers/MileageHistory";
import NewReservation from "../Calendar/NewReservtaion";
import AddCustomer from "./AddCustomer";
import VisitingStatistics from "../../../components/Customers/VisitingStatistics";
import CustomerInfo from "../../../components/Customers/CustomerInfo";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import PetProfile from "../../../components/PetProfile";
import GuardianList from "../../../components/Customers/GuardianList";

import api from "../../../common/api";
import { usePartner } from "../../../providers/partner";
import {
  Attachment,
  Guardian,
  Partner,
  PetInfo,
  ReservationSummary,
  Tag,
} from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

const DropdownMenu = ({
  petId,
  partner,
  setIsShowDropdown,
  popPopup,
  isDeletedCustomer,
  fetchCurrentPage,
  updateCustomerDetail,
}: {
  petId: number;
  partner: Partner;
  setIsShowDropdown: (bool: boolean) => void;
  popPopup: () => void;
  isDeletedCustomer: boolean;
  fetchCurrentPage?: () => void;
  updateCustomerDetail: () => void;
}) => {
  const { trackRestoreCustomer } = useAmplitudeTrack();

  const [isFirstItemHovered, setIsFirstItemHovered] = useState(false);
  const [isSecondItemHovered, setIsSecondItemHovered] = useState(false);

  const fetchRestoreDeletedPet = async () => {
    try {
      await api.delete(`/rest/pet/${petId}?groupId=${partner.id}&status=false`);
      popPopup();
      toast.success("고객 정보가 복구되었습니다");
      if (fetchCurrentPage) fetchCurrentPage();
    } catch (e) {
      toast.error("고객 정보 복구에 실패하였습니다");
    }
  };

  const pushEditCustomerInfoPopup = () => {
    pushPopup({
      kind: PopupKind.Popup,
      element: AddCustomer,
      dimClick: "none",
      props: {
        petId,
        fetchCurrentPage: () => {
          if (fetchCurrentPage) fetchCurrentPage();
          updateCustomerDetail();
        },
      },
    });
  };

  const deleteCustomer = async () => {
    pushPopup({
      kind: PopupKind.Popup,
      element: ConfirmPopup,
      align: PopupAlign.TopCenter,
      props: {
        title: `선택된 고객을 삭제할까요?\n삭제 후에도 삭제된 고객 목록에서 확인 할 수 있습니다.`,
        onConfirm: async () => {
          try {
            await api.delete(
              `/rest/pet/${petId}?groupId=${partner.id}&status=true`
            );
            popPopup();
            toast.success("고객이 삭제되었습니다");
            if (fetchCurrentPage) fetchCurrentPage();
          } catch (e) {
            toast.error("고객 삭제를 실패하였습니다");
          }
        },
        confirmButtonLabel: "삭제하기",
      },
    });
  };

  const restoreDeletedPet = () => {
    pushPopup({
      element: ConfirmPopup,
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      props: {
        icon: "/icons/icon_noti_info.png",
        title: "고객 정보를 복구할까요?",
        confirmButtonType: "confirm",
        confirmButtonLabel: "복구하기",
        onConfirm: () => {
          fetchRestoreDeletedPet();
          trackRestoreCustomer();
          fetchCurrentPage?.();
          popPopup();
        },
      },
    });
  };

  // TODO 별도 컴포넌트로 분리
  return (
    <Absolute style={{ inset: "24px 0px auto auto", zIndex: 2 }}>
      <VFlex width={140} p-8 g-4 bdr-12 bc-w sdw-6>
        {isDeletedCustomer ? (
          <Text
            p-8
            t-14-500-s8
            clickable
            bdr-8
            bc-t2={isFirstItemHovered}
            onMouseEnter={() => setIsFirstItemHovered(true)}
            onMouseLeave={() => setIsFirstItemHovered(false)}
            onClick={() => {
              restoreDeletedPet();
              setIsShowDropdown(false);
            }}
          >
            고객 정보 복구
          </Text>
        ) : (
          <>
            <Text
              p-8
              t-14-500-s8
              clickable
              bdr-8
              bc-t2={isFirstItemHovered}
              onMouseEnter={() => setIsFirstItemHovered(true)}
              onMouseLeave={() => setIsFirstItemHovered(false)}
              onClick={() => {
                pushEditCustomerInfoPopup();
                setIsShowDropdown(false);
              }}
            >
              고객 정보 수정
            </Text>
            <Text
              p-8
              t-14-500-r1
              clickable
              bdr-8
              bc-t2={isSecondItemHovered}
              onMouseEnter={() => setIsSecondItemHovered(true)}
              onMouseLeave={() => setIsSecondItemHovered(false)}
              onClick={() => {
                deleteCustomer();
                setIsShowDropdown(false);
              }}
            >
              고객 정보 삭제
            </Text>
          </>
        )}
      </VFlex>
    </Absolute>
  );
};

const CustomerDetail = ({
  popPopup,
  petId,
  fetchCurrentPage,
}: {
  popPopup: () => void;
  petId: number;
  fetchCurrentPage?: () => void;
}) => {
  const { trackClickCustomerDetail } = useAmplitudeTrack();
  const { partner } = usePartner();
  const [petProfileImg, setPetProfileImg] = useState<Attachment | undefined>();
  const [petInfo, setPetInfo] = useState<PetInfo | undefined>();
  const [isDeletedCustomer, setIsDeletedCustomer] = useState(false);
  const [guardianList, setGuardianList] = useState<Guardian[] | undefined>();
  const [tags, setTags] = useState<Tag[] | undefined>();

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [reservationSummary, setReservationSummary] =
    useState<ReservationSummary | null>(null);

  const [newPetMemo, setNewPetMemo] = useState<string | undefined>(undefined);
  const savePetMemo = async () => {
    try {
      if (!petInfo) return;

      await api.put(
        `/rest/v2/pet/${petId}/single?groupId=${partner.id}&type=NOTE&value=${
          (newPetMemo && encodeURIComponent(newPetMemo)) || null
        }`
      );
      toast.success("변경사항이 저장되었습니다.");

      setPetInfo({ ...petInfo, note: newPetMemo });
    } catch (e) {}
  };

  const fetchPetInfo = async () => {
    try {
      const { deleted, petInfo, tags, guardians, reservationSummary } =
        await api.get<{
          deleted: boolean;
          petInfo: PetInfo;
          tags: Tag[];
          guardians: Guardian[];
          reservationSummary: ReservationSummary;
        }>(`/rest/v2/pet/${petId}/detail?groupId=${partner.id}`);
      setPetInfo(petInfo);
      setNewPetMemo(petInfo?.note);
      setReservationSummary(reservationSummary);
      setTags(tags);
      setGuardianList(guardians);
      setPetProfileImg(petInfo.attachment);
      setIsDeletedCustomer(deleted);
    } catch (e) {
      toast.error("고객 정보를 불러올 수 없습니다");
    }
  };

  const updateCustomerDetail = () => {
    fetchPetInfo();
  };

  useEffect(() => {
    fetchPetInfo();
  }, []);

  const tabs = [
    {
      key: "1",
      name: "예약 목록",
      component: () => <ServiceHistory petId={petId} groupId={partner.id} />,
    },

    {
      key: "2",
      name: "고객 정보",
      component: () => (
        <CustomerInfo
          petId={petId}
          petInfo={petInfo ?? null}
          reservationSummary={reservationSummary}
          isDeletedCustomer={isDeletedCustomer}
          updateCustomerDetail={updateCustomerDetail}
        />
      ),
    },
    {
      key: "3",
      name: "방문 통계",
      component: () => (
        <VisitingStatistics
          petId={petId}
          isDeletedCustomer={isDeletedCustomer}
        />
      ),
    },
    {
      key: "4",
      name: "적립금",
      component: () => (
        <MileageHistory petId={petId} isDeletedCustomer={isDeletedCustomer} />
      ),
    },
    {
      key: "5",
      name: "보호자",
      component: () => (
        <GuardianList
          guardianList={guardianList ?? []}
          isDeletedCustomer={isDeletedCustomer}
          petId={petId}
          fetchGuardianList={updateCustomerDetail}
        />
      ),
    },
  ];
  const [selectedDetailTab, setSelectedDetailTab] = useState(0);

  useEffect(() => {
    trackClickCustomerDetail(petId, isDeletedCustomer ? "deleted" : "");
  }, [isDeletedCustomer]);

  return (
    <VFlex
      a-st
      style={{
        height: "95vh",
        minHeight: "600px",
        maxHeight: "850px",
        maxWidth: "1024px",
        width: "95vw",
      }}
      ovf-h
    >
      <HFlex p-24-tb p-32-rl g-24 a-c>
        <Text t-22-600-s8>
          {petInfo ? `${petInfo.name} (${petInfo.species.name})` : "고객 상세"}
        </Text>
        <Flex f-1 />
        {!isDeletedCustomer && (
          <ButtonV2
            type="filled"
            onClick={() => {
              popPopup();
              pushPopup({
                kind: PopupKind.Popup,
                element: NewReservation,
                dimClick: "none",
                props: {
                  selectedCustomer: {
                    id: petInfo?.id,
                    name: petInfo?.name,
                    species: petInfo?.species.name,
                    attachment: petInfo?.attachment,
                    guardianList,
                  },
                },
              });
            }}
          >
            예약 등록
          </ButtonV2>
        )}
        <VFlex rel a-c>
          <Image
            src={"/icons/icon_more_horiz_s6.svg"}
            size={24}
            clickable
            onClick={() => setIsShowDropdown(true)}
          />
          {isShowDropdown && (
            <DropdownMenu
              petId={petId}
              partner={partner}
              setIsShowDropdown={setIsShowDropdown}
              popPopup={popPopup}
              isDeletedCustomer={isDeletedCustomer}
              updateCustomerDetail={updateCustomerDetail}
              fetchCurrentPage={fetchCurrentPage}
            />
          )}
        </VFlex>
        {isShowDropdown && (
          <Absolute
            style={{ zIndex: 1 }}
            onClick={() => setIsShowDropdown(false)}
          />
        )}
        <Image
          src={"/icons/icon_close_s6.svg"}
          size={24}
          clickable
          onClick={() => {
            if (newPetMemo !== petInfo?.note) {
              pushPopup({
                kind: PopupKind.Popup,
                key: "confirm_discard_pet_memo",
                element: ConfirmPopup,
                align: PopupAlign.TopCenter,
                props: {
                  title: `반려동물 메모가 저장되지 않았습니다.\n저장하지 않고 나갈까요?`,
                  confirmButtonLabel: "중단하기",
                  confirmButtonType: "delete",
                  onConfirm: () => {
                    popPopup();
                    if (fetchCurrentPage) fetchCurrentPage();
                  },
                },
              });
            } else {
              popPopup();
              if (fetchCurrentPage) fetchCurrentPage();
            }
          }}
        />
      </HFlex>
      <Divider height={1} />

      {!!(!petInfo && !guardianList) ? (
        <VFlex c-c f-1>
          <Spinner />
        </VFlex>
      ) : (
        petInfo &&
        guardianList && (
          <HFlex f-1 a-st ovf-h>
            <VFlex width={376} style={{ boxSizing: "border-box" }}>
              <VFlex ovf-a>
                <PetProfile
                  petInfo={petInfo}
                  tags={tags}
                  guardianList={guardianList}
                  petProfileImg={petProfileImg}
                  newPetMemo={newPetMemo}
                  setNewPetMemo={setNewPetMemo}
                  savePetMemo={isDeletedCustomer ? undefined : savePetMemo}
                />
              </VFlex>
            </VFlex>
            <Divider width={1} />
            <VFlex f-1>
              <Flex height={50} p-24-rl p-14-t>
                <TabBar
                  value={tabs[selectedDetailTab]}
                  items={tabs}
                  onChange={(item) =>
                    setSelectedDetailTab(
                      tabs.findIndex((t) => t.key === item.key)
                    )
                  }
                />
              </Flex>
              <Divider height={1} />
              <VFlex
                f-1
                bc-t2
                ovf-h
                style={{
                  borderBottomRightRadius: "24px",
                }}
              >
                {tabs[selectedDetailTab].component()}
              </VFlex>
            </VFlex>
          </HFlex>
        )
      )}
    </VFlex>
  );
};

export default CustomerDetail;
